<!-- ChatMessage.vue -->
<template>
    <div class="py-1 first:pt-0 last:pb-0">
        <div class="flex items-start gap-2" :class="{ 'flex-row-reverse': isUser }">
            <!-- Avatar -->
            <div class="flex-shrink-0">
                <div v-if="isUser"
                    class="h-9 w-9 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-600 flex items-center justify-center ring-2 ring-white">
                    <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
                <div v-else
                    class="h-9 w-9 rounded-full bg-gradient-to-r from-gray-100 to-gray-200 flex items-center justify-center ring-2 ring-white">
                    <span class="text-xl">🤖</span>
                </div>
            </div>

            <!-- Message Content -->
            <div class="inline-block message-container rounded-lg px-5 py-4 text-left text-sm" :class="{
                'bg-indigo-500 text-white': isUser,
                'bg-gray-100 text-gray-900': !isUser
            }" v-html="message">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChatMessage',
    props: {
        message: {
            type: String,
            required: true
        },
        isUser: {
            type: Boolean,
            default: false
        },
        timestamp: {
            type: Date,
            default: () => new Date()
        },
        status: {
            type: String,
            default: ''
        }
    },
    computed: {
        formattedTime() {
            return this.timestamp.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit'
            });
        }
    }
}
</script>

<style scoped>

.message {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.user-message .message {
    margin-left: auto;
}

::v-deep ul {
  list-style: disc;
  margin-left: 1em;
}

</style>