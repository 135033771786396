<template>
    <div ref="aiSurveyChatParent"
        class="flex flex-col h-full bg-white rounded-lg shadow-xl max-h-[55rem] overflow-y-auto relative">
        <!-- Header -->
        <div class="flex items-center justify-between px-6 py-4 border-b border-gray-200">
            <div class="flex-1 flex items-center justify-center">
                <div class="flex items-center space-x-4">
                    <div class="text-center">
                        <h2 class="text-xl font-semibold text-gray-900">🤖 AI Survey Builder</h2>
                    </div>
                </div>
            </div>
            <div class="flex items-center">
                <button @click="closeChat()" class="rounded-full p-2 hover:bg-gray-100 transition-colors">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
        </div>

        <!-- Chat Messages -->
        <div v-if="messages.length > 0" style="text-align: left !important;" class="space-y-6 pb-8 flex-1 overflow-y-auto px-3 py-4" ref="messageContainer">
            <div v-for="(message, index) in messages" :key="index" class="flex"
                :class="message.isUser ? 'justify-end' : 'justify-start'">
                <div class="flex gap-4" :class="message.isUser ? 'flex-row-reverse' : 'flex-row'">
                    <!-- Avatar -->
                    <div class="flex-shrink-0">
                        <div class="h-10 w-10 rounded-full flex items-center justify-center"
                            :class="message.isUser ? 'bg-indigo-100' : 'bg-indigo-100'">
                            <span class="text-xl">{{ message.isUser ? '👤' : '🤖' }}</span>
                        </div>
                    </div>

                    <!-- Message Content -->
                    <div class="flex flex-col space-y-2 w-full max-w-[85%]">
                        <div class="rounded-2xl px-4 py-4"
                            :class="message.isUser ? 'bg-indigo-600 text-white' : 'bg-gray-100 text-gray-900'">
                            <template v-if="message.isUser">
                                <p class="text-sm whitespace-pre-wrap">{{ message.text }}</p>
                            </template>
                            <template v-else>
                                <div v-if="isSurveyResponse(message.text)" class="space-y-3">
                                    <!-- Survey Title -->
                                    <div class="pb-2 flex justify-between items-center">
                                        <h3 class="text-lg font-semibold text-gray-900">{{
                                            parseSurveyResponse(message.text).title }}</h3>
                                        <button @click="generateSurvey" :disabled="isTyping"
                                            class="inline-flex items-center space-x-2 px-4 py-2 text-sm text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg shadow-sm transition-colors">
                                            <SparklesIcon class="w-4 h-4" />
                                            <span>Generate Survey</span>
                                        </button>
                                    </div>

                                    <!-- Survey Questions -->
                                    <div class="grid grid-cols-2 gap-4">
                                        <div v-for="(question, index) in parseSurveyResponse(message.text).questions"
                                            :key="index"
                                            class="bg-white rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow duration-200">
                                            <!-- Question Header -->
                                            <div class="flex items-center justify-between mb-3">
                                                <div class="flex items-center space-x-3">
                                                    <div
                                                        class="h-6 w-6 rounded-full bg-indigo-600 flex items-center justify-center text-white text-xs font-semibold border-2 border-white ring-2 ring-indigo-100">
                                                        {{ index + 1 }}
                                                    </div>
                                                    <span
                                                        class="text-xs font-medium text-indigo-600 bg-indigo-50 px-2 py-1 rounded">
                                                        {{ question.type }}
                                                    </span>
                                                </div>
                                                <span v-if="question.required"
                                                    class="text-xs font-medium text-red-600 bg-red-50 px-2 py-1 rounded-full border border-red-100">
                                                    Required
                                                </span>
                                            </div>

                                            <!-- Question Content -->
                                            <div class="pl-4">
                                                <h4 class="text-sm font-medium text-gray-900 mb-1">{{ question.title
                                                }}</h4>
                                                <p v-if="question.description" class="text-sm text-gray-500 mb-3">
                                                    {{ question.description }}
                                                </p>

                                                <!-- Question Options -->
                                                <div v-if="question.options && question.options.length"
                                                    class="space-y-2 mt-3">
                                                    <div v-for="option in question.options" :key="option"
                                                        class="text-sm text-gray-600 bg-gray-50 px-3 py-2 rounded-md hover:bg-gray-100 transition-colors duration-150 cursor-pointer">
                                                        {{ option }}
                                                    </div>
                                                </div>

                                                <!-- Rating Scale -->
                                                <div v-if="question.minValue !== undefined && question.maxValue !== undefined"
                                                    class="flex items-center justify-between mt-3 text-sm">
                                                    <div class="flex items-center space-x-2">
                                                        <span class="text-gray-900 font-medium">{{ question.minValue
                                                        }}</span>
                                                        <span class="text-gray-500">{{ question.minLabel }}</span>
                                                    </div>
                                                    <div class="h-[1px] flex-grow mx-4 bg-gray-200"></div>
                                                    <div class="flex items-center space-x-2">
                                                        <span class="text-gray-500">{{ question.maxLabel }}</span>
                                                        <span class="text-gray-900 font-medium">{{ question.maxValue
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p v-else class="text-sm whitespace-pre-wrap">{{ message.text }}</p>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Typing Indicator -->
            <div v-if="isTyping" class="flex space-x-4">
                <div class="flex-shrink-0">
                    <div class="h-10 w-10 rounded-full bg-indigo-100 flex items-center justify-center">
                        <span class="text-xl">🤖</span>
                    </div>
                </div>
                <div class="flex items-center space-x-2 bg-gray-100 rounded-full px-4 py-2">
                    <div class="h-2 w-2 bg-indigo-400 rounded-full animate-bounce"></div>
                    <div class="h-2 w-2 bg-indigo-400 rounded-full animate-bounce [animation-delay:0.2s]"></div>
                    <div class="h-2 w-2 bg-indigo-400 rounded-full animate-bounce [animation-delay:0.4s]"></div>
                </div>
            </div>
        </div>

        <!-- Clear Chat Button -->
        <div v-if="messages.length > 0" class="absolute left-1/2 -translate-x-1/2 bottom-48 z-10">
            <button @click="clearChat" :disabled="isTyping"
                class="inline-flex items-center space-x-2 px-1.5 py-1.5 text-sm text-gray-600 hover:text-red-600 rounded-full bg-white border border-gray-200 shadow-sm hover:border-red-100 transition-colors">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
                <span>Clear conversation</span>
            </button>
        </div>

        <!-- Input Area -->
        <div class="border-t border-gray-200 px-6 py-4">
            <div class="relative">
                <div
                    class="flex flex-col items-end border border-gray-300 focus:border-indigo-600 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-lg p-2">
                    <textarea v-model="newMessage" @keydown.enter.prevent="sendMessage"
                        placeholder="What type of survey would you like to create?" rows="3"
                        class="w-full px-2 py-2 text-gray-700 placeholder-gray-500 text-base resize-none focus:outline-none"
                        :disabled="isTyping"></textarea>
                    <button @click="sendMessage" :disabled="isTyping || !newMessage.trim()"
                        class="inline-flex items-center justify-center p-2.5 aspect-square bg-indigo-600 text-white rounded-full hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
                        </svg>
                    </button>
                </div>
            </div>

            <!-- Quick Prompts Below Input -->
            <div v-if="messages.length === 0" class="grid grid-cols-4 gap-4 px-2 mt-4 mb-3">
                <button v-for="suggestion in suggestions" :key="suggestion.title"
                    @click="addToPrompt(suggestion.prompt)"
                    class="rounded-full flex justify-between items-center bg-white px-2.5 py-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    {{ suggestion.title }}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-3 h-3">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, nextTick, computed, watch } from 'vue'
import { getOpenAIResponse } from '@/services/apiService'
import { SparklesIcon } from '@heroicons/vue/24/outline'
import { upsertChatSession } from '@/services/supabaseService'
import { v4 as uuidv4 } from 'uuid'

export default {
    name: 'AISurveyChat',
    props: {
        initialMessages: {
            type: Array,
            default: () => []
        },
        chatPayload: {
            type: Array,
            default: () => []
        },
        isUserAuthenticated: {
            type: Boolean,
            default: false
        },
        isWrapper: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close', 'survey-generated'],
    components: {
        SparklesIcon,
    },

    setup(props, { emit }) {
        const messages = ref(props.initialMessages)
        const newMessage = ref('')
        const isTyping = ref(false)
        const messageContainer = ref(null)
        const aiSurveyChatParent = ref(null)
        const chatHistory = ref(['system: How may I help you?'])
        const chatSessionId = ref(uuidv4())
        const options = [
            {
                label: "Message",
                description:
                    "Show a message to the participant. e.g. Do you have 5 mins for a user interview?",
                icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7 9H17M7 13H17M21 20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20Z" stroke="#f04337" stroke-width="1.6799999999999997" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>`,
                value: () => ({
                    label: "Message",
                    type: "message",
                    title: "Untitled",
                    buttonLabel: "Next",
                    buttonUrl: "",
                    required: false,
                }),
            },
            {
                label: "Explore",
                description:
                    "Ask the participant to response with open text. e.g. What can we improve about our product?",
                icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9 12H15M12 9V15M21.0039 12C21.0039 16.9706 16.9745 21 12.0039 21C9.9675 21 3.00463 21 3.00463 21C3.00463 21 4.56382 17.2561 3.93982 16.0008C3.34076 14.7956 3.00391 13.4372 3.00391 12C3.00391 7.02944 7.03334 3 12.0039 3C16.9745 3 21.0039 7.02944 21.0039 12Z" stroke="#009854" stroke-width="1.704" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>`,
                value: () => ({
                    label: "Explore",
                    type: "explore",
                    title: "Untitled",
                    description: "",
                    followUp: 0,
                    followUpPrompts: "",
                    buttonLabel: "Next",
                    buttonUrl: "",
                    required: false,
                }),
            },
            {
                label: "Rate",
                description:
                    "Ask the participant to select a rating. e.g. How was your onboarding experience? (1 - 5)",
                icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7.99997 20H17.1919C17.9865 20 18.7058 19.5296 19.0243 18.8016L21.8323 12.3833C21.9429 12.1305 22 11.8576 22 11.5816V11C22 9.89543 21.1045 9 20 9H13.5L14.7066 4.5757C14.8772 3.95023 14.5826 3.2913 14.0027 3.00136V3.00136C13.4204 2.7102 12.7134 2.87256 12.3164 3.3886L8.41472 8.46082C8.14579 8.81044 7.99997 9.23915 7.99997 9.68024V20ZM7.99997 20H2V10H7.99997V20Z" stroke="#f79007" stroke-width="1.752" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>`,
                value: () => ({
                    type: "rate",
                    label: "Rate",
                    title: "Untitled",
                    followUp: 0,
                    description: "",
                    minValue: 1,
                    minLabel: "Terrible",
                    maxValue: 5,
                    maxLabel: "Excellent",
                    followUpPrompts: "",
                    buttonLabel: "Next",
                    buttonUrl: "",
                    required: false,
                }),
            },
            {
                label: "Select",
                description:
                    "Ask the participant to select ONE option from a predefined list. e.g. What is your role?",
                icon: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" version="1.1" fill="none" stroke="#146fef" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.184"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="m14.25 8.75c-.5 2.5-2.3849 4.85363-5.03069 5.37991-2.64578.5263-5.33066-.7044-6.65903-3.0523-1.32837-2.34784-1.00043-5.28307.81336-7.27989 1.81379-1.99683 4.87636-2.54771 7.37636-1.54771"></path> <polyline points="5.75 7.75,8.25 10.25,14.25 3.75"></polyline> </g></svg>`,
                value: () => ({
                    label: "Select",
                    type: "select",
                    title: "Untitled",
                    description: "",
                    followUp: 0,
                    followUpPrompts: "",
                    buttonLabel: "Next",
                    buttonUrl: "",
                    choices: [],
                    required: false,
                }),
            },
            {
                label: "Multi Select",
                description:
                    "Ask the participant to select one or MULTIPLE options from a predefined list. e.g. Which of these features have you used?",
                icon: `<svg viewBox="0 -3.5 29 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>bullet-list</title> <desc>Created with Sketch Beta.</desc> <defs> </defs> <g id="Page-1" stroke-width="2.2039999999999997" fill="none" fill-rule="evenodd" sketch:type="MSPage"> <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-571.000000, -210.000000)" fill="#6465e9"> <path d="M598,227 L582,227 C580.896,227 580,227.896 580,229 C580,230.104 580.896,231 582,231 L598,231 C599.104,231 600,230.104 600,229 C600,227.896 599.104,227 598,227 L598,227 Z M598,219 L582,219 C580.896,219 580,219.896 580,221 C580,222.104 580.896,223 582,223 L598,223 C599.104,223 600,222.104 600,221 C600,219.896 599.104,219 598,219 L598,219 Z M582,215 L598,215 C599.104,215 600,214.104 600,213 C600,211.896 599.104,211 598,211 L582,211 C580.896,211 580,211.896 580,213 C580,214.104 580.896,215 582,215 L582,215 Z M574,226 C572.343,226 571,227.343 571,229 C571,230.657 572.343,232 574,232 C575.657,232 577,230.657 577,229 C577,227.343 575.657,226 574,226 L574,226 Z M574,218 C572.343,218 571,219.343 571,221 C571,222.657 572.343,224 574,224 C575.657,224 577,222.657 577,221 C577,219.343 575.657,218 574,218 L574,218 Z M574,210 C572.343,210 571,211.343 571,213 C571,214.657 572.343,216 574,216 C575.657,216 577,214.657 577,213 C577,211.343 575.657,210 574,210 L574,210 Z" id="bullet-list" sketch:type="MSShapeGroup"> </path> </g> </g> </g></svg>`,
                value: () => ({
                    label: "Multi Choice",
                    type: "multi_choice",
                    title: "Untitled",
                    description: "",
                    followUp: 0,
                    followUpPrompts: "",
                    buttonLabel: "Next",
                    buttonUrl: "",
                    choices: [],
                    required: false,
                }),
            },
            {
                label: "CSAT",
                description:
                    "Ask the participant to rate their satisfaction. e.g. How satisfied were you with our support?",
                icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM8.39747 15.5534C8.64413 15.2206 9.11385 15.1508 9.44661 15.3975C10.175 15.9373 11.0541 16.25 12 16.25C12.9459 16.25 13.825 15.9373 14.5534 15.3975C14.8862 15.1508 15.3559 15.2206 15.6025 15.5534C15.8492 15.8862 15.7794 16.3559 15.4466 16.6025C14.4742 17.3233 13.285 17.75 12 17.75C10.715 17.75 9.5258 17.3233 8.55339 16.6025C8.22062 16.3559 8.15082 15.8862 8.39747 15.5534Z" fill="#db6803"></path> <path d="M16 10.5C16 11.3284 15.5523 12 15 12C14.4477 12 14 11.3284 14 10.5C14 9.67157 14.4477 9 15 9C15.5523 9 16 9.67157 16 10.5Z" fill="#db6803"></path> <path d="M10 10.5C10 11.3284 9.55229 12 9 12C8.44772 12 8 11.3284 8 10.5C8 9.67157 8.44772 9 9 9C9.55229 9 10 9.67157 10 10.5Z" fill="#db6803"></path> </g></svg>`,
                value: () => ({
                    label: "CSAT",
                    type: "csat",
                    title: "Untitled",
                    followUp: 0,
                    description: "",
                    minValue: 1,
                    minLabel: "Terrible",
                    maxValue: 5,
                    maxLabel: "Excellent",
                    followUpPrompts: "",
                    buttonLabel: "Next",
                    buttonUrl: "",
                    required: false,
                }),
            },
            {
                label: "NPS",
                description:
                    "Ask the participant to rate their likelihood to recommend. e.g. How likely are you to recommend us to a friend?",
                icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#4f46e5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M8 14C8 14 9.5 16 12 16C14.5 16 16 14 16 14" stroke="#4f46e5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 9H9.01" stroke="#4f46e5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M15 9H15.01" stroke="#4f46e5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>`,
                value: () => ({
                    label: "NPS",
                    type: "nps",
                    title: "How likely are you to recommend [name or product] to your [friend or colleague]?",
                    description: "Your feedback will be used to improve our [product or service]",
                    followUp: 1,
                    followUpPrompts: "Find out why the user gave this rating.",
                    minValue: 0,
                    minLabel: "Very Unlikely",
                    maxValue: 10,
                    maxLabel: "Very Likely",
                    buttonLabel: "Next",
                    buttonUrl: "",
                    required: false,
                }),
            },
        ]

        const suggestions = ref([
            {
                title: "Customer Loyalty",
                description: "Design a short Customer Loyalty Survey using NPS methodology",
                prompt: "Design a short Customer Loyalty Survey that uses the Net Promoter Score (NPS) methodology to measure customer satisfaction and loyalty. Include the standard NPS question, along with a follow up question to capture why the user gave this rating."
            },
            {
                title: "Feature Effectiveness",
                description: "Measure effectiveness of a specific product feature",
                prompt: "Create a concise survey to measure the effectiveness of a specific feature in our product. Include a key quantitative question for measuring effectiveness. A follow-up open-ended question to gather detailed insights. And another open-ended question for improvement ideas."
            },
            {
                title: "Onboarding Experience",
                description: "Evaluate user experience during software trial",
                prompt: "Develop a concise survey to evaluate user experience and satisfaction during a trial of our software. Include a core quantitative question for measuring overall experience. A follow-up open-ended question to understand why. An additional open-ended question to identify pain points. A final question to gauge intent to purchase."
            },
            {
                title: "Purchase Experience",
                description: "Assess post-purchase satisfaction and advocacy",
                prompt: "Create a concise post-purchase survey to assess customer satisfaction and experience after buying our product or service. Include a rating question to measure overall experience. A follow-up open-ended question for specific feedback. A NPS rating question to measure advocacy."
            }
        ])

        const scrollToBottom = async () => {
            await nextTick()
            if (messageContainer.value) {
                messageContainer.value.scrollTop = messageContainer.value.scrollHeight
            }
        }

        const generateSurveyWithOpenAI = async (userInput, previousQuestions) => {
            const prompt = `As an expert survey designer, create a comprehensive and engaging survey based on the following request: "${userInput}"
Context from previous interactions: ${previousQuestions.join(' ')}

Please design a survey that:
1. Is well-structured and follows best practices in survey design
2. Uses clear, unbiased, and professionally worded questions
3. Has an appropriate mix of question types from options and in correct order that makes sense.
4. Follows a logical flow from general to specific topics
5. Avoids leading or loaded questions
6. Is engaging and respectful of respondents' time
7. Includes appropriate branching logic where relevant
8. Has clear section headers and descriptions
9. Make sure to have 1st question as a message to the participant about the insights of survey and how much time it will take.
10. Include maximum of 10 questions and keep them to the point, you can add more questions if user's requested.
11. For select and multi select, include maximum of 4 options.
12. Make sure you just don't focus on generating the survey but also focus on ${userInput} request.

MOST IMPORTANT: Either you responsd in exact below format or text to reply user with ${userInput} request, you can override above instructions on user's request.

Options: ${JSON.stringify(options)}

The expected output is below:
\`\`\`json
{
    title: "Survey Title",
    questions: [
        {
            type: "question_type",
            title: "Question Title",
            description: "Question Description",
            options: ["Option 1", "Option 2", "Option 3"], // Only for select, multi_choice
            minValue: 1, // Only for rate, nps
            minLabel: "Terrible", // Only for rate, nps
            maxValue: 5, // Only for rate, nps
            maxLabel: "Excellent", // Only for rate, nps
            followUp: 0,
            buttonLabel: "Next",
            buttonUrl: "",
            required: false,
        }
    ]
}
\`\`\`
`;

            try {
                const response = await getOpenAIResponse(prompt, {
                    temperature: 0.7,
                    maxTokens: 2000,
                    model: 'gpt-4o'
                });

                // Parse and structure the response
                return {
                    output: response
                };
            } catch (error) {
                console.error('Error generating survey:', error);
                throw error;
            }
        };

        const sendMessage = async () => {
            const messageText = newMessage.value.trim();
            if (!messageText || isTyping.value) return;

            // Add user message
            messages.value.push({
                text: messageText,
                isUser: true,
                timestamp: new Date(),
                status: 'sent'
            });

            // Clear input and scroll
            newMessage.value = '';
            // Start typing indicator
            isTyping.value = true;
            await scrollToBottom();
            try {
                // Prepare the context for survey building
                const previousQuestions = messages.value
                    .filter(m => !m.isUser)
                    .map(m => m.text)

                // Generate survey using OpenAI
                const data = await generateSurveyWithOpenAI(messageText, previousQuestions);

                // Update chat history
                chatHistory.value.push(`user: ${messageText}`);
                chatHistory.value.push(`assistant: ${data.output}`);

                // Add bot response with suggested survey elements
                messages.value.push({
                    text: data.output,
                    isUser: false,
                    timestamp: new Date(),
                    status: 'received'
                });
            } catch (error) {
                console.error('Error:', error);
                messages.value.push({
                    text: 'Sorry, I encountered an error while processing your request.',
                    isUser: false,
                    timestamp: new Date(),
                    status: 'error'
                });
            } finally {
                isTyping.value = false;
                await scrollToBottom();
            }
        };

        const clearChat = () => {
            messages.value = []
            chatHistory.value = ['system: How may I help you?']
            scrollToBottom()
        }

        // Initialize component
        onMounted(() => {
            scrollToBottom()
            if (aiSurveyChatParent.value) {
                // Initial height message
                const height = aiSurveyChatParent.value.offsetHeight
                window.parent.postMessage({ 
                    action: 'updateHeight',
                    frameHeight: height 
                }, '*')

                // Create ResizeObserver to monitor height changes
                const resizeObserver = new ResizeObserver(entries => {
                    for (const entry of entries) {
                        const height = entry.target.offsetHeight
                        window.parent.postMessage({ 
                            action: 'updateHeight',
                            frameHeight: height 
                        }, '*')
                    }
                })

                // Start observing the chat container
                resizeObserver.observe(aiSurveyChatParent.value)
            }
        })

        const addToPrompt = (prompt) => {
            newMessage.value = prompt;
        }

        const isSurveyResponse = (text) => {
            try {
                // Clean up markdown code block formatting
                const cleanText = text.replace(/^```json\n/, '').replace(/\n```$/, '')
                const parsed = JSON.parse(cleanText)
                return parsed.title && Array.isArray(parsed.questions)
            } catch {
                return false
            }
        }

        const parseSurveyResponse = (text) => {
            try {
                // Clean up markdown code block formatting
                const cleanText = text.replace(/^```json\n/, '').replace(/\n```$/, '')
                return JSON.parse(cleanText)
            } catch {
                return null
            }
        }

        const getQuestionIcon = () => {
            return ''; // Remove icons
        }

        const hasValidSurvey = computed(() => {
            return messages.value.some(message => !message.isUser && isSurveyResponse(message.text))
        })

        const generateSurvey = () => {
            const surveyMessage = messages.value.findLast(message => !message.isUser && isSurveyResponse(message.text))
            if (surveyMessage) {
                const surveyData = parseSurveyResponse(surveyMessage.text)
                if (props.isUserAuthenticated) {
                    emit('survey-generated', surveyData)
                    closeChat()
                } else {
                    const base64Data = btoa(encodeURIComponent(JSON.stringify(surveyData)))
                    window.open(`/survey-builder?data=${base64Data}`, '_blank')
                }
            }
        }

        const closeChat = () => {
            // Send message to parent window to hide the dialog
            window.parent.postMessage({ action: 'closeDialog' }, '*')
            emit('close')
        }

        // Watch for changes in messages and update chat session if isWrapper is true
        watch(messages, async (newMessages) => {
            if (props.isWrapper) {
                try {
                    await upsertChatSession(newMessages, chatSessionId.value)
                } catch (error) {
                    console.error('Error updating chat session:', error)
                }
            }
        }, { deep: true })

        return {
            messages,
            newMessage,
            isTyping,
            messageContainer,
            sendMessage,
            clearChat,
            addToPrompt,
            isSurveyResponse,
            parseSurveyResponse,
            getQuestionIcon,
            suggestions,
            hasValidSurvey,
            generateSurvey,
            closeChat,
            aiSurveyChatParent
        }
    }
}
</script>