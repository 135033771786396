<template>
  <div class="flex flex-col w-full h-screen">
    <GlobalNotification
      v-if="showCopyNotification"
      message="Survey link copied to clipboard!"
      type="ok"
    />
    <div class="px-6 pt-5 border-b border-gray-200">
      <div v-if="isLoading">
        <!-- Skeleton -->
        <div class="flex items-center justify-center bg-gray-100">
          <div
            class="flex items-center justify-between w-full p-4 bg-white rounded-lg"
          >
            <div class="h-5 w-32 bg-gray-200 animate-pulse rounded"></div>
            <div class="flex space-x-4">
              <div class="h-9 w-20 bg-gray-200 animate-pulse rounded"></div>
              <div class="h-9 w-20 bg-gray-200 animate-pulse rounded"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex items-center justify-between mb-5">
        <div class="flex flex-col">
          <div class="flex items-center gap-2">
            <div v-if="isEditingTitle" class="flex items-center gap-2 w-full">
              <input
                type="text"
                v-model="editedTitle"
                @blur="saveTitleEdit"
                @keyup.enter="saveTitleEdit"
                ref="titleInput"
                class="text-2xl font-semibold bg-white border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              />
            </div>
            <h1 v-else class="text-2xl text-left font-semibold">{{ survey_title }}</h1>
            <button
              @click="startTitleEdit"
              class="text-gray-400 ml-3 hover:text-gray-600 focus:outline-none"
              :class="{ 'opacity-0': isEditingTitle }"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
              </svg>
            </button>
          </div>
          <span
            class="text-left text-sm text-gray-500"
            v-if="status !== 'draft'"
          >
            {{ responseCount
            }}{{ responseLimit ? ` / ${responseLimit}` : "" }} responses |
            {{
              responseCount && viewResponseCount
                ? ((responseCount / viewResponseCount) * 100).toFixed(2)
                : 0
            }}% response rate
          </span>
        </div>
        <div class="flex items-center">
          <button
            type="button"
            @click="previewSurvey"
            v-if="status === 'draft'"
            class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Preview
          </button>
          <button
            type="button"
            @click="showLaunchModal"
            :disabled="isUpdating"
            v-if="status === 'draft'"
            class="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <span
              v-if="isUpdating"
              class="animate-spin inline-block w-4 h-4 border-2 border-white border-t-transparent rounded-full"
            ></span>
            <span v-else>Publish</span>
          </button>
          <button
            type="button"
            @click="editSurvey"
            :disabled="isUpdating"
            v-if="status !== 'draft'"
            class="rounded-md bg-white px-7 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-4"
          >
            <span
              v-if="isUpdating"
              class="animate-spin inline-block w-4 h-4 border-2 border-gray-400 rounded-full"
            ></span>
            <span v-else>Edit</span>
          </button>
          <select
            v-if="status != 'draft'"
            id="status"
            v-model="status"
            @change="updateSurveyStatus(status)"
            class="rounded-md bg-white px-5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-blue-300"
          >
            <option value="in_progress">In-Progress</option>
            <option value="pause">Pause</option>
            <option value="archive">Archive</option>
          </select>
        </div>
      </div>

      <!-- Edit popup -->
      <div
        v-if="showSurveyEditModal"
        class="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        ></div>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <div
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div class="sm:flex sm:items-start">
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3
                    class="text-base font-semibold leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Risk of Data Loss!
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      This survey is live and has responses recorded against it.
                      Modifying this survey may result in irrversible data loss.
                      Proceed with caution.
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  @click="updateSurveyStatus('draft')"
                  class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                >
                  I accept this risk
                </button>
                <button
                  type="button"
                  @click="showSurveyEditModal = false"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Launch confirmation modal -->
      <div
        v-if="showLaunchConfirmModal"
        class="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        ></div>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <div
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div class="text-center">
                <div
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-indigo-100 mb-4"
                >
                  <svg
                    class="h-6 w-6 text-indigo-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                    />
                  </svg>
                </div>
                <div>
                  <h3
                    class="text-base font-semibold leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Launch Survey
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      You're about to make this survey live. Once published, it
                      will be available to your target audience. Are you ready
                      to launch?
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-8 sm:mt-8 flex w-full space-x-3">
                <button
                  type="button"
                  @click="showLaunchConfirmModal = false"
                  class="w-1/2 inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  @click="updateSurveyStatus('in_progress')"
                  class="w-1/2 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                >
                  Launch Survey
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Survey is live modal -->
      <div
        v-if="showSurveyLiveModal"
        class="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        ></div>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <div
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6"
            >
              <div class="text-center">
                <div
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 mb-4"
                >
                  <svg
                    class="h-6 w-6 text-green-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div>
                  <h3
                    class="text-base font-semibold leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Survey is Live!
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500 mx-20">
                      Your survey has been published and is ready to collect
                      responses.
                    </p>
                    <div
                      v-if="
                        channel && channel.toLowerCase() === 'sharable link'
                      "
                      class="mt-12"
                    >
                      <p class="font-medium text-sm">Survey Link</p>
                      <SurveyLink
                        class="w-full mt-2"
                        :surveyId="survey_id"
                        @copied="handleCopied"
                      />
                    </div>

                    <div
                      v-if="channel && channel.toLowerCase() === 'web'"
                      class="mt-8 rounded-md bg-orange-50 border-orange-400 border p-4"
                      v-show="!sdkConnected"
                    >
                      <div class="flex flex-col items-center text-center">
                        <div class="flex items-center space-x-2 mb-2">
                          <svg
                            class="h-5 w-5 text-orange-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <h3 class="text-sm font-bold text-orange-800">
                            SDK not connected
                          </h3>
                        </div>
                        <div class="mt-2 text-sm text-orange-700">
                          <p>
                            To show surveys on your website, the Javascript SDK
                            must be installed.
                          </p>
                          <p class="mt-2">
                            <a
                              href="https://help.sondar.ai/en/articles/10489683-installing-the-javascript-sdk"
                              target="_blank"
                              class="font-medium text-orange-700 underline hover:text-orange-600 inline-flex items-center space-x-1"
                            >
                              <svg
                                class="h-4 w-4"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                                />
                              </svg>
                              <span>Installation Guide</span>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-8 sm:mt-8 flex w-full">
                <button
                  type="button"
                  @click="showSurveyLiveModal = false"
                  class="w-full inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Dismiss
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- old tab bar -->
      <div class="flex items-center space-x-7 mt-5">
        <div
          v-if="status == 'draft'"
          class="flex items-center space-x-1 cursor-pointer border-b-2 pb-4 px-2"
          :class="
            section === 'Build'
              ? 'text-indigo-600 border-indigo-600 text-sm font-medium'
              : 'text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
          "
          @click="updateSection('Build')"
        >
          <span>Build</span>
        </div>
        <div
          v-if="status == 'draft'"
          class="flex items-center space-x-1 cursor-pointer border-b-2 pb-4 px-2"
          :class="
            section === 'Configure'
              ? 'text-indigo-600 border-indigo-600 text-sm font-medium'
              : 'text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
          "
          @click="updateSection('Configure')"
        >
          <span>Configure</span>
        </div>
        <div
          v-if="status != 'draft'"
          class="flex items-center space-x-1 cursor-pointer border-b-2 pb-4 px-2"
          :class="
            section === 'Responses'
              ? 'text-indigo-600 border-indigo-600 text-sm font-medium'
              : 'text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
          "
          @click="updateSection('Responses')"
        >
          <span>Responses</span>
        </div>
        <div
          v-if="status != 'draft'"
          class="flex items-center space-x-1 cursor-pointer border-b-2 pb-4 px-2"
          :class="
            section === 'Details'
              ? 'text-indigo-600 border-indigo-600 text-sm font-medium'
              : 'text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
          "
          @click="updateSection('Details')"
        >
          <span>Details</span>
        </div>
        <div
          v-if="status != 'draft'"
          class="flex items-center space-x-1 cursor-pointer border-b-2 pb-4 px-2"
          :class="
            section === 'Reports'
              ? 'text-indigo-600 border-indigo-600 text-sm font-medium'
              : 'text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
          "
          @click="updateSection('Reports')"
        >
          <span>Reports</span>
        </div>
      </div>
    </div>
    <CreateSurvey class="overflow-y-auto" v-if="section === 'Build'" />
    <ConfigureSurvey class="overflow-y-auto" v-if="section === 'Configure'" />
    <SurveyDetails class="overflow-y-auto" v-if="section === 'Details'" />
    <ViewResponse class="overflow-y-auto" v-if="section === 'Responses'" />
    <AIAnalysis class="overflow-y-auto" v-if="section === 'Reports'" />
  </div>
</template>

<script>
import { ref, onMounted, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import CreateSurvey from "../surveyBuilder/CreateSurvey.vue";
import ConfigureSurvey from "../surveyBuilder/ConfigureSurvey.vue";
import SurveyDetails from "./SurveyDetails.vue";
import ViewResponse from "./ViewResponse.vue";
import AIAnalysis from "./AIAnalysis.vue";
import { useAuthStore } from "@/stores/auth";
import {
  checkTenantSDKConnection,
  fetchSurveyDetails,
  updateSurveyStatus as updateSurveyStatusService,
  fetchMailerliteUserId,
  updateSurveyTitle,
} from "@/services/supabaseService";
import SurveyLink from "../commons/SurveyLink.vue";
import GlobalNotification from "../commons/GlobalNotification.vue";

export default {
  components: {
    CreateSurvey,
    ConfigureSurvey,
    SurveyDetails,
    ViewResponse,
    SurveyLink,
    GlobalNotification,
    AIAnalysis,
  },

  props: {
    tab: {
      type: String,
    },
  },

  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const authStore = useAuthStore();

    // Reactive state
    const currentUserId = ref(null);
    const userRole = ref(null);
    const tenantId = ref(null);
    const status = ref("");
    const section = ref("");
    const survey_title = ref("");
    const isLoading = ref(false);
    const isUpdating = ref(false);
    const responseLimit = ref(0);
    const responseCount = ref(0);
    const viewResponseCount = ref(0);
    const showSurveyEditModal = ref(false);
    const showLaunchConfirmModal = ref(false);
    const showSurveyLiveModal = ref(false);
    const survey_id = ref(route.params.survey_id);
    const channel = ref("");
    const sdkConnected = ref(false);
    const showCopyNotification = ref(false);
    const isEditingTitle = ref(false);
    const editedTitle = ref("");
    const titleInput = ref(null);

    const updateSection = (newSection) => {
      section.value = newSection;
      router.push({
        name: route.name,
        params: { survey_id: survey_id.value },
        query: { tab: newSection }
      });
    };

    // Watch for tab prop changes and status changes
    watch(
      [() => props.tab, () => status.value],
      ([newTab, newStatus]) => {
        if (newTab && newStatus) {
          const validTabsForDraft = ['Build', 'Configure'];
          const validTabsForLive = ['Responses', 'Details', 'Reports'];
          
          const isValidTab = newStatus === 'draft' 
            ? validTabsForDraft.includes(newTab)
            : validTabsForLive.includes(newTab);

          if (isValidTab) {
            updateSection(newTab);
          }
        }
      },
      { immediate: true }
    );

    // Methods
    const checkSDKStatus = async () => {
      try {
        const status = await checkTenantSDKConnection(authStore.tenantId);
        sdkConnected.value = status.sdk_connected;
      } catch (err) {
        console.error("Error checking SDK status:", err);
        sdkConnected.value = false;
      }
    };

    const previewSurvey = () => {
      window.open(`/survey/${survey_id.value}?isPreview=true`, "_blank");
    };

    const fetchSurvey = async () => {
      try {
        isLoading.value = true;

        const surveyData = await fetchSurveyDetails(survey_id.value);

        survey_title.value = surveyData.title;
        status.value = surveyData.status;
        responseLimit.value = surveyData.response_limit;
        channel.value =
          surveyData.survey_distribution_config?.[0]?.channel || "";

        // Check SDK status if channel is web
        if (channel.value.toLowerCase() === "web") {
          await checkSDKStatus();
        }

        // Update initial section based on status and route query
        const initialTab = route.query.tab;
        if (status.value === "draft") {
          updateSection(initialTab || "Build");
        } else {
          updateSection(initialTab || "Responses");
        }

        // Filter out preview responses and count completed responses
        responseCount.value = surveyData.responses
          ? surveyData.responses.filter(
              (response) =>
                !response.is_preview &&
                response.response_messages &&
                response.response_messages.length > 0
            ).length
          : 0;

        // Count all non-preview responses
        viewResponseCount.value = surveyData.responses
          ? surveyData.responses.filter((response) => !response.is_preview)
              .length
          : 0;
      } catch (err) {
        console.error("Unexpected error:", err);
      } finally {
        isLoading.value = false;
      }
    };

    const editSurvey = () => {
      if (responseCount.value > 0) {
        showSurveyEditModal.value = true;
      } else {
        updateSurveyStatus("draft");
      }
    };

    const updateMailerSub = async () => {
      try {
        const mailerliteUserId = await fetchMailerliteUserId(currentUserId.value);
        if (!mailerliteUserId) return;

        fetch(
          `${process.env.VUE_APP_SUPABASE_EF_BASE_URL}/mailer-update-subscriber`,
          {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${process.env.VUE_APP_SUPABASE_KEY}`
            },
            body: JSON.stringify({
              sub_user_id: mailerliteUserId,
            }),
          }
        ).catch(err => console.error("Error updating mailer subscription:", err));
      } catch (err) {
        console.error("Error fetching mailerlite_user_id:", err);
      }
    };

    const updateSurveyStatus = async (newStatus) => {
      showSurveyEditModal.value = false;
      showLaunchConfirmModal.value = false;
      try {
        isUpdating.value = true;
        await updateSurveyStatusService(survey_id.value, newStatus);
        status.value = newStatus;
        if (status.value == "draft") {
          updateSection("Build");
        } else {
          updateSection("Responses");
          if (newStatus === "in_progress") {
            showSurveyLiveModal.value = true;
          }
        }
        // Fire and forget - don't block on this call
        updateMailerSub();
      } catch (err) {
        console.error("Unexpected error:", err);
      } finally {
        isUpdating.value = false;
      }
    };

    const showLaunchModal = () => {
      showLaunchConfirmModal.value = true;
    };

    const handleCopied = () => {
      showCopyNotification.value = true;
      setTimeout(() => {
        showCopyNotification.value = false;
      }, 3000); // Hide notification after 3 seconds
    };

    const startTitleEdit = async () => {
      editedTitle.value = survey_title.value;
      isEditingTitle.value = true;
      await nextTick();
      titleInput.value.focus();
    };

    const saveTitleEdit = async () => {
      if (editedTitle.value.trim() !== "") {
        try {
          isUpdating.value = true;
          await updateSurveyTitle(survey_id.value, editedTitle.value);
          survey_title.value = editedTitle.value;
        } catch (err) {
          console.error("Error updating survey title:", err);
        } finally {
          isUpdating.value = false;
        }
      }
      isEditingTitle.value = false;
    };

    // Lifecycle hooks
    onMounted(() => {
      currentUserId.value = authStore.user.id;
      userRole.value = authStore.role;
      tenantId.value = authStore.tenantId;
      fetchSurvey();
    });

    return {
      // State
      currentUserId,
      userRole,
      tenantId,
      status,
      section,
      survey_title,
      isLoading,
      isUpdating,
      responseLimit,
      responseCount,
      viewResponseCount,
      showSurveyEditModal,
      showLaunchConfirmModal,
      showSurveyLiveModal,
      survey_id,
      channel,
      sdkConnected,
      showCopyNotification,
      isEditingTitle,
      editedTitle,
      titleInput,

      // Methods
      checkSDKStatus,
      previewSurvey,
      editSurvey,
      updateSurveyStatus,
      showLaunchModal,
      handleCopied,
      updateSection,
      startTitleEdit,
      saveTitleEdit,
    };
  },
};
</script>

<style scoped></style>
