<!-- ChatBot.vue -->
<template>
    <div class="flex flex-col h-full bg-white">
        <!-- Tailwind-inspired Header -->
        <div class="flex items-center justify-between p-3 border-b border-gray-200 bg-white shadow-sm">
            <div class="flex items-center space-x-3">
                <div class="flex-shrink-0 h-10 w-10 rounded-full bg-indigo-100 flex items-center justify-center">
                    <span class="text-xl">🤖</span>
                </div>
                <div>
                    <h2 class="text-base font-medium text-left text-gray-900">
                        AI Analyst (BETA)
                        <span class="inline-flex items-center space-x-1 ml-2" v-if="isOnline">
                            <span class="h-2 w-2 mb-0.5 rounded-full bg-green-500 inline-block"></span>
                            <span class="text-xs text-green-600 font-medium">Online</span>
                        </span>
                    </h2>
                    <p class="text-xs text-gray-500 text-left">Powered by Sondar</p>
                </div>
            </div>
            <button @click="$emit('close')" class="rounded-full p-1.5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-300 transition-all duration-200 flex items-center justify-center bg-white">
                <HeroIconXMark class="h-5 w-5 text-gray-700" />
            </button>
        </div>

        <!-- Chat Messages -->
        <div class="flex-1 overflow-y-auto p-4 space-y-4" ref="messageContainer">
            <div v-if="messages.length === 0" class="flex flex-col h-full">
                <div class="text-center mb-6">
                    <div class="rounded-full bg-indigo-100 p-3 mb-4 inline-block">
                        <span class="text-4xl">👋</span>
                    </div>
                    <h3 class="text-sm font-semibold text-gray-900">Welcome!</h3>
                    <p class="mt-1 text-sm text-gray-500">How can I assist you today?</p>
                </div>
                
                <!-- Suggestion Buttons -->
                <div class="w-full space-y-3">
                    <div v-if="isLoadingSuggestions" class="text-center py-3">
                        <div class="flex justify-center space-x-2">
                            <div class="h-2 w-2 rounded-full bg-indigo-300 animate-bounce"></div>
                            <div class="h-2 w-2 rounded-full bg-indigo-300 animate-bounce" style="animation-delay: 0.2s"></div>
                            <div class="h-2 w-2 rounded-full bg-indigo-300 animate-bounce" style="animation-delay: 0.4s"></div>
                        </div>
                        <p class="text-xs text-gray-500 mt-2">Generating suggestions...</p>
                    </div>
                    
                    <template v-else>
                        <button 
                            v-for="(suggestion, index) in suggestions" 
                            :key="index"
                            @click="useSuggestion(suggestion)"
                            class="w-full text-left px-4 py-3 rounded-lg border border-gray-200 bg-white hover:bg-indigo-50 hover:border-indigo-200 transition-all duration-200 group shadow-sm"
                        >
                            <div class="flex items-center">
                                <div class="flex-shrink-0 mr-3">
                                    <div class="h-8 w-8 rounded-full flex items-center justify-center group-hover:bg-indigo-200 transition-colors"
                                        :class="[
                                            index === 0 ? 'bg-blue-100' : 
                                            index === 1 ? 'bg-green-100' : 
                                            index === 2 ? 'bg-yellow-100' : 
                                            'bg-purple-100'
                                        ]">
                                        <span class="text-lg" 
                                            :class="[
                                                index === 0 ? 'text-blue-600' : 
                                                index === 1 ? 'text-green-600' : 
                                                index === 2 ? 'text-yellow-600' : 
                                                'text-purple-600'
                                            ]">
                                            {{ index === 0 ? '📊' : index === 1 ? '🔍' : index === 2 ? '😊' : '💡' }}
                                        </span>
                                    </div>
                                </div>
                                <p class="text-sm text-gray-700 group-hover:text-indigo-700">{{ suggestion }}</p>
                            </div>
                        </button>
                    </template>
                </div>
            </div>

            <div v-else class="space-y-2">
                <ChatMessage 
                    v-for="(message, index) in messages" 
                    :key="index" 
                    :message="message.text"
                    :is-user="message.isUser"
                    :timestamp="message.timestamp"
                    :status="message.status" 
                />
            </div>

            <!-- Typing Indicator -->
            <div v-if="isTyping" class="flex items-center space-x-2 py-3">
                <div class="flex-shrink-0">
                    <div class="h-9 w-9 rounded-full bg-gradient-to-r from-gray-100 to-gray-200 flex items-center justify-center ring-2 ring-white">
                        <span class="text-xl">🤖</span>
                    </div>
                </div>
                <div class="flex space-x-2">
                    <div class="h-1.5 w-1.5 rounded-full bg-gray-300 animate-bounce"></div>
                    <div class="h-1.5 w-1.5 rounded-full bg-gray-300 animate-bounce" style="animation-delay: 0.2s"></div>
                    <div class="h-1.5 w-1.5 rounded-full bg-gray-300 animate-bounce" style="animation-delay: 0.4s"></div>
                </div>
            </div>
        </div>

        <!-- Clear Chat Button (visible only when there are messages) -->
        <div v-if="messages.length > 0" class="px-4 py-2 flex justify-center">
            <button 
                @click="clearChat" 
                :disabled="isTyping"
                class="text-xs flex items-center space-x-1.5 transition-all duration-200 py-1.5 px-3 rounded-full border"
                :class="isTyping ? 
                    'text-gray-400 border-gray-200 bg-gray-50 cursor-not-allowed' : 
                    'text-gray-600 border-gray-300 bg-white hover:bg-indigo-50 hover:text-indigo-600 hover:border-indigo-300'"
            >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
                <span>Clear Chat</span>
            </button>
        </div>

        <!-- Input Area -->
        <div class="border-t border-gray-200 p-3">
            <div class="flex items-center space-x-2">
                <input
                    type="text"
                    v-model="newMessage"
                    @keyup.enter="sendMessage"
                    placeholder="Type your question here..."
                    class="flex-1 rounded-md border text-sm border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
                <button
                    @click="sendMessage"
                    class="bg-indigo-500 text-sm text-white rounded-md px-4 py-2 hover:bg-indigo-600 transition-colors"
                >
                    Send
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, nextTick, watch, computed } from 'vue'
import ChatMessage from './ChatMessage.vue'
import { XMarkIcon as HeroIconXMark } from '@heroicons/vue/24/outline'
import { getOpenAIResponse } from '@/services/apiService'

export default {
    name: 'ChatBot',
    components: {
        ChatMessage,
        HeroIconXMark
    },
    props: {
        initialMessages: {
            type: Array,
            default: () => []
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        chatPayload: {
            type: Array,
            default: () => []
        }
    },
    emits: ['close', 'send-message'],
    setup(props, { emit }) {
        const messages = ref(props.initialMessages)
        const newMessage = ref('')
        const isTyping = ref(false)
        const isOnline = ref(true)
        const messageContainer = ref(null)
        const chatHistory = ref(['system: How may I help you?'])
        
        // Use ref for suggestions (initially empty, will be populated by AI)
        const suggestions = ref([])
        const isLoadingSuggestions = ref(false)

        const processedData = computed(() => {
            const data = props.chatPayload;
            return data?.map(block => ({
                title: block.title || 'Untitled Block',
                type: block.type?.toLowerCase() || 'text',
                range: [block.min_value, block.max_value],
                choice: block.choices || [],
                responses: block.chatbotConv?.map(conv => ({
                    user: conv?.user || {},
                    response: conv?.response || '',
                    conversations: conv?.con?.map(msg => ({
                        [msg.author]: msg.value
                    })) || []
                })) || []
            })) || [];
        });

        // Helper function to simplify the processed data
        const simplifyData = (data) => {
            return data.map(item => ({
                title: item.title,
                type: item.type,
                range: item.range,
                choice: item.choice
            }));
        };

        // Function to generate AI-suggested questions
        const generateAISuggestions = async () => {
            try {
                isLoadingSuggestions.value = true;
                
                // Get simplified data using the helper function
                const simplifiedData = simplifyData(processedData.value);
                
                // Create a prompt with the simplified data context
                const prompt = `Suggest 4 questions to help the survey owner better understand their product based on survey questions, remember these questions are the ones that survey owner wants to ask considering the dataset and not the end user. Keep the questions short, under 10 words each..
                
                Dataset: ${JSON.stringify(simplifiedData)}`;
                
                // Call OpenAI API
                const response = await getOpenAIResponse(prompt, {
                    temperature: 0.7,
                    maxTokens: 200
                });
                
                // Parse the response - assuming it returns a numbered or line-separated list
                const questionList = response
                    .split(/\n/)
                    .filter(line => line.trim())
                    .map(line => line.replace(/^(\d+\.|\*|-)\s*/, '').trim())
                    .filter(line => line.length > 0)
                    .slice(0, 4); // Ensure we get exactly 4 questions
                
                if (questionList.length === 4) {
                    suggestions.value = questionList;
                } else {
                    // Fallback if parsing fails
                    suggestions.value = [
                        "What patterns appear in this data?",
                        "Identify common themes in responses",
                        "Summarize the key sentiments",
                        "What insights can you extract?"
                    ];
                }
            } catch (error) {
                console.error('Error generating AI suggestions:', error);
                // Fallback suggestions in case of API error
                suggestions.value = [
                    "What patterns appear in this data?",
                    "Identify common themes in responses",
                    "Summarize the key sentiments",
                    "What insights can you extract?"
                ];
            } finally {
                isLoadingSuggestions.value = false;
            }
        };

        watch(processedData, async (newData) => {
            // Generate new suggestions when data changes
            if (newData && newData.length > 0 && suggestions.value.length === 0) {
                await generateAISuggestions();
            }
        }, { deep: true });

        const scrollToBottom = async () => {
            await nextTick()
            if (messageContainer.value) {
                messageContainer.value.scrollTop = messageContainer.value.scrollHeight
            }
        }
        
        // Method to handle suggestion button clicks
        const useSuggestion = (suggestion) => {
            newMessage.value = suggestion
            sendMessage()
        }

        const sendMessage = async () => {
            const messageText = newMessage.value.trim()
            if (!messageText) return

            // Add user message
            messages.value.push({
                text: messageText,
                isUser: true,
                timestamp: new Date(),
                status: 'sent'
            })

            // Start typing indicator
            isTyping.value = true

            newMessage.value = ''
            await scrollToBottom()

            try {
                // Make API call to the assistant endpoint
                const response = await fetch(`${process.env.VUE_APP_SUPABASE_EF_BASE_URL}/assistant`, {
                // const response = await fetch("http://0.0.0.0:8000/", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        question: messageText,
                        data: processedData.value,
                        chat_history: chatHistory.value
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to get response from assistant');
                }

                const data = await response.json();
                
                // Update chat history with user message and bot response
                chatHistory.value.push(`user: ${messageText}`);
                chatHistory.value.push(`assistant: ${data.data.output || 'Sorry, I could not process your request.'}`);

                // Add bot response
                messages.value.push({
                    text: data.data.output || 'Sorry, I could not process your request.',
                    isUser: false,
                    timestamp: new Date(),
                    status: 'received'
                });
            } catch (error) {
                console.error('Error:', error);
                // Add error message
                messages.value.push({
                    text: 'Sorry, I encountered an error while processing your request.',
                    isUser: false,
                    timestamp: new Date(),
                    status: 'error'
                });
            } finally {
                isTyping.value = false
                await scrollToBottom()
            }
        }

        const toggleChat = () => {
            emit('close')
        }

        // Watch messages for changes and scroll to bottom
        watch(messages, () => {
            scrollToBottom()
        }, { deep: true })

        onMounted(() => {
            scrollToBottom()
            // Generate suggestions on initial mount if data is available
            if (processedData.value && processedData.value.length > 0) {
                generateAISuggestions()
            }
        })

        const clearChat = () => {
            messages.value = []
            chatHistory.value = ['system: How may I help you?']
            scrollToBottom()
        }

        return {
            messages,
            newMessage,
            isTyping,
            isOnline,
            messageContainer,
            sendMessage,
            toggleChat,
            suggestions,
            useSuggestion,
            clearChat,
            isLoadingSuggestions
        }
    }
}
</script>

<style scoped>
.chatbot-container {
    display: flex;
    flex-direction: column;
    height: 600px;
    width: 400px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background-color: white;
}

.chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
}

.typing-indicator {
    display: flex;
    padding: 1rem;
    gap: 4px;
}

.dot {
    width: 8px;
    height: 8px;
    background-color: #e5e7eb;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out;
}

.dot:nth-child(1) {
    animation-delay: -0.32s;
}

.dot:nth-child(2) {
    animation-delay: -0.16s;
}

@keyframes bounce {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}

/* Custom Scrollbar */
.chat-messages::-webkit-scrollbar {
    width: 6px;
}

.chat-messages::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.chat-messages::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>