<template>
  <div id="profile">
    <!-- Organization Profile Section -->
    <div class="px-4 sm:px-0">
      <h3 class="text-base/7 font-semibold text-gray-900">Organization Profile</h3>
    </div>
    <div class="mt-6 border-t border-b border-gray-100">
      <dl class="divide-y divide-gray-100">
        <div class="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0 group">
          <div class="text-sm/6 font-medium text-gray-900">Name</div>
          <div class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
            {{ tenantName }}
          </div>
        </div>
        <div class="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
          <div class="text-sm/6 font-medium text-gray-900">API Key</div>
          <div class="mt-2 text-sm text-gray-900 sm:col-span-3 sm:mt-0 relative">
            <div class="group relative">
              <div
                class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-50 group-hover:ring-2 group-hover:ring-inset group-hover:ring-indigo-600 transition-all duration-200">
                <div class="flex-1 py-2.5 pl-3 pr-12 text-gray-900 font-mono text-sm truncate">
                  {{ tenantApiKey }}
                </div>
                <div class="absolute inset-y-0 right-0 flex items-center">
                  <button type="button"
                    class="inline-flex h-full items-center rounded-r-md px-3 hover:focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 group/button transition-colors duration-200"
                    @click="copyToClipboard">
                    <svg
                      class="h-4 w-4 text-gray-400 group-hover/button:text-indigo-600 transition-colors duration-200"
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184" />
                    </svg>
                    <span class="sr-only">Copy API key</span>
                  </button>
                </div>
              </div>
            </div>
            <p class="mt-2 text-xs text-gray-500">
              Use this key to authenticate API requests from your application.
            </p>
          </div>
        </div>
        <div class="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
          <div class="text-sm/6 font-medium text-gray-900">JavaScript SDK</div>
          <div class="mt-2 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
            <div class="flex items-center gap-x-2">
              <div class="h-2.5 w-2.5 rounded-full" :class="sdkConnected ? 'bg-green-500' : 'bg-red-500'"></div>
              <span class="text-sm text-gray-600">{{ sdkConnected ? 'Connected' : 'Not Connected' }}</span>
            </div>
          </div>
        </div>
      </dl>
    </div>

    <!-- My Profile Section -->
    <div class="mt-16 px-4 sm:px-0">
      <h3 class="text-base/7 font-semibold text-gray-900">My Profile</h3>
    </div>
    <div class="mt-6 border-t border-b border-gray-100">
      <dl class="divide-y divide-gray-100">
        <div class="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0 group">
          <div class="text-sm/6 font-medium text-gray-900">Name</div>
          <div class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
            {{ currentUser.name || '-' }}
          </div>
        </div>
        <div class="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0 group">
          <div class="text-sm/6 font-medium text-gray-900">Email</div>
          <div class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
            {{ currentUser.email }}
          </div>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  currentUser: {
    type: Object,
    required: true
  },
  tenantName: {
    type: String,
    required: true
  },
  tenantApiKey: {
    type: String,
    required: true
  },
  sdkConnected: {
    type: Boolean,
    required: true
  }
});

const emit = defineEmits(['copy-success']);

async function copyToClipboard() {
  try {
    await navigator.clipboard.writeText(props.tenantApiKey);
    emit('copy-success');
  } catch (err) {
    console.error('Failed to copy:', err);
  }
}
</script> 