import { DateTime } from "luxon";
export const getLocalDateTimeString = (dateString) => {
    if (!dateString) return "N/A";

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const dt = DateTime.fromISO(dateString.split(".")[0], { zone: "utc" })
        .setZone(userTimeZone);

    const getOrdinal = (day) => {
        if (day > 3 && day < 21) return "th";
        switch (day % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    };

    return dt.toFormat(`d'${getOrdinal(dt.day)}' MMM yyyy, hh:mm a`);
    // Format: 28th Feb 2025, 12:00 PM
};
