<template>
  <div class="relative h-screen">
    <div class="grid lg:grid-cols-5 h-full">
      <!-- Left side with form -->
      <div class="flex flex-col lg:col-span-2">
        <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <img class="mx-auto h-10 w-auto" src="../../assets/full-logo.svg" alt="Your Company" />
            <h2 class="mt-8 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Create Your Account
            </h2>

          </div>

          <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form @submit.prevent="handleSignUp" class="space-y-6">
              <div>
                <label for="name" class="block text-sm font-medium leading-6 text-gray-900 text-left">Full name</label>
                <div class="mt-2">
                  <input v-model="name" type="text" name="name" id="name" autocomplete="name" required
                    placeholder="Sarah Ho"
                    class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                  <p v-if="nameError" class="mt-1 text-left text-sm text-red-600">{{ nameError }}</p>
                </div>
              </div>

              <div>
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900 text-left">Work Email</label>
                <div class="mt-2">
                  <input v-model="email" type="email" name="email" id="email" autocomplete="email" required
                    placeholder="sarah@openai.com" @input="validateEmail"
                    class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                  <p v-if="emailError" class="mt-1 text-left text-sm text-red-600">{{ emailError }}</p>
                </div>
              </div>

              <div>
                <label for="organizationName"
                  class="block text-sm font-medium leading-6 text-gray-900 text-left">Organization name</label>
                <div class="mt-2">
                  <input v-model="organizationName" type="text" name="organizationName" id="organizationName" required
                    placeholder="Open AI"
                    class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                  <p v-if="organizationError" class="mt-1 text-left text-sm text-red-600">{{ organizationError }}</p>
                </div>
              </div>

              <div class="mt-4 text-sm text-gray-500">
                By creating an account, you agree to our
                <a href="https://www.sondar.ai/terms-of-service" target="_blank"
                  class="font-semibold text-indigo-600 hover:text-indigo-500">Terms of Service</a>
                and
                <a href="https://www.sondar.ai/privacy" target="_blank"
                  class="font-semibold text-indigo-600 hover:text-indigo-500">Privacy Policy</a>.
              </div>

              <div>
                <button type="submit" :disabled="isLoading || !isFormValid"
                  class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-300 disabled:cursor-not-allowed">
                  <template v-if="isLoading">
                    <svg class="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                      fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                      </path>
                    </svg>
                    Creating account...
                  </template>
                  <template v-else>
                    Sign up
                  </template>
                </button>
              </div>

              <p v-if="formError" class="mt-4 text-sm text-red-600">{{ formError }}</p>

              <p class="mt-10 text-center text-sm text-gray-500">
                Already have an account?
                <router-link to="/login" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                  Sign in
                </router-link>
              </p>
            </form>
          </div>
        </div>
      </div>

      <!-- Right side  -->
      <div class="relative hidden lg:block lg:col-span-3 bg-zinc-100">
        <div class="flex flex-col items-center justify-center h-full mx-auto max-w-3xl gap-8">
          <img src="../../assets/coinjar_testimonial.png" alt="CoinJar Testimonial" class="max-w-[80%] h-auto" />
          <div class="flex flex-row items-center justify-center gap-4">
            <img src="../../assets/g2_badge_1.png" alt="G2 Badge 1" class="h-32 w-auto" />
            <img src="../../assets/g2_badge_2.png" alt="G2 Badge 2" class="h-32 w-auto" />
            <img src="../../assets/g2_badge_3.png" alt="G2 Badge 3" class="h-32 w-auto" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import { useAuthStore } from '@/stores/auth'

// Reactive state
const name = ref('')
const email = ref('')
const organizationName = ref('')
const nameError = ref('')
const emailError = ref('')
const organizationError = ref('')
const formError = ref('')
const isLoading = ref(false)

// List of common personal email domains that should be blocked
const blockedEmailDomains = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'mail.com',
  'protonmail.com',
  'zoho.com',
  'yandex.com',
  'live.com',
  'msn.com'
]

// Enhanced email validation
const validateEmail = () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  if (!emailRegex.test(email.value)) {
    emailError.value = 'Please enter a valid email address'
    return
  }

  const domain = email.value.split('@')[1].toLowerCase()
  if (blockedEmailDomains.includes(domain)) {
    emailError.value = 'Please use your work email address instead of a personal email'
    return
  }

  emailError.value = ''
}

const handleSignUp = async () => {
  // Reset errors
  nameError.value = ''
  emailError.value = ''
  organizationError.value = ''
  formError.value = ''

  // Validate inputs
  if (!name.value) {
    nameError.value = 'Name is required'
    return
  }
  if (!email.value) {
    emailError.value = 'Email is required'
    return
  }
  if (!organizationName.value) {
    organizationError.value = 'Organization name is required'
    return
  }

  try {
    isLoading.value = true
    const authStore = useAuthStore()
    await authStore.signUp({
      name: name.value,
      email: email.value,
      organizationName: organizationName.value,
    });
    await handleSignUpSuccess();
    
  } catch (err) {
    formError.value = err.message || 'An unexpected error occurred. Please try again later.'
  } finally {
    isLoading.value = false
  }
}

const handleSignUpSuccess = async () => {
  isLoading.value = false;
  formError.value = '';
  window.location.href = `/signup-success?email=${encodeURIComponent(email.value)}`;
}

// Computed property to check if form is valid
const isFormValid = computed(() => {
  // Check if all required fields are filled
  if (!name.value || !email.value || !organizationName.value) {
    return false
  }

  // Check if email is valid (no errors)
  if (emailError.value) {
    return false
  }

  // Check if there are no other errors
  if (nameError.value || organizationError.value || formError.value) {
    return false
  }

  return true
})

// Add validation for name and organization when they change
const validateName = () => {
  nameError.value = name.value.trim() ? '' : 'Name is required'
}

const validateOrganization = () => {
  organizationError.value = organizationName.value.trim() ? '' : 'Organization name is required'
}

// Update watchers to validate all fields
watch([email], () => {
  if (email.value) validateEmail()
})

watch([name], () => {
  if (name.value) validateName()
})

watch([organizationName], () => {
  if (organizationName.value) validateOrganization()
})
</script>
