<template>
  <div class="p-6">
    <GlobalNotification
      v-if="showNotification"
      message="URL copied to clipboard"
      type="ok"
    />
    <!-- Loading Spinner -->
    <div v-if="isLoading">
      <div class="min-h-screen animate-pulse space-y-6 bg-gray-50 p-6">
        <div>
          <div class="mb-2 h-6 w-32 rounded bg-gray-200"></div>
          <div class="h-10 w-full rounded bg-gray-300"></div>
        </div>
        <div>
          <div class="mb-2 h-6 w-56 rounded bg-gray-200"></div>
          <div class="flex space-x-4">
            <div class="h-16 flex-1 rounded bg-gray-300"></div>
            <div class="h-16 flex-1 rounded bg-gray-300"></div>
          </div>
        </div>
        <div>
          <div class="mb-2 h-6 w-40 rounded bg-gray-200"></div>
          <div class="flex items-center space-x-4">
            <div class="h-10 w-32 rounded bg-gray-300"></div>
            <div class="h-16 w-24 rounded bg-gray-200"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="space-y-8" v-if="!isLoading">
      <div class="border-b border-gray-200 pb-8">
        <dl class="divide-y divide-gray-200 text-left">
          <!-- Distribution Channel -->
          <div class="py-6 sm:grid sm:grid-cols-4 sm:gap-4">
            <dt class="text-sm font-medium text-gray-900">Distribution Channel</dt>
            <dd class="mt-1 text-sm text-gray-600 sm:col-span-3 sm:mt-0">
              <div class="flex items-center gap-x-2">
                <div
                  class="inline-flex items-center rounded-md gap-1.5 px-3 py-2 text-sm font-medium ring-1 ring-inset"
                  :class="{
                    'bg-green-100 text-green-800 ring-green-600/20': distributionChannel,
                    'bg-gray-100 text-gray-800 ring-gray-600/20': !distributionChannel
                  }"
                >
                  <GlobeAltIcon v-if="distributionChannel.toLowerCase() === 'web'" class="h-4 w-4" />
                  <LinkIcon v-else class="h-4 w-4" />
                  <span class="capitalize">{{ distributionChannel || 'Not set' }}</span>
                </div>
                <SurveyLink 
                  v-if="distributionChannel.toLowerCase() === 'sharable link'"
                  :surveyId="survey_id"
                  @copied="handleCopied"
                />
                <SDKConnectionStatus 
                  :channel="distributionChannel" 
                  :sdkConnected="sdkConnected" 
                />
                <a 
                  v-if="distributionChannel.toLowerCase() === 'web' && !sdkConnected"
                  :href="SDK_GUIDE_URL"
                  target="_blank"
                  class="inline-flex items-center gap-x-1.5 text-sm text-indigo-600 hover:text-indigo-500 font-medium"
                >
                  <BookOpenIcon class="h-4 w-4" />
                  SDK installation guide
                  <span class="sr-only">Opens in a new tab</span>
                </a>
              </div>
            </dd>
          </div>

          <!-- Trigger -->
          <div v-if="distributionChannel.toLowerCase() === 'web'" class="py-6 sm:grid sm:grid-cols-4 sm:gap-4">
            <dt class="text-sm font-medium text-gray-900">Trigger</dt>
            <dd class="mt-1 text-sm text-gray-600 sm:col-span-3 sm:mt-0">
              <div v-if="triggerType === 'tracked_event'" class="flex items-center gap-1">
                <span>When </span>
                <span class="inline-flex items-center rounded-md bg-indigo-100 px-3 py-1 text-sm font-medium text-indigo-800 ring-1 ring-inset ring-indigo-600/20">{{ trackedEvent }}</span>
                <span>has occured</span>
                <span class="inline-flex items-center rounded-md bg-indigo-100 px-3 py-1 text-sm font-medium text-indigo-800 ring-1 ring-inset ring-indigo-600/20">{{ conditionValue }} times</span>
              </div>

							<div v-if="triggerType === 'page_visit'" class="flex items-center gap-1">
                <div v-if="conditionOperator === ''">
									<span>When a user visits any page on your website</span>
								</div>
								<div v-if="conditionOperator === 'exact_match'">
									<span>When a user visits this exact url: </span>
									<span class="font-bold">{{ conditionValue }}</span>
								</div>
								<div v-if="conditionOperator !== '' && conditionOperator !== 'exact_match' && conditionOperator !== 'regex'">
									<span>When a user visits a page on your site that </span>
									<span class="inline-flex items-center rounded-md bg-indigo-100 px-3 py-1 mr-1 text-sm font-medium text-indigo-800 ring-1 ring-inset ring-indigo-600/20">{{ conditionOperator }}</span>
									<span class="font-bold">{{ conditionValue }}</span>
								</div>
								<div v-if="conditionOperator === 'regex'">
									<span>When a user visits a page on your site that matches this regex pattern: </span>
									<span class="font-bold">{{ conditionValue }}</span>
								</div>
              </div>
            </dd>
          </div>

          <!-- Audience -->
          <div class="py-6 sm:grid sm:grid-cols-4 sm:gap-4" v-if="distributionChannel && distributionChannel.toLowerCase() === 'web'">
            <dt class="text-sm font-medium text-gray-900">Audience</dt>
            <dd class="mt-1 text-sm text-gray-600 sm:col-span-3 sm:mt-0">
              <div v-if="userAttributes.length === 0" class="text-gray-500">No audience filters set</div>
              <div v-else class="space-y-4">
                <div v-for="(attr, index) in userAttributes" :key="index" class="flex items-center gap-1">
                  <span 
                    v-if="index > 0" 
                    class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-sm font-medium text-gray-600 ring-1 ring-inset ring-gray-500/20"
                  >
                    {{ attr.condition_type.toLowerCase() }}
                  </span>
                  <span>{{ capitalizeFirstLetter(attr.attribute) }}</span>
                  <span class="font-bold text-gray-900">{{ attr.operator === 'in' ? '=' : attr.operator }}</span>
                  <div class="flex flex-wrap items-center gap-1">
                    <span 
                      v-if="typeof attr.value === 'number' || typeof attr.value === 'string'"
                      class="inline-flex items-center rounded-md bg-indigo-100 px-3 py-1 text-sm font-medium text-indigo-800 ring-1 ring-inset ring-indigo-600/20"
                    >
                      {{ attr.value }}
                    </span>
                    <template v-else>
                      <template v-for="(val, valIndex) in attr.value" :key="valIndex">
                        <span 
                          class="inline-flex items-center rounded-md bg-indigo-100 px-3 py-1 text-sm font-medium text-indigo-800 ring-1 ring-inset ring-indigo-600/20"
                        >
                          {{ attr.attribute === 'language' ? getLanguageLabel(val) : val }}
                        </span>
                        <span 
                          v-if="valIndex < attr.value.length - 1"
                          class="inline-flex items-center text-sm text-gray-500 px-1"
                        >
                          or
                        </span>
                      </template>
                    </template>
                  </div>
                </div>
              </div>
            </dd>
          </div>

          <!-- Other Conditions -->
          <div class="py-6 sm:grid sm:grid-cols-4 sm:gap-4" v-if="distributionChannel && distributionChannel.toLowerCase() === 'web' || responseLimit !== null && responseLimit !== undefined && responseLimit > 0">
            <dt class="text-sm font-medium text-gray-900">Survey Settings</dt>
            <dd class="mt-1 text-sm text-gray-600 sm:col-span-3 sm:mt-0">
              <div class="space-y-4">
                <div v-if="delayPopup > 0" class="flex items-center gap-1">
                  <span>Shows after</span>
                  <span class="inline-flex items-center rounded-md bg-indigo-100 px-3 py-1 text-sm font-medium text-indigo-800 ring-1 ring-inset ring-indigo-600/20">{{ delayPopup }} seconds</span>
                </div>
                <div v-if="responseLimit !== null && responseLimit !== undefined && responseLimit > 0" class="flex items-center gap-1">
                  <span>Limited to</span>
                  <span class="inline-flex items-center rounded-md bg-indigo-100 px-3 py-1 text-sm font-medium text-indigo-800 ring-1 ring-inset ring-indigo-600/20">{{ responseLimit }} responses</span>
                </div>
                <div v-if="widgetPosition && distributionChannel.toLowerCase() === 'web'" class="flex items-center gap-1">
                  <span>Widget appears at</span>
                  <span class="inline-flex items-center rounded-md bg-indigo-100 px-3 py-1 text-sm font-medium text-indigo-800 ring-1 ring-inset ring-indigo-600/20">{{ formatWidgetPosition(widgetPosition) }}</span>
                </div>
                <div v-if="distributionChannel && distributionChannel.toLowerCase() === 'web'" class="flex items-center gap-1">
                  <span>Widget can be dismissed:</span>
                  <span class="inline-flex items-center rounded-md px-3 py-1 text-sm font-medium ring-1 ring-inset" :class="canDismissPopup ? 'bg-indigo-100 text-indigo-800 ring-indigo-600/20' : 'bg-red-100 text-red-800 ring-red-600/20'">
                    {{ canDismissPopup ? 'Yes' : 'No' }}
                  </span>
                </div>
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { GlobeAltIcon, LinkIcon, BookOpenIcon } from '@heroicons/vue/24/outline';
import SDKConnectionStatus from '@/components/commons/SDKConnectionStatus.vue';
import GlobalNotification from '@/components/commons/GlobalNotification.vue';
import SurveyLink from '@/components/commons/SurveyLink.vue';
import { fetchSurveyConfigDetails, checkTenantSDKConnection, fetchLanguages } from "@/services/supabaseService";
import { useAuthStore } from '@/stores/auth';

const SDK_GUIDE_URL = 'https://help.sondar.ai/en/articles/10489683-installing-the-javascript-sdk';

const route = useRoute();
const authStore = useAuthStore();

// Reactive state
const context = ref("");
const survey_id = ref(route.params.survey_id);
const isLoading = ref(true);
const trackedEvent = ref("");
const conditionValue = ref("");
const conditionOperator = ref("");
const distributionChannel = ref("");
const sdkConnected = ref(false);
const triggerType = ref("");
const triggerConditions = ref([]);
const userAttributes = ref([]);
const delayPopup = ref();
const canDismissPopup = ref(true);
const responseLimit = ref();
const widgetPosition = ref("");

// Add notification state
const showNotification = ref(false);

// Add languageMap to store language code to label mapping
const languageMap = ref({});

// Helper function to format widget position
const formatWidgetPosition = (position) => {
  if (!position) return "";
  return position.split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// Helper functions
const handleCopied = () => {
  showNotification.value = true;
  setTimeout(() => {
    showNotification.value = false;
  }, 2000);
};

const fetchSurvey = async () => {
  try {
    const [data, languages] = await Promise.all([
      fetchSurveyConfigDetails(survey_id.value),
      fetchLanguages()
    ]);

    // Create language mapping
    languageMap.value = languages.reduce((acc, lang) => {
      acc[lang.value] = lang.label;
      return acc;
    }, {});

    context.value = data.llm_context;
    triggerConditions.value = data.conditions;
    
    // Update tracked event details from conditions
    if (data.conditions && data.conditions.length > 0) {
      const condition = data.conditions[0];
      trackedEvent.value = condition.event || "";
			conditionOperator.value = condition.operator || "";
      conditionValue.value = condition.value?.toString() || "";
    }
    
    triggerType.value = data.trigger_type;
    userAttributes.value = data.user_attrs;
    delayPopup.value = data.delay_seconds;
    canDismissPopup.value = data.can_dismiss;
    distributionChannel.value = data.survey_distribution_config?.[0]?.channel || "";
    responseLimit.value = data.response_limit;
    widgetPosition.value = data.widget_position;

    // Check SDK connection if channel is Web
    if (distributionChannel.value.toLowerCase() === 'web') {
      const sdkStatus = await checkTenantSDKConnection(authStore.tenantId);
      sdkConnected.value = sdkStatus.sdk_connected;
    }
  } catch (err) {
    console.error("Unexpected error:", err);
  } finally {
    isLoading.value = false;
  }
};

// Add helper function to get language label
const getLanguageLabel = (code) => {
  return languageMap.value[code] || code;
};

// Add helper function to capitalize first letter
const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Lifecycle hooks
onMounted(() => {
  fetchSurvey();
});
</script>

<style scoped>
button {
  outline: none;
  cursor: pointer;
}

.material-icons {
  font-size: 20px;
}
</style>
