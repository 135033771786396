import supabase from "@/supabase";

// Tenant Management
export const fetchTenants = async () => {
    const { data, error } = await supabase
        .from("tenants")
        .select("*")
        .order("created_at", { ascending: false });

    if (error) throw error;
    return data;
};

export const checkExistingUser = async (email) => {
    const { data, error } = await supabase
        .from("users")
        .select("email")
        .eq("email", email)
        .single();

    if (error && error.code !== "PGRST116") throw error;
    return data;
};

export const createTenant = async (name, apiKey) => {
    const { data, error } = await supabase
        .from("tenants")
        .insert({
            name: name,
            api_key: apiKey,
        })
        .select()
        .single();

    if (error) throw error;
    return data;
};

export const inviteUser = async (email, redirectUrl) => {
    const { data, error } = await supabase.auth.admin.inviteUserByEmail(
        email,
        {
            redirectTo: redirectUrl,
        },
    );

    if (error) throw error;
    return data;
};

export const createUserProfile = async (
    userId,
    email,
    name,
    roleId,
    tenantId,
) => {
    const { error } = await supabase
        .from("users")
        .insert({
            id: userId,
            email: email,
            name: name,
            role_id: roleId,
            tenant_id: tenantId,
        });

    if (error) throw error;
};

export const deleteTenant = async (tenantId) => {
    const { error } = await supabase
        .from("tenants")
        .delete()
        .eq("id", tenantId);

    if (error) throw error;
};

// Tenant Settings
export const getCurrentUserDetails = async (userId) => {
    const { data, error } = await supabase
        .from("users")
        .select("name, email")
        .eq("id", userId)
        .single();

    if (error) throw error;
    return data;
};

export const getTenantById = async (tenantId) => {
    const { data, error } = await supabase
        .from("tenants")
        .select()
        .eq("id", tenantId)
        .single();

    if (error) throw error;
    return data;
};

export const getTeamMembersByTenantId = async (tenantId) => {
    const { data, error } = await supabase
        .from("users")
        .select("*, roles(name)")
        .eq("tenant_id", tenantId);

    if (error) throw error;
    return data.map((user) => ({
        ...user,
        role: user.roles?.name || "tenant_member",
    }));
};

export const checkTenantSDKConnection = async (tenantId) => {
    const { data, error } = await supabase
        .from("tenants")
        .select("sdk_connected")
        .eq("id", tenantId)
        .single();

    if (error) throw error;
    return data;
};

export const checkTenantOnboardingStatus = async (tenantId) => {
    const { data, error } = await supabase
        .from("tenants")
        .select("seen_onboarding_survey")
        .eq("id", tenantId)
        .single();

    if (error) throw error;
    return data?.seen_onboarding_survey;
};

export const updateTenantOnboardingStatus = async (tenantId) => {
    const { error } = await supabase
        .from("tenants")
        .update({ seen_onboarding_survey: true })
        .eq("id", tenantId);

    if (error) throw error;
};

export const addToMailingLists = async (email, name, organizationName) => {
    try {
        await fetch(
            `${process.env.VUE_APP_SUPABASE_EF_BASE_URL}/subscribe-to-mailing-list`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":
                        `Bearer ${process.env.VUE_APP_SUPABASE_KEY}`,
                },
                body: JSON.stringify({
                    email: email,
                    name: name,
                    company: organizationName,
                }),
            },
        );
    } catch (err) {
        console.log(err);
    }
};

export const inviteTeamMember = async (
    email,
    redirectUrl,
    name,
    tenantId,
    tenantName,
    role
) => {
    const { data, error } = await supabase.auth.admin.inviteUserByEmail(
        email,
        {
            redirectTo: redirectUrl,
            data: {
                full_name: name,
                email_confirmed: false,
            },
        },
    );

    if (error) throw error;

    const { error: userError } = await supabase
        .from("users")
        .insert({
            id: data.user.id,
            email: email,
            name: name,
            role_id: role,
            tenant_id: tenantId,
        });

    if (userError) throw userError;
    await addToMailingLists(email, name, tenantName);
    return data;
};

export const updateTeamMember = async (email, tenantId, updates) => {
    const { error } = await supabase
        .from("users")
        .update(updates)
        .eq("email", email)
        .eq("tenant_id", tenantId);

    if (error) throw error;
};

// Attribute Management
export const fetchTenantAttributes = async (tenantId) => {
    let query = supabase
        .from("tenant_user_attributes")
        .select("id, attribute_name, data_type, created_at, description")
        .order("created_at", { ascending: false });

    if (tenantId) {
        query = query.eq("tenant_id", tenantId);
    }

    const { data, error } = await query;

    if (error) throw error;

    return data.map((attribute) => ({
        id: attribute.id,
        name: attribute.attribute_name,
        dataType: attribute.data_type,
        dataTypeDisplay: attribute.data_type === "datetime-local"
            ? "date & time"
            : attribute.data_type,
        createdAt: attribute.created_at,
        description: attribute.description,
        showMenu: false,
    }));
};

export const createTenantAttribute = async (attributeData) => {
    const { error } = await supabase
        .from("tenant_user_attributes")
        .insert([{
            attribute_name: attributeData.name.trim(),
            data_type: attributeData.dataType,
            description: attributeData.description.trim() || null,
            tenant_id: attributeData.tenantId,
        }]);

    if (error) throw error;
};

// Survey Configuration
export const fetchTenantEvents = async () => {
    const { data, error } = await supabase
        .from("tenant_event")
        .select("event_name")
        .order("created_at", { ascending: false });

    if (error) throw error;
    return data.map((event) => ({
        name: event.event_name,
    }));
};

export const fetchLanguages = async () => {
    const { data, error } = await supabase
        .from("languages")
        .select("label, value");

    if (error) throw error;
    return data.map((lang) => ({
        label: lang.label,
        value: lang.value,
    }));
};

export const fetchTenantUserAttributes = async (tenantId) => {
    const { data, error } = await supabase
        .from("tenant_user_attributes")
        .select("attribute_name, data_type")
        .eq("tenant_id", tenantId)
        .order("created_at", { ascending: false });

    if (error) throw error;
    return data;
};

export const fetchSurveyConfiguration = async (surveyId) => {
    const { data, error } = await supabase
        .from("tenant_surveys")
        .select(`
            conditions, 
            user_attrs, 
            trigger_type, 
            can_dismiss, 
            delay_seconds, 
            response_limit,
            widget_position,
            background_tint,
            survey_distribution_config (
                channel
            )
        `)
        .eq("id", surveyId)
        .single();

    if (error) throw error;
    return data;
};

export const updateSurveyConfiguration = async (surveyId, configData) => {
    const { error } = await supabase
        .from("tenant_surveys")
        .update(configData)
        .eq("id", surveyId);

    if (error) throw error;
};

// Survey Block Management
export const deleteSurveyBlock = async (blockId) => {
    const { error } = await supabase
        .from("survey_blocks")
        .delete()
        .eq("id", blockId);

    if (error) throw error;
};

export const syncSurveyBlocks = async (transformedData) => {
    const { error } = await supabase
        .from("survey_blocks")
        .upsert(transformedData);

    if (error) throw error;
};

export const loadSurveyBlocks = async (surveyId) => {
    const { data, error } = await supabase
        .from("survey_blocks")
        .select("*")
        .eq("survey_id", surveyId)
        .order("position", { ascending: true });

    if (error) throw error;
    return data;
};

// Survey Management
export const fetchSurveyDetails = async (surveyId) => {
    const { data, error } = await supabase
        .from("tenant_surveys")
        .select(`
            id, 
            status, 
            title, 
            response_limit, 
            tenant_id, 
            tenants(name), 
            title, 
            responses:responses(id, is_preview, response_messages:response_messages(id)),
            survey_distribution_config (
                channel
            )
        `)
        .eq("id", surveyId)
        .single();

    if (error) throw error;
    return data;
};

export const updateSurveyStatus = async (surveyId, newStatus) => {
    const { error } = await supabase
        .from("tenant_surveys")
        .update({ status: newStatus })
        .eq("id", surveyId);

    if (error) throw error;
};

// Event Management
export const fetchEventDetails = async () => {
    const { data: eventData, error: eventError } = await supabase
        .from("tenant_event")
        .select("id, event_name, created_at, description")
        .order("created_at", { ascending: false });

    if (eventError) throw eventError;

    // Fetch metrics
    const { data: metricsData, error: metricsError } = await supabase
        .from("event_metrics")
        .select("event_name, occurrence_count");

    if (metricsError) throw metricsError;

    // Compute the total occurrence count for each event_name
    const metricsMap = (metricsData || []).reduce((acc, metric) => {
        if (!acc[metric.event_name]) {
            acc[metric.event_name] = 0;
        }
        acc[metric.event_name] += metric.occurrence_count;
        return acc;
    }, {});

    // Map events with their metrics
    return eventData.map((event) => ({
        id: event.id,
        name: event.event_name,
        createdAt: event.created_at,
        description: event.description,
        occurrenceCount: metricsMap[event.event_name] || 0,
        showMenu: false,
    }));
};

export const createEvent = async (eventName, description, tenantId) => {
    const { error } = await supabase
        .from("tenant_event")
        .insert([{
            event_name: eventName,
            description: description,
            tenant_id: tenantId,
        }]);

    if (error) throw error;
};

// Survey Overview Management
export const fetchSurveyOverview = async (surveyId) => {
    const { data, error } = await supabase
        .from("tenant_surveys")
        .select(`
            title, 
            llm_context,
            survey_distribution_config (
                channel
            )
        `)
        .eq("id", surveyId)
        .single();

    if (error) throw error;
    return data;
};

export const updateSurveyContext = async (surveyId, context) => {
    const { error } = await supabase
        .from("tenant_surveys")
        .update({ llm_context: context })
        .eq("id", surveyId);

    if (error) throw error;
};

export const updateSurveyTitle = async (surveyId, title) => {
    const { error } = await supabase
        .from("tenant_surveys")
        .update({ title: title })
        .eq("id", surveyId);

    if (error) throw error;
};

// Survey Share Management
export const fetchSurveyConfigDetails = async (surveyId) => {
    const { data, error } = await supabase
        .from("tenant_surveys")
        .select(`
            llm_context,
            widget_position,
            conditions,
            user_attrs,
            delay_seconds,
            can_dismiss,
            trigger_type,
            response_limit,
            survey_distribution_config (
                channel
            )
        `)
        .eq("id", surveyId)
        .single();

    if (error) throw error;
    return data;
};

export const updateSurveyWidgetPosition = async (surveyId, position) => {
    const { error } = await supabase
        .from("tenant_surveys")
        .update({ widget_position: position })
        .eq("id", surveyId);

    if (error) throw error;
};

// Survey List Management
export const fetchSurveysWithDetails = async (tenantId = null) => {
    let query = supabase
        .from("tenant_surveys")
        .select(`
            id, 
            tenant_id, 
            tenants(name), 
            title,
            status,
            response_limit,
            created_at,
            updated_at,
            survey_distribution_config (
                channel
            ),
            responses:responses(id, is_preview, response_messages:response_messages(id))
        `)
        .order("created_at", { ascending: false });

    if (tenantId) {
        query = query.eq("tenant_id", tenantId);
    }

    const { data, error } = await query;
    if (error) throw error;

    return data.map((survey) => ({
        id: survey.id,
        title: survey.title,
        status: survey.status || "draft",
        responseLimit: survey.response_limit,
        responses: survey.responses
            ? survey.responses.filter(
                (response) =>
                    !response.is_preview &&
                    response.response_messages &&
                    response.response_messages.length > 0,
            ).length
            : 0,
        tenant: survey.tenants?.name,
        distributionChannel: survey.survey_distribution_config?.[0]?.channel,
        tenantId: survey.tenant_id,
        createdBy: "Me",
        created_at: survey.created_at,
        updated_at: survey.updated_at,
    }));
};

export const fetchTenantsForAdmin = async () => {
    const { data, error } = await supabase
        .from("tenants")
        .select("id, name");

    if (error) throw error;
    return data;
};

export const createNewSurvey = async (surveyData) => {
    // First create the survey
    const { data, error } = await supabase
        .from("tenant_surveys")
        .insert([{
            tenant_id: surveyData.tenantId,
            trigger_events: [],
            conditions: [],
            user_attrs: [],
            delay_seconds: 0,
            can_dismiss: true,
            llm_context: "",
            status: "draft",
            widget_position: "bottom_right",
            title: surveyData.title,
            trigger_type: "page_visit",
            response_limit: null,
        }])
        .select()
        .single();

    if (error) throw error;

    // Then create the distribution config
    const { error: distributionError } = await supabase
        .from("survey_distribution_config")
        .insert([{
            survey: data.id,
            channel: surveyData.distributionChannel,
        }]);

    if (distributionError) throw distributionError;

    return data;
};

export const createBlocksInSurvey = async (blocks) => {
    const { error } = await supabase
        .from("survey_blocks")
        .insert(blocks);

    if (error) throw error;
}

export const createInitialMessageBlock = async (surveyId, tenantId, position = 1) => {
    const block = {
        type: "message",
        title: "Thank you for your response!",
        button_label: "",
        button_url: "",
        position: position,
        required: false,
        survey_id: surveyId,
        tenant_id: tenantId,
    };

    const { error } = await supabase
        .from("survey_blocks")
        .insert(block);

    if (error) throw error;
};

export const fetchPopularSurveyTemplates = async () => {
    const { data, error } = await supabase
        .from("tenant_surveys")
        .select(`
            id,
            title,
            metadata
        `)
        .eq("is_popular", true)
        .limit(4);

    if (error) throw error;

    return data.map((template) => ({
        id: template.id,
        title: template.title,
        thumbnail: template.metadata?.thumbnail || null,
        description: template.metadata?.description ||
            "No description available",
    }));
};

// Survey Response Management
export const fetchSurveyBlocks = async (surveyId) => {
    const { data, error } = await supabase
        .from("survey_blocks")
        .select("id, title, type, position, rating_min, rating_max, choices")
        .neq("type", "message")
        .eq("survey_id", surveyId);

    if (error) throw error;
    return data;
};

export const fetchFilteredResponses = async (surveyId, filterParams) => {
    const filterConditions = [];

    // Apply additional filters
    for (const filter of filterParams.appliedFilters) {
        if (
            filter.type === "language" || filter.type === "device" ||
            filter.type === "user_attribute"
        ) {
            const field = filter.type === "user_attribute"
                ? filter.option.value
                : filter.type;
            let criteria = filter.type === "language"
                ? filterParams.langLabelValMap[filter.criteria]
                : filter.criteria;

            filterConditions.push({
                field_name: field,
                comparison_operator: filter.operator,
                compare_value: criteria,
                value_type: field == "language" || field == "device"
                    ? field
                    : filter.option.type,
            });
        }
    }

    const startDate = filterParams.customStartDate
        ? new Date(
            new Date(filterParams.customStartDate).setHours(23, 59, 59, 999),
        ).toISOString()
        : null;
    const endDate = filterParams.customEndDate
        ? new Date(
            new Date(filterParams.customEndDate).setHours(23, 59, 59, 999),
        ).toISOString()
        : null;

    const { data, error } = await supabase.rpc("filter_responses", {
        filter_conditions: filterConditions,
        time_filter: {
            type: filterParams.filter,
            start: startDate,
            end: endDate,
        },
        input_survey_id: surveyId,
    });

    if (error) throw error;

    return data;
};

export const fetchResponseMessages = async (blockIds, responseIds) => {
    const { data, error } = await supabase
        .rpc("fetch_response_messages", {
            block_ids: blockIds,
            response_ids: responseIds,
        });

    if (error) throw error;
    return data;
};

export const fetchTenantUserAttributesForResponse = async (tenantId = null) => {
    let query = supabase
        .from("tenant_user_attributes")
        .select("id, attribute_name, data_type")
        .order("created_at", { ascending: false });

    if (tenantId) {
        query = query.eq("tenant_id", tenantId);
    }

    const { data, error } = await query;
    if (error) throw error;
    return data;
};

// Template Management
export const fetchTemplateDetails = async (templateId) => {
    const { data, error } = await supabase
        .from("tenant_surveys")
        .select("*, survey_blocks (*)")
        .eq("id", templateId)
        .single();

    if (error) throw error;
    return data;
};

export const incrementTemplateStat = async (templateId, statType) => {
    // First fetch existing stat
    const { data: existingStat } = await supabase
        .from("survey_stats")
        .select()
        .eq("survey_id", templateId)
        .single();

    const currentTime = new Date().toISOString();

    if (existingStat) {
        const { error } = await supabase
            .from("survey_stats")
            .update({
                [statType]: existingStat[statType] + 1,
                updated_at: currentTime,
            })
            .eq("survey_id", templateId);

        if (error) throw error;
    } else {
        const { error } = await supabase
            .from("survey_stats")
            .insert({
                survey_id: templateId,
                [statType]: 1,
                created_at: currentTime,
                updated_at: currentTime,
            });

        if (error) throw error;
    }
};

export const cloneTemplate = async (templateId, tenantId, channel) => {
    // Fetch template data
    const { data: templateData } = await supabase
        .from("tenant_surveys")
        .select("*, survey_blocks (*), survey_distribution_config (*)")
        .eq("id", templateId)
        .single();

    if (!templateData) throw new Error("Template not found");

    // Create new survey
    const { data: newSurvey, error: surveyError } = await supabase
        .from("tenant_surveys")
        .insert({
            trigger_events: templateData.trigger_events,
            conditions: templateData.conditions,
            user_attrs: templateData.user_attrs,
            delay_seconds: templateData.delay_seconds,
            can_dismiss: templateData.can_dismiss,
            llm_context: templateData.llm_context,
            trigger_type: templateData.trigger_type,
            widget_position: templateData.widget_position,
            title: `${templateData.title} (Cloned)`,
            tenant_id: tenantId || templateData.tenant_id,
            is_template: false,
            metadata: null,
            status: templateData.status,
        })
        .select()
        .single();

    if (surveyError) throw surveyError;

    // Clone the distribution config if it exists
    if (
        templateData.survey_distribution_config &&
        templateData.survey_distribution_config.length > 0
    ) {
        const { error: distributionError } = await supabase
            .from("survey_distribution_config")
            .insert({
                survey: newSurvey.id,
                channel: channel,
            });

        if (distributionError) throw distributionError;
    }

    // Create blocks for the new survey if they exist
    if (templateData.survey_blocks && templateData.survey_blocks.length > 0) {
        const blocksToInsert = templateData.survey_blocks.map((block) => ({
            button_label: block.button_label,
            button_url: block.button_url,
            description: block.description,
            followup_question_prompt: block.followup_question_prompt,
            followup_questions: block.followup_questions,
            rating_max_label: block.rating_max_label,
            rating_min_label: block.rating_min_label,
            position: block.position,
            required: block.required,
            rating_max: block.rating_max,
            rating_min: block.rating_min,
            survey_id: newSurvey.id,
            title: block.title,
            type: block.type,
            choices: block.choices,
            tenant_id: tenantId || templateData.tenant_id,
        }));

        const { error: blocksError } = await supabase
            .from("survey_blocks")
            .insert(blocksToInsert);

        if (blocksError) throw blocksError;
    }

    return newSurvey;
};

// Templates List Management
export const fetchTemplatesWithMetadata = async () => {
    const { data, error } = await supabase
        .from("tenant_surveys")
        .select("id, title, metadata")
        .not("metadata", "is", null);

    if (error) throw error;

    return data.map((template) => ({
        id: template.id,
        title: template.title,
        categories: template.metadata?.category || [],
        description: template.metadata?.description ||
            "No description available",
        thumbnail: template.metadata?.thumbnail || null,
        preview_url: template.metadata?.preview_url || null,
    }));
};

export const fetchOnboardingStatus = async (tenantId) => {
    const { data: tenant, error: tenantError } = await supabase
        .from("tenants")
        .select("sdk_connected")
        .eq("id", tenantId)
        .single();

    if (tenantError) throw tenantError;

    const { data: surveys, error: surveysError } = await supabase
        .from("tenant_surveys")
        .select("id, status")
        .eq("tenant_id", tenantId);

    if (surveysError) throw surveysError;

    return {
        accountSetup: true, // Always completed
        sdkInstalled: tenant.sdk_connected || false,
        surveyCreated: surveys.length > 0,
        surveyPublished: surveys.some((survey) =>
            survey.status === "in_progress"
        ),
    };
};

export const getNPSTrends = async (surveyId, timeFrame) => {
    const { data, error } = await supabase
        .rpc("get_nps_trends", {
            survey_id: surveyId,
            time_frame: timeFrame,
        });

    if (error) {
        console.error("Error fetching NPS trends:", error);
        throw error;
    }

    return data;
};

export const saveResponseToSupabase = async (
    blockData,
    responseId,
    value,
    tenantId,
) => {
    try {
        const initialConversation = [
            {
                author: "system",
                value: blockData.title,
                sent_at: new Date().toISOString(),
            },
            {
                author: "user",
                value: String(value),
                sent_at: new Date().toISOString(),
            },
        ];

        const { error } = await supabase
            .from("response_messages")
            .upsert({
                block_id: blockData.id,
                response_id: responseId,
                value: String(value),
                conversation: initialConversation,
                tenant_id: tenantId,
            }, { onConflict: ["block_id", "response_id"] })
            .select("id")
            .single();

        if (error) throw error;
    } catch (error) {
        console.error("Error in saveResponseToSupabase:", error);
        throw error;
    }
};

export const fetchMailerliteUserId = async (userId) => {
    const { data, error } = await supabase
        .from("users")
        .select("mailerlite_user_id")
        .eq("id", userId)
        .single();

    if (error) throw error;
    return data?.mailerlite_user_id;
};

export const fetchAnalysisReports = async (surveyId) => {
    const { data, error } = await supabase
        .from("analysis_reports")
        .select(`
            *,
            users:created_by (name)
        `)
        .eq("survey_id", surveyId)
        .order("created_at", { ascending: false });

    if (error) throw error;
    return data;
};

export const fetchNumberOfResponsesBetweenRange = async (
    surveyId,
    startDate,
    endDate,
) => {
    const { count, error } = await supabase
        .from("responses")
        .select("id, response_messages!inner(id)", {
            count: "exact",
            head: true,
        })
        .eq("survey_id", surveyId)
        .eq("is_preview", false)
        .gte("created_at", startDate)
        .lte("created_at", endDate);

    if (error) throw error;

    return count;
};

export const fetchAnalysisReport = async (reportId) => {
    const { data, error } = await supabase
        .from("analysis_reports")
        .select(`
            *,
            users:created_by (name)
        `)
        .eq("id", reportId)
        .single();

    if (error) throw error;
    return data;
};

export const updateSurveyDistributionConfig = async (surveyId, channel) => {
    const { error } = await supabase
        .from("survey_distribution_config")
        .update({
            channel: channel
        })
        .eq("survey", surveyId);

    if (error) throw error;
};

export const upsertChatSession = async (chatHistory, sessionId) => {
    const { data, error } = await supabase
        .from("chat_sessions")
        .upsert({
            id: sessionId,
            chat_history: chatHistory
        }, {
            onConflict: 'id'
        })
        .select()
        .single();

    if (error) throw error;
    return data;
};
