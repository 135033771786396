<template>
  <div
    class="relative flex items-center justify-center border border-gray-300 w-52 h-28 rounded-lg bg-gray-100"
  >
    <div
      :class="positionClass"
      class="absolute w-16 h-10 bg-gray-700 rounded-sm rounded-[7px]"
    ></div>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    position: {
      type: String,
      default: "bottom_right",
    },
  },
  setup(props) {
    const positionClass = computed(() => {
      switch (props.position) {
        case "bottom_left":
          return "bottom-2 left-2"
        case "bottom_center":
          return "bottom-2 left-1/2 transform -translate-x-1/2"
        case "bottom_right":
          return "bottom-2 right-2"
        case "top_left":
          return "top-2 left-2"
        case "top_center":
          return "top-2 left-1/2 transform -translate-x-1/2"
        case "top_right":
          return "top-2 right-2"
        case "center_left":
          return "top-1/2 left-2 transform -translate-y-1/2"
        case "center_right":
          return "top-1/2 right-2 transform -translate-y-1/2"
        case "page_center":
          return "top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        default:
          console.error("Invalid position")
          return ""
      }
    })

    return {
      positionClass
    }
  }
}
</script>
