<template>
  <div>
    <!-- Loading -->
    <div v-if="isLoading">
      <div class="animate-pulse space-y-4 p-4">
        <!-- Left Sidebar -->
        <div class="flex space-x-4">
          <div class="flex flex-col space-y-4 w-1/3">
            <div class="h-10 bg-gray-300 rounded"></div>
            <div class="h-10 bg-gray-300 rounded"></div>
            <div class="h-10 bg-gray-300 rounded"></div>
            <div class="h-10 bg-gray-300 rounded"></div>
            <div class="h-10 bg-gray-300 rounded"></div>
            <div class="h-10 bg-gray-300 rounded"></div>
            <div class="h-6 bg-gray-300 rounded w-1/3"></div>
          </div>

          <!-- Right Sidebar -->
          <div class="flex flex-col space-y-4 w-2/3">
            <div class="h-8 bg-gray-300 rounded w-1/2"></div>
            <div class="h-16 bg-gray-300 rounded"></div>
            <div class="h-8 bg-gray-300 rounded w-1/2"></div>
            <div class="h-16 bg-gray-300 rounded"></div>
            <div class="flex space-x-4">
              <div class="h-10 bg-gray-300 rounded w-1/2"></div>
              <div class="h-10 bg-gray-300 rounded w-1/2"></div>
            </div>
            <div class="flex items-center space-x-4">
              <div class="h-8 bg-gray-300 rounded w-6"></div>
              <div class="h-8 bg-gray-300 rounded w-20"></div>
              <div class="h-8 bg-gray-300 rounded w-16"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isLoading" class="flex">
      <div class="w-1/3 border-r border-gray-200" id="question-list">
        <div class="p-4">
          <draggable v-model="blocks" handle=".drag-handle" animation="200" class="space-y-2"
            :itemKey="(block) => block.id">
            <template #item="{ element, index }">
              <div v-if="index !== blocks.length - 1"
                class="group relative block cursor-pointer rounded-lg border border-gray-300 bg-white px-2 py-4 shadow-xs focus:outline-hidden data-focus:border-indigo-600 data-focus:ring-2 data-focus:ring-indigo-600 sm:flex sm:justify-between"
                :class="{
                  'border-indigo-600 border-2': selectedBlock === index,
                }" @click="selectBlock(index)">
                <div class="flex items-center overflow-hidden">
                  <span class="drag-handle cursor-move">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="currentColor"
                      viewBox="0 0 24 24">
                      <circle cx="5" cy="5" r="1.5" />
                      <circle cx="5" cy="12" r="1.5" />
                      <circle cx="5" cy="19" r="1.5" />
                      <circle cx="12" cy="5" r="1.5" />
                      <circle cx="12" cy="12" r="1.5" />
                      <circle cx="12" cy="19" r="1.5" />
                    </svg>
                  </span>
                  <span class="text-sm font-semibold text-gray-900 mr-2">{{ index + 1 }}.</span>
                  <span
                    class="truncate block whitespace-nowrap text-ellipsis overflow-hidden inline text-sm font-semibold text-gray-900">
                    {{ element.title }}</span>
                </div>
                <div :class="getLabelClass(element.type)"
                  class="px-2 py-1 text-xs rounded-md font-semibold ml-5 min-w-[5rem] max-w-[5rem] text-center">
                  {{ element.label }}
                </div>
              </div>
            </template>
          </draggable>
          <button @click="showAddBlockModal = true"
            class="text-blue-600 mb-4 mt-4 w-full text-center font-medium flex justify-center items-center space-x-1">
            <heroicon-outline-plus class="w-5 h-5" />
            <span>Add Block</span>
          </button>
          <!-- Explicitly Render Last Element Outside Draggable -->
          <div v-if="blocks.length > 0" @click="selectBlock(blocks.length - 1)" :class="{
            'border-indigo-600 border-2': selectedBlock === blocks.length - 1,
          }"
            class="group relative block cursor-pointer rounded-lg border border-gray-300 bg-white px-4 py-4 shadow-xs focus:outline-hidden data-focus:border-indigo-600 data-focus:ring-2 data-focus:ring-indigo-600 sm:flex sm:justify-between">
            <div class="flex items-center overflow-hidden">
              <span
                class="truncate block whitespace-nowrap text-ellipsis overflow-hidden text-sm font-semibold text-gray-900">Thank
                You Block</span>
            </div>
          </div>
        </div>
      </div>

      <div class="w-3/4 p-6" v-if="blocks.length > 0">
        <div class="flex items-center justify-between">
          <h2 class="text-lg font-semibold">
            {{
              selectedBlock < blocks.length - 1 ? getBlockLabel(blocks[selectedBlock].type) : "Thank You" }} </h2>
              <div class="flex items-center">
                <Switch v-if="selectedBlock < blocks.length - 1" v-model="blocks[selectedBlock].required" :class="[
                  blocks[selectedBlock].required
                    ? 'bg-indigo-600'
                    : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                ]">
                  <span class="sr-only">Required field</span>
                  <span aria-hidden="true" :class="[
                    blocks[selectedBlock].required
                      ? 'translate-x-5'
                      : 'translate-x-0',
                    'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                  ]" />
                </Switch>
                <label class="ml-2 text-sm font-medium text-gray-900" v-if="selectedBlock < blocks.length - 1">
                  Required
                </label>
                <heroicon-outline-trash @click="showDeleteBlockModal = true" v-if="selectedBlock != blocks.length - 1"
                  class="w-6 h-6 ml-6 cursor-pointer text-gray-400 hover:text-red-700" />
              </div>
        </div>
        <div class="mt-6">
          <div class="space-y-4">
            <!-- TITLE -->
            <div>
              <label
                class="block text-sm/6 font-medium text-gray-900block text-medium text-left font-semibold text-gray-700">Title</label>
              <input v-model="blocks[selectedBlock].title" type="text" @input="validateTitle"
                class="mt-2 block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                placeholder="Enter your question here" />
              <p v-if="errors.titleError" class="mt-2 text-sm text-red-600 text-left">
                Title is required.
              </p>
            </div>
            <!-- DESCRIPTION -->
            <div>
              <label
                class="block text-sm/6 font-medium text-gray-900block text-medium text-left font-semibold text-gray-700">Description - Optional</label>
              <quill-editor v-model:value="blocks[selectedBlock].description" :options="editorOptions"
                class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6">
              </quill-editor>
            </div>
            <!-- MIN-MAX VALUE -->
            <div class="grid grid-cols-4 gap-4 mt-4" v-if="getCurrentBlockType == 'rate' || getCurrentBlockType == 'nps'">
              <div>
                <label
                  class="block text-sm/6 font-medium text-gray-900block text-medium text-left font-semibold text-gray-700">Minimum
                  Value</label>
                <input type="number" v-model="blocks[selectedBlock].minValue" @input="validateValues"
                  :disabled="getCurrentBlockType == 'nps'"
                  class="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                  placeholder="0" />
                <p v-if="errors.minError" class="text-red-500 text-sm">
                  Minimum value must be at least 0.
                </p>
              </div>
              <div>
                <label
                  class="block text-sm/6 font-medium text-gray-900block text-medium text-left font-semibold text-gray-700">Label</label>
                <input type="text" v-model="blocks[selectedBlock].minLabel"
                  class="mt-2 block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  placeholder="Terrible" />
              </div>
              <div>
                <label
                  class="block text-sm/6 font-medium text-gray-900block text-medium text-left font-semibold text-gray-700">Maximum
                  Value</label>
                <input type="number" v-model="blocks[selectedBlock].maxValue" @input="validateValues"
                  :disabled="getCurrentBlockType == 'nps'"
                  class="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                  placeholder="5" />
                <p v-if="errors.maxError" class="text-red-500 text-sm">
                  Maximum value must be at most 10.
                </p>
              </div>
              <div>
                <label
                  class="block text-sm/6 font-medium text-gray-900block text-medium text-left font-semibold text-gray-700">Label</label>
                <input type="text" v-model="blocks[selectedBlock].maxLabel"
                  class="mt-2 block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  placeholder="Excellent" />
              </div>
            </div>
            <p v-if="errors.rangeError" class="text-red-500 text-sm">
              Maximum value must be greater than minimum value.
            </p>
            <!-- CHOICES -->
            <div class="mt-4" v-if="
              getCurrentBlockType == 'multi_choice' ||
              getCurrentBlockType == 'select'
            ">
              <div class="flex items-center justify-between">
                <label
                  class="block text-sm/6 font-medium text-gray-900block text-medium text-left font-semibold text-gray-700">Choices</label>
                <button @click="showPopup = true"
                  class="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Add
                </button>
              </div>
              <div v-if="blocks[selectedBlock].choices.length === 0"
                class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 py-8 mt-4 text-center cursor-pointer hover:border-gray-400 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden"
                @click="showPopup = true">
                <p class="font-medium text-gray-900">
                  No options available. Add one now!
                </p>
              </div>
              <ul class="mt-3 space-y-2" id="choices-list">
                <li v-for="(choice, index) in blocks[selectedBlock].choices" :key="index"
                  class="group relative block cursor-pointer rounded-lg border border-gray-300 bg-white px-4 py-3 shadow-xs focus:outline-hidden sm:flex sm:justify-between">
                  <span class="font-medium text-sm text-gray-900">{{
                    choice
                    }}</span>
                  <HeroiconOutlineTrash @click="removeChoice(index)"
                    class="h-5 w-5 cursor-pointer text-gray-400 hover:text-red-700" />
                </li>
              </ul>
            </div>
            <!-- SCALE -->
            <div class="grid grid-cols-4 gap-4 mt-2" v-if="getCurrentBlockType == 'csat'">
              <div v-for="scale in scales" :key="scale.value" @click="setScale(scale.value)" :class="{
                'border-indigo-500 border-2':
                  blocks[selectedBlock].maxValue === scale.value,
                'border-gray-300':
                  blocks[selectedBlock].scale !== scale.value,
              }" class="border p-3 rounded-md text-center cursor-pointer">
                <div class="flex justify-center space-x-2 mb-1">
                  <span v-for="emoji in scale.emojis" class="text-3xl" :key="emoji">{{ emoji }}</span>
                </div>
                <span class="text-sm font-medium">{{ scale.label }}</span>
              </div>
            </div>
            <!-- FOLLOW UP QUESTIONS -->
            <div v-if="getCurrentBlockType != 'message'">
              <div class="flex justify-between items-center">
                <label class="block text-medium text-left font-semibold text-gray-700">AI Generated Follow-up Questions</label>
                <a href="https://help.sondar.ai/en/articles/10472882-ai-generated-follow-up-questions" target="_blank" class="text-sm text-indigo-600 hover:text-indigo-500 flex items-center gap-1">
                  <BookOpenIcon class="w-4 h-4" />
                  Docs
                </a>
              </div>
              <div class="grid grid-cols-4 gap-4 mt-2">
                <button v-for="option in followUpOptions" :key="option.label" @click="setFollowUp(option.value)" :class="{
                  'border-indigo-500 border-2':
                    blocks[selectedBlock].followUp === option.value,
                  'border-gray-300 bg-white':
                    blocks[selectedBlock].followUp !== option.value,
                }" class="border p-3 rounded-md text-left text-medium font-medium flex flex-col">
                  {{ option.label }}
                  <span class="text-sm text-left text-gray-500 mt-1">
                    {{ option.description }}
                  </span>
                </button>
              </div>
            </div>
            <div v-if="
              blocks[selectedBlock].followUp &&
              getCurrentBlockType != 'message'
            ">
              <label
                class="block text-sm/6 font-medium text-gray-900block text-medium text-left font-semibold text-gray-700">Focus Prompts - Optional</label>
              <textarea v-model="blocks[selectedBlock].followUpPrompts" rows="3"
                placeholder="By default, the AI asks follow-up questions that are relevant to the users response. However, you can guide the AI by adding prompts to steer its focus."
                class="mt-1 block w-full border p-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
            </div>
            <!-- BUTTON LABEL & URL -->
            <div class="grid grid-cols-2 gap-4">
              <div>
                <label
                  class="block text-sm/6 font-medium text-gray-900block text-medium text-left font-semibold text-gray-700">Button
                  Label</label>
                <input type="text" v-model="blocks[selectedBlock].buttonLabel"
                  :disabled="selectedBlock === blocks.length - 1 && !blocks[selectedBlock].buttonUrl"
                  class="mt-2 block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  placeholder="Next" />
              </div>
              <div>
                <label
                  class="block text-sm/6 font-medium text-gray-900block text-medium text-left font-semibold text-gray-700">Button
                  URL</label>
                <input type="text" v-model="blocks[selectedBlock].buttonUrl"
                  class="mt-2 block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  placeholder="e.g. https://trustpilot.com" />
              </div>
            </div>
            <p v-if="selectedBlock === blocks.length - 1" class="mt-2 text-left text-sm text-gray-500">
              For the thank you block, a button label will only be enabled if a button URL is provided.
            </p>
          </div>
        </div>
      </div>

      <!-- ADD CHOICE POP-UP -->
      <div v-if="showPopup" class="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
        <div class="bg-white rounded-lg shadow-2xl w-full max-w-lg transform transition-all">
          <div class="rounded-t-lg p-6 pb-0 text-left">
            <DialogTitle as="h3" class="text-base font-semibold text-gray-900">Add a New Option</DialogTitle>
          </div>
          <div class="p-6">
            <div class="mb-12 text-left">
              <label for="choice-name" class="block text-sm font-medium text-gray-700">
                Choice Name
              </label>
              <div class="mt-2">
                <input id="choice-name" v-model="newChoice" type="text" placeholder="Enter choice name"
                  class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6" />
              </div>
              <p v-if="newChoiceError" class="mt-2 text-sm text-red-600">{{ newChoiceError }}</p>
            </div>

            <div class="mt-6 flex justify-end space-x-3">
              <button type="button" @click="cancelAddChoice"
                class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                Cancel
              </button>
              <button type="button" @click="addChoice"
                class="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- DELETE BLOCK POP-UP -->
      <div v-if="showDeleteBlockModal"
        class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div class="bg-white rounded-lg shadow-lg w-80">
          <div class="p-4">
            <p class="mt-2 text-sm text-gray-600">
              Are you sure you want to delete this block?
            </p>
          </div>
          <div class="flex justify-end gap-4 p-4">
            <button @click="showDeleteBlockModal = false"
              class="px-4 py-2 text-sm font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300">
              Cancel
            </button>
            <button @click="removeBlock"
              class="px-4 py-2 text-sm font-semibold text-white bg-indigo-600 rounded hover:bg-indigo-600">
              Delete
            </button>
          </div>
        </div>
      </div>

      <!-- NEW BLOCK MODAL -->
      <div v-if="showAddBlockModal"
        class="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
        <div class="bg-white rounded-lg shadow-2xl w-full max-w-xl transform transition-all">
          <div class="bg-indigo-600 rounded-t-lg p-4">
            <h3 class="text-lg font-semibold text-white">New Block</h3>
          </div>
          <div class="p-8">
            <div class="grid grid-cols-3 gap-6 mb-6">
              <button v-for="(option, index) in options" :key="option.label"
                class="flex flex-col items-center justify-center border rounded-lg py-6 px-4 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition"
                :class="{
                  'border-indigo-500 border-2': selectedOption === index,
                  'border-gray-300': selectedOption !== index,
                }" @click="selectOption(index)">
                <div class="flex flex-col items-center">
                  <div v-html="option.icon" class="w-10 h-10"></div>
                  <span class="text-sm font-medium mt-3 text-gray-800">
                    {{ option.label }}
                  </span>
                </div>
              </button>
            </div>

            <p class="text-sm text-gray-500">
              {{ options[selectedOption].description }}
            </p>

            <div class="mt-8 flex justify-end space-x-4">
              <button type="button" @click="showAddBlockModal = false"
                class="inline-flex items-center px-5 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Cancel
              </button>
              <button type="button" @click="addBlock"
                class="inline-flex items-center px-5 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue'
import { useRoute } from 'vue-router'
import { PlusIcon, TrashIcon, BookOpenIcon } from "@heroicons/vue/24/outline"
import draggable from "vuedraggable"
const { v4: uuidv4 } = require("uuid")
import { quillEditor } from "vue3-quill"
import { Switch } from "@headlessui/vue"
import { deleteSurveyBlock, syncSurveyBlocks, loadSurveyBlocks } from '@/services/supabaseService'
import { useAuthStore } from '@/stores/auth'

export default {
  components: {
    HeroiconOutlineTrash: TrashIcon,
    HeroiconOutlinePlus: PlusIcon,
    BookOpenIcon,
    draggable,
    quillEditor,
    Switch,
  },
  name: "CreateSurvey",
  setup() {
    const route = useRoute()

    const authStore = useAuthStore()

    // Reactive state
    const debounceTimer = ref(null)
    const editorOptions = ref({
      placeholder: "Write something...",
      modules: {
        toolbar: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link"],
        ],
      },
      theme: "snow",
    })
    const selectedBlock = ref(0)
    const showPopup = ref(false)
    const newChoice = ref("")
    const newChoiceError = ref("")
    const showAddBlockModal = ref(false)
    const showDeleteBlockModal = ref(false)
    const selectedOption = ref(0)
    const survey_id = ref(route.params.survey_id)
    const blocks = ref([])
    const isLoading = ref(true)
    const errors = ref({
      minError: false,
      maxError: false,
      rangeError: false,
      titleError: false,
    })
    const previousBlocksState = ref(null)
    const checkChangesTimer = ref(null)

    // Static data
    const scales = [
      { value: 2, label: "2 Scale", emojis: ["👎", "👍"] },
      { value: 3, label: "3 Scale", emojis: ["😠", "😐", "😍"] },
      { value: 4, label: "4 Scale", emojis: ["😠", "😟", "🙂", "😍"] },
      {
        value: 5,
        label: "5 Scale",
        emojis: ["😠", "😟", "😐", "🙂", "😍"],
      },
    ]

    const followUpOptions = [
      {
        label: "Off",
        value: 0,
        description: "No follow-up questions will be asked.",
      },
      {
        label: "Quick Follow-up",
        value: 1,
        description: "1 follow-up question will be asked.",
      },
      {
        label: "A little more",
        value: 2,
        description: "2 follow-up question will be asked.",
      },
      {
        label: "Deep Dive",
        value: 4,
        description: "4 follow-up question will be asked.",
      },
    ]

    const options = [
      {
        label: "Message",
        description:
          "Show a message to the participant. e.g. Do you have 5 mins for a user interview?",
        icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7 9H17M7 13H17M21 20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20Z" stroke="#f04337" stroke-width="1.6799999999999997" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>`,
        value: () => ({
          id: uuidv4(),
          label: "Message",
          type: "message",
          title: "Untitled",
          buttonLabel: "Next",
          buttonUrl: "",
          required: false,
        }),
      },
      {
        label: "Explore",
        description:
          "Ask the participant to response with open text. e.g. What can we improve about our product?",
        icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9 12H15M12 9V15M21.0039 12C21.0039 16.9706 16.9745 21 12.0039 21C9.9675 21 3.00463 21 3.00463 21C3.00463 21 4.56382 17.2561 3.93982 16.0008C3.34076 14.7956 3.00391 13.4372 3.00391 12C3.00391 7.02944 7.03334 3 12.0039 3C16.9745 3 21.0039 7.02944 21.0039 12Z" stroke="#009854" stroke-width="1.704" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>`,
        value: () => ({
          id: uuidv4(),
          label: "Explore",
          type: "explore",
          title: "Untitled",
          description: "",
          followUp: 0,
          followUpPrompts: "",
          buttonLabel: "Next",
          buttonUrl: "",
          required: false,
        }),
      },
      {
        label: "Rate",
        description:
          "Ask the participant to select a rating. e.g. How was your onboarding experience? (1 - 5)",
        icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7.99997 20H17.1919C17.9865 20 18.7058 19.5296 19.0243 18.8016L21.8323 12.3833C21.9429 12.1305 22 11.8576 22 11.5816V11C22 9.89543 21.1045 9 20 9H13.5L14.7066 4.5757C14.8772 3.95023 14.5826 3.2913 14.0027 3.00136V3.00136C13.4204 2.7102 12.7134 2.87256 12.3164 3.3886L8.41472 8.46082C8.14579 8.81044 7.99997 9.23915 7.99997 9.68024V20ZM7.99997 20H2V10H7.99997V20Z" stroke="#f79007" stroke-width="1.752" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>`,
        value: () => ({
          id: uuidv4(),
          type: "rate",
          label: "Rate",
          title: "Untitled",
          followUp: 0,
          description: "",
          minValue: 1,
          minLabel: "Terrible",
          maxValue: 5,
          maxLabel: "Excellent",
          followUpPrompts: "",
          buttonLabel: "Next",
          buttonUrl: "",
          required: false,
        }),
      },
      {
        label: "Select",
        description:
          "Ask the participant to select ONE option from a predefined list. e.g. What is your role?",
        icon: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" version="1.1" fill="none" stroke="#146fef" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.184"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="m14.25 8.75c-.5 2.5-2.3849 4.85363-5.03069 5.37991-2.64578.5263-5.33066-.7044-6.65903-3.0523-1.32837-2.34784-1.00043-5.28307.81336-7.27989 1.81379-1.99683 4.87636-2.54771 7.37636-1.54771"></path> <polyline points="5.75 7.75,8.25 10.25,14.25 3.75"></polyline> </g></svg>`,
        value: () => ({
          id: uuidv4(),
          label: "Select",
          type: "select",
          title: "Untitled",
          description: "",
          followUp: 0,
          followUpPrompts: "",
          buttonLabel: "Next",
          buttonUrl: "",
          choices: [],
          required: false,
        }),
      },
      {
        label: "Multi Select",
        description:
          "Ask the participant to select one or MULTIPLE options from a predefined list. e.g. Which of these features have you used?",
        icon: `<svg viewBox="0 -3.5 29 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>bullet-list</title> <desc>Created with Sketch Beta.</desc> <defs> </defs> <g id="Page-1" stroke-width="2.2039999999999997" fill="none" fill-rule="evenodd" sketch:type="MSPage"> <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-571.000000, -210.000000)" fill="#6465e9"> <path d="M598,227 L582,227 C580.896,227 580,227.896 580,229 C580,230.104 580.896,231 582,231 L598,231 C599.104,231 600,230.104 600,229 C600,227.896 599.104,227 598,227 L598,227 Z M598,219 L582,219 C580.896,219 580,219.896 580,221 C580,222.104 580.896,223 582,223 L598,223 C599.104,223 600,222.104 600,221 C600,219.896 599.104,219 598,219 L598,219 Z M582,215 L598,215 C599.104,215 600,214.104 600,213 C600,211.896 599.104,211 598,211 L582,211 C580.896,211 580,211.896 580,213 C580,214.104 580.896,215 582,215 L582,215 Z M574,226 C572.343,226 571,227.343 571,229 C571,230.657 572.343,232 574,232 C575.657,232 577,230.657 577,229 C577,227.343 575.657,226 574,226 L574,226 Z M574,218 C572.343,218 571,219.343 571,221 C571,222.657 572.343,224 574,224 C575.657,224 577,222.657 577,221 C577,219.343 575.657,218 574,218 L574,218 Z M574,210 C572.343,210 571,211.343 571,213 C571,214.657 572.343,216 574,216 C575.657,216 577,214.657 577,213 C577,211.343 575.657,210 574,210 L574,210 Z" id="bullet-list" sketch:type="MSShapeGroup"> </path> </g> </g> </g></svg>`,
        value: () => ({
          id: uuidv4(),
          label: "Multi Choice",
          type: "multi_choice",
          title: "Untitled",
          description: "",
          followUp: 0,
          followUpPrompts: "",
          buttonLabel: "Next",
          buttonUrl: "",
          choices: [],
          required: false,
        }),
      },
      {
        label: "CSAT",
        description:
          "Ask the participant to rate their satisfaction. e.g. How satisfied were you with our support?",
        icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM8.39747 15.5534C8.64413 15.2206 9.11385 15.1508 9.44661 15.3975C10.175 15.9373 11.0541 16.25 12 16.25C12.9459 16.25 13.825 15.9373 14.5534 15.3975C14.8862 15.1508 15.3559 15.2206 15.6025 15.5534C15.8492 15.8862 15.7794 16.3559 15.4466 16.6025C14.4742 17.3233 13.285 17.75 12 17.75C10.715 17.75 9.5258 17.3233 8.55339 16.6025C8.22062 16.3559 8.15082 15.8862 8.39747 15.5534Z" fill="#db6803"></path> <path d="M16 10.5C16 11.3284 15.5523 12 15 12C14.4477 12 14 11.3284 14 10.5C14 9.67157 14.4477 9 15 9C15.5523 9 16 9.67157 16 10.5Z" fill="#db6803"></path> <path d="M10 10.5C10 11.3284 9.55229 12 9 12C8.44772 12 8 11.3284 8 10.5C8 9.67157 8.44772 9 9 9C9.55229 9 10 9.67157 10 10.5Z" fill="#db6803"></path> </g></svg>`,
        value: () => ({
          id: uuidv4(),
          label: "CSAT",
          type: "csat",
          title: "Untitled",
          followUp: 0,
          description: "",
          minValue: 1,
          minLabel: "Terrible",
          maxValue: 5,
          maxLabel: "Excellent",
          followUpPrompts: "",
          buttonLabel: "Next",
          buttonUrl: "",
          required: false,
        }),
      },
      {
        label: "NPS",
        description:
          "Ask the participant to rate their likelihood to recommend. e.g. How likely are you to recommend us to a friend?",
        icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#4f46e5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M8 14C8 14 9.5 16 12 16C14.5 16 16 14 16 14" stroke="#4f46e5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 9H9.01" stroke="#4f46e5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M15 9H15.01" stroke="#4f46e5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>`,
        value: () => ({
          id: uuidv4(),
          label: "NPS",
          type: "nps",
          title: "How likely are you to recommend [name or product] to your [friend or colleague]?",
          description: "Your feedback will be used to improve our [product or service]",
          followUp: 1,
          followUpPrompts: "Find out why the user gave this rating.",
          minValue: 0,
          minLabel: "Very Unlikely",
          maxValue: 10,
          maxLabel: "Very Likely",
          buttonLabel: "Next",
          buttonUrl: "",
          required: false,
        }),
      },
    ]

    // Computed properties
    const getCurrentBlockType = computed(() => {
      return blocks.value[selectedBlock.value].type
    })

    // Methods
    const startChangeChecker = () => {
      previousBlocksState.value = JSON.stringify(blocks.value)
      checkChangesTimer.value = setInterval(() => {
        const currentBlocksState = JSON.stringify(blocks.value)
        if (
          previousBlocksState.value !== currentBlocksState &&
          !Object.values(errors.value).some((value) => value === true)
        ) {
          previousBlocksState.value = currentBlocksState
          syncBlocks()
        }
      }, 500)
    }

    const updateTitleRichText = (content) => {
      blocks.value[selectedBlock.value].title = content
    }

    const validateTitle = () => {
      const title = blocks.value[selectedBlock.value]?.title
      errors.value.titleError = !title || title.trim() === ""
    }

    const validateValues = () => {
      const min = blocks.value[selectedBlock.value]?.minValue
      const max = blocks.value[selectedBlock.value]?.maxValue
      const type = blocks.value[selectedBlock.value]?.type

      // Skip validation for NPS type since values are fixed
      if (type === 'nps') {
        errors.value.minError = false
        errors.value.maxError = false
        errors.value.rangeError = false
        return
      }

      errors.value.minError = min === undefined || min < 0 || min > 10
      errors.value.maxError = max === undefined || max < 0 || max > 10
      errors.value.rangeError = min !== undefined && max !== undefined && min > max
    }

    const selectOption = (index) => {
      selectedOption.value = index
    }

    const addBlock = () => {
      const blockData = options[selectedOption.value].value()
      const secondLastIndex = Math.max(blocks.value.length - 1, 0)
      blocks.value.splice(secondLastIndex, 0, blockData)
      showAddBlockModal.value = false
      selectedBlock.value = secondLastIndex
    }

    const removeBlock = async () => {
      const blockId = blocks.value[selectedBlock.value].id
      blocks.value = blocks.value.filter((block) => block.id !== blockId)
      selectedBlock.value =
        selectedBlock.value - 1 >= 0 ? selectedBlock.value - 1 : 0
      showDeleteBlockModal.value = false
      await deleteBlockFromDB(blockId)
    }

    const setScale = (value) => {
      blocks.value[selectedBlock.value].maxValue = value
    }

    const addChoice = () => {
      if (newChoice.value.trim() !== "") {
        blocks.value[selectedBlock.value].choices.push(newChoice.value)
        newChoice.value = ""
        newChoiceError.value = ""
        showPopup.value = false
      } else {
        newChoiceError.value = "This is required"
      }
    }

    const cancelAddChoice = () => {
      newChoice.value = ""
      newChoiceError.value = ""
      showPopup.value = false
    }

    const removeChoice = (index) => {
      blocks.value[selectedBlock.value].choices.splice(index, 1)
    }

    const selectBlock = (index) => {
      selectedBlock.value = index
    }

    const setFollowUp = (value) => {
      if (blocks.value[selectedBlock.value]) {
        blocks.value[selectedBlock.value].followUp = value
      }
    }

    const getLabelClass = (type) => {
      switch (type) {
        case "message":
          return "bg-red-100 text-red-700"
        case "explore":
          return "bg-green-100 text-green-700"
        case "rate":
          return "bg-yellow-100 text-yellow-700"
        case "select":
          return "bg-blue-100 text-blue-700"
        case "csat":
          return "bg-orange-100 text-orange-700"
        case "multi_choice":
          return "bg-purple-100 text-purple-700"
        case "nps":
          return "bg-indigo-100 text-indigo-700"
        default:
          return "bg-gray-100 text-gray-700"
      }
    }

    const deleteBlockFromDB = async (blockId) => {
      try {
        await deleteSurveyBlock(blockId)
      } catch (err) {
        console.error("Unexpected error while deleting block:", err)
      }
    }

    const syncBlocks = async () => {
      clearTimeout(debounceTimer.value)
      debounceTimer.value = setTimeout(async () => {
        const transformedData = blocks.value.map((block, index) => ({
          id: block.id,
          button_label: block.buttonLabel,
          button_url: block.buttonUrl,
          description: block.description,
          followup_question_prompt: block.followUpPrompts,
          followup_questions: block.followUp,
          rating_max_label: block.maxLabel,
          rating_min_label: block.minLabel,
          position: index + 1,
          required: block.required,
          rating_max: block.maxValue,
          rating_min: block.minValue,
          survey_id: survey_id.value,
          title: block.title,
          type: block.type,
          choices: block?.choices,
          tenant_id: authStore.tenantId
        }))

        try {
          await syncSurveyBlocks(transformedData)
        } catch (err) {
          console.error("Unexpected error:", err)
        }
      }, 2000)
    }

    const loadBlocks = async () => {
      try {
        const data = await loadSurveyBlocks(survey_id.value)
        blocks.value = data.map((block) => ({
          id: block.id,
          label: block.label || getBlockLabel(block.type),
          type: block.type,
          title: block.title,
          description: block.description || null,
          followUp: block.followup_questions || 0,
          followUpPrompts: block.followup_question_prompt || null,
          buttonLabel: block.button_label,
          buttonUrl: block.button_url || null,
          minValue: block.rating_min,
          minLabel: block.rating_min_label || null,
          maxValue: block.rating_max,
          maxLabel: block.rating_max_label || null,
          choices: block.choices ? block.choices : [],
          required: block.required || false,
        }))
      } catch (err) {
        console.error("Unexpected error while loading blocks:", err)
      } finally {
        isLoading.value = false
      }
    }

    const getBlockLabel = (type) => {
      switch (type) {
        case "message":
          return "Message"
        case "explore":
          return "Explore"
        case "rate":
          return "Rate"
        case "select":
          return "Select"
        case "csat":
          return "CSAT"
        case "multi_choice":
          return "Multi Choice"
        case "nps":
          return "NPS"
        default:
          return "Unknown"
      }
    }

    // Lifecycle hooks
    onMounted(async () => {
      await loadBlocks()
      startChangeChecker()
    })

    onBeforeUnmount(() => {
      clearInterval(checkChangesTimer.value)
    })

    // Watch
    watch(blocks, {
      deep: true,
      handler(newBlocks) {
        // Check if we're on the last block and clear button label if URL is empty
        if (selectedBlock.value === newBlocks.length - 1) {
          const lastBlock = newBlocks[newBlocks.length - 1]
          if (!lastBlock.buttonUrl) {
            lastBlock.buttonLabel = ''
          }
        }
      }
    })

    // Return all variables and functions needed in the template
    return {
      // State
      debounceTimer,
      editorOptions,
      selectedBlock,
      showPopup,
      newChoice,
      newChoiceError,
      showAddBlockModal,
      showDeleteBlockModal,
      selectedOption,
      survey_id,
      blocks,
      isLoading,
      errors,
      scales,
      followUpOptions,
      options,

      // Computed
      getCurrentBlockType,

      // Methods
      updateTitleRichText,
      validateTitle,
      validateValues,
      selectOption,
      addBlock,
      removeBlock,
      setScale,
      addChoice,
      cancelAddChoice,
      removeChoice,
      selectBlock,
      setFollowUp,
      getLabelClass,
      syncBlocks,
      loadBlocks,
      getBlockLabel,
    }
  }
}
</script>

<style scoped>
select {
  outline: none;
  cursor: pointer;
}

button {
  outline: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}
</style>