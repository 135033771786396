<template>
  <div class="outer-admin">
    <div class="flex h-screen">
      <div class="flex w-60 flex-col border-r border-gray-200">
        <NavBar :active-id="activeId" @active-id="updateActiveId" />
      </div>
      <div class="flex justify-center items-start w-full overflow-y-auto">
        <router-view v-if="activeId === 'surveys'" v-slot="{ route }">
          <SurveyBuilder v-if="route.name === 'SurveyBuilder'" />
          <SurveyList v-else />
        </router-view>
        <EventList v-if="activeId === 'events'" />
        <AttributeList v-if="activeId === 'attributes'" />
        <AdminSettings v-if="activeId === 'admin-settings'" />
        <TenantSettings v-if="activeId === 'tenant-settings'" />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import NavBar from "./NavBar.vue"
import SurveyList from "../surveyBuilder/SurveyList.vue"
import SurveyBuilder from "../surveyBuilder/EditSurvey.vue"
import EventList from "../surveyBuilder/EventList.vue"
import AttributeList from "../surveyBuilder/AttributeList.vue"
import AdminSettings from "./AdminSettings.vue"
import TenantSettings from "./TenantSettings.vue"
import { checkTenantOnboardingStatus, updateTenantOnboardingStatus } from '@/services/supabaseService'

export default {
  name: 'AdminDashboard',
  components: {
    NavBar,
    SurveyList,
    SurveyBuilder,
    EventList,
    AttributeList,
    AdminSettings,
    TenantSettings
  },
  props: {
    defaultView: {
      type: String,
      default: 'surveys',
      validator: (value) => ['surveys', 'events', 'attributes', 'admin-settings', 'tenant-settings'].includes(value)
    }
  },
  setup(props) {
    const route = useRoute()
    const activeId = ref(determineActiveId())
    const auth = useAuthStore()

    function determineActiveId() {
      const path = route.path
      if (path === '/') return props.defaultView
      if (path.startsWith("/events")) return "events"
      if (path.startsWith("/attributes")) return "attributes"
      if (path.startsWith("/survey")) return "surveys"
      if (path.startsWith("/admin-settings")) return "admin-settings"
      if (path.startsWith("/tenant-settings")) return "tenant-settings"
      return props.defaultView // Default case
    }

    function updateActiveId(id) {
      activeId.value = id
    }

    onMounted(async () => {
      const seenOnboardingSurvey = await checkTenantOnboardingStatus(auth.tenantId);
      if (!seenOnboardingSurvey) {
        // eslint-disable-next-line no-undef
        SondarSDK.showSurvey(process.env.VUE_APP_ONBOARDING_SURVEY_ID);
        await updateTenantOnboardingStatus(auth.tenantId);
      }
    })

    watch(
      () => route.path,
      () => {
        activeId.value = determineActiveId()
      },
      { immediate: true }
    )

    return {
      activeId,
      updateActiveId
    }
  }
}
</script>
