<template>
    <div class="flex h-screen">
        <!-- Main Content -->
        <div class="flex-1 p-6 overflow-y-auto chart-container-parent" :class="{ 'w-2/3': isChatOpen }">
            <div class="flex items-center justify-between mb-5">
                <div class="flex items-center gap-5">
                    <div class="relative flex items-center space-x-4">
                        <select
                            class="text-gray-700 focus:outline-none border border-gray-400 rounded-md px-4 py-2 text-sm font-medium"
                            v-model="filter" @change="setFilter">
                            <option v-for="option in filterOptions" :key="option.value" :value="option.value">
                                {{ option.label }}
                            </option>
                        </select>

                        <div v-if="filter === 'custom'" class="flex items-center space-x-4">
                            <div class="flex items-center space-x-2">
                                <label class="block text-sm font-medium text-gray-700 whitespace-nowrap">Start Date:
                                </label>
                                <input type="date" v-model="customStartDate"
                                    class="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 px-4 py-1 focus:ring-indigo-500 sm:text-sm"
                                    @change="setFilter" />
                            </div>

                            <div class="flex items-center space-x-2">
                                <label class="block text-sm font-medium text-gray-700 whitespace-nowrap">End Date: </label>
                                <input type="date" v-model="customEndDate"
                                    class="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 px-4 py-1 focus:ring-indigo-500 sm:text-sm"
                                    @change="setFilter" />
                            </div>
                        </div>
                    </div>

                    <div class="flex items-center gap-3">
                        <button @click="showAppliedFilters = !showAppliedFilters"
                            class="px-6 pr-3 py-2 bg-indigo-500 hover:bg-indigo-600 text-white font-medium text-sm rounded-md shadow focus:outline-none transition duration-300 ease-in-out">
                            Filters
                            <svg v-if="!showAppliedFilters" class="w-4 h-4 inline-block ml-2"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                            <svg v-else class="w-4 h-4 inline-block ml-2" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                            </svg>
                        </button>

                        <div v-if="filters.applied.length > 0" class="relative flex items-center space-x-4">
                            <span
                                class="flex items-center space-x-2 text-sm cursor-pointer px-4 py-2 rounded-md transition duration-300 ease-in-out"
                                @click="clearAllFilters">
                                <span>Clear Filters</span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="flex items-center gap-3">
                    <button @click="analyzeWithAI"
                        :class="[
                            'px-6 py-2 text-white font-medium text-sm rounded-md shadow focus:outline-none transition duration-300 ease-in-out flex items-center',
                            hasAnyResponses ? 'bg-indigo-500 hover:bg-indigo-600 cursor-pointer' : 'bg-gray-400 cursor-not-allowed'
                        ]"
                        :disabled="!hasAnyResponses">
                        <SparklesIcon class="w-4 h-4 mr-2" />
                        Summary Report (BETA)
                    </button>
                    <button @click="toggleChat" v-if="!isChatOpen"
                        :class="[
                            'px-6 py-2 text-white font-medium text-sm rounded-md shadow focus:outline-none transition duration-300 ease-in-out flex items-center',
                            hasAnyResponses ? 'bg-indigo-500 hover:bg-indigo-600 cursor-pointer' : 'bg-gray-400 cursor-not-allowed'
                        ]"
                        :disabled="!hasAnyResponses">
                        <HeroIconChatBubbleLeftEllipsisOutline class="w-4 h-4 mr-2" />
                        AI Analyst (BETA)
                    </button>
                </div>
            </div>

            <!-- Filters -->
            <div class="p-6 bg-white rounded-lg shadow-md w-full border border-gray-200 mb-5" v-if="showAppliedFilters">
                <div class="flex justify-between items-center space-x-2 mb-4">
                    <h2 class="text-lg font-semibold text-gray-800">Filters</h2>
                    <div class="flex items-center space-x-2">
                        <!-- <label class="text-sm font-medium text-gray-700">Match with:</label>
                        <select v-model="filters.matchOption" class="rounded-lg border border-gray-300 p-2 text-sm">
                            <option value="all">All Attributes</option>
                            <option value="any">Any Attribute</option>
                        </select> -->
                        <svg xmlns="http://www.w3.org/2000/svg" @click="showAppliedFilters = false"
                            class="h-4 w-4 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <div v-if="filters.applied.length === 0"
                    class="text-gray-500 bg-gray-100 rounded-lg p-3 text-sm italic mb-4">
                    No filters applied.
                </div>
                <div v-for="(filter, index) in filters.applied" :key="index"
                    class="mb-4 flex items-center w-full space-x-2">
                    <select v-model="filter.type"
                        class="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-800 focus:outline-none w-1/4">
                        <option v-for="option in filters.options" :key="option.value" :value="option.value">
                            {{ option.label }}
                        </option>
                    </select>

                    <template v-if="filter.type === 'device'">
                        <select v-model="filter.criteria"
                            class="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-800 focus:outline-none w-full">
                            <option
                                v-for="criteria in filters.options.find(opt => opt.value === 'device')?.options[0].criteria"
                                :key="criteria" :value="criteria">
                                {{ criteria }}
                            </option>
                        </select>
                    </template>

                    <template v-if="filter.type === 'language'">
                        <select v-model="filter.criteria"
                            class="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-800 focus:outline-none w-full">
                            <option v-for="lang in filters.options.find(opt => opt.value === 'language')?.options"
                                :key="lang.value" :value="lang.label">
                                {{ lang.label }}
                            </option>
                        </select>
                    </template>

                    <template v-if="filter.type !== 'device' && filter.type !== 'language'">
                        <template v-if="filters.options.find(opt => opt.value === filter.type)?.options.length">
                            <select v-model="filter.option"
                                class="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-800 focus:outline-none w-full">
                                <option v-for="option in filters.options.find(opt => opt.value === filter.type)?.options"
                                    :key="option.value" :value="option">
                                    {{ option.label }}
                                </option>
                            </select>
                        </template>

                        <template
                            v-if="(filters.options.find(opt => opt.value === filter.type)?.options.find(opt => opt.value === filter.option?.value)?.operators || []).length">
                            <select v-model="filter.operator"
                                class="border border-gray-300 rounded-md text-center px-3 py-2 text-sm text-gray-800 focus:outline-none w-1/3">
                                <option
                                    v-for="option in (filters.options.find(opt => opt.value === filter.type)?.options.find(opt => opt.value === filter.option?.value)?.operators || [])"
                                    :key="option" :value="option">
                                    {{ option }}
                                </option>
                            </select>
                        </template>

                        <template
                            v-if="filters.options.find(opt => opt.value === filter.type)?.options.find(opt => opt.value === filter.option?.value)?.criteria">
                            <template
                                v-if="Array.isArray(filters.options.find(opt => opt.value === filter.type)?.options.find(opt => opt.value === filter.option?.value)?.criteria)">
                                <select v-model="filter.criteria"
                                    class="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-800 focus:outline-none w-full">
                                    <option
                                        v-for="criteria in filters.options.find(opt => opt.value === filter.type)?.options.find(opt => opt.value === filter.option?.value)?.criteria"
                                        :key="criteria" :value="criteria">
                                        {{ criteria }}
                                    </option>
                                </select>
                            </template>
                            <template v-else>
                                <input v-model="filter.criteria"
                                    :type="filters.options.find(opt => opt.value === filter.type)?.options.find(opt => opt.value === filter.option?.value)?.criteria"
                                    class="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-800 focus:outline-none w-full"
                                    placeholder="Enter criteria" />
                            </template>
                        </template>
                    </template>

                    <button @click="removeFilter(index)" class="text-gray-500 hover:text-gray-700">
                        <TrashIcon class="w-5 h-5 text-indigo-500" />
                    </button>
                </div>

                <div class="flex items-center space-x-4 mt-3">
                    <button @click="addFilter"
                        class="border border-gray-300 bg-white text-gray-700 text-sm px-6 py-2 rounded-md hover:bg-gray-100 focus:outline-none">
                        Add Filter
                    </button>
                    <button @click="applyFilters" v-if="filters.applied.length > 0"
                        class="bg-indigo-500 text-white text-sm px-6 py-2 rounded-md hover:bg-indigo-600 focus:outline-none">
                        Apply
                    </button>
                </div>
            </div>

            <!-- Loading -->
            <div v-if="isLoading">
                <div class="min-h-screen animate-pulse space-y-6 bg-gray-50 p-6">
                    <div class="rounded-lg bg-white p-4 shadow">
                        <div class="flex items-center justify-between">
                            <div class="h-6 w-16 rounded bg-gray-200"></div>
                            <div class="h-4 w-20 rounded bg-gray-200"></div>
                        </div>
                        <div class="mt-4 h-6 w-3/4 rounded bg-gray-200"></div>
                        <div class="mt-6 space-y-2">
                            <div class="flex items-end space-x-4">
                                <div class="h-16 w-1/4 rounded bg-gray-300"></div>
                                <div class="h-16 w-1/4 rounded bg-gray-300"></div>
                                <div class="h-12 w-1/4 rounded bg-gray-300"></div>
                            </div>
                            <div class="mt-2 flex justify-between space-x-4 text-sm text-gray-300">
                                <div class="h-4 w-20 rounded bg-gray-200"></div>
                                <div class="h-4 w-20 rounded bg-gray-200"></div>
                                <div class="h-4 w-20 rounded bg-gray-200"></div>
                            </div>
                        </div>
                    </div>

                    <div class="rounded-lg bg-white p-4 shadow">
                        <div class="flex items-center justify-between">
                            <div class="h-6 w-24 rounded bg-gray-200"></div>
                            <div class="h-4 w-20 rounded bg-gray-200"></div>
                        </div>
                        <div class="mt-4 h-6 w-3/4 rounded bg-gray-200"></div>
                        <div class="mt-6 space-y-2">
                            <div class="h-6 rounded bg-gray-300"></div>
                            <div class="h-6 rounded bg-gray-300"></div>
                            <div class="h-6 rounded bg-gray-300"></div>
                            <div class="h-6 rounded bg-gray-300"></div>
                        </div>
                        <div class="mt-2 flex justify-between space-x-4 text-sm text-gray-300">
                            <div class="h-4 w-24 rounded bg-gray-200"></div>
                            <div class="h-4 w-24 rounded bg-gray-200"></div>
                            <div class="h-4 w-24 rounded bg-gray-200"></div>
                            <div class="h-4 w-24 rounded bg-gray-200"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!isLoading">
                <!-- Section Questions -->
                <div v-if="section === 'questions'">
                    <div v-for="(block, index) in responseMapping" :key="block.title" class="mb-6">
                        <div class="border border-gray-200 rounded-lg shadow-md">
                            <div class="flex items-center justify-between bg-gray-100 p-4 rounded-t-lg">
                                <div class="flex items-center gap-2">
                                    <span
                                        class="px-4 py-2 border border-gray-300 min-w-[100px] text-xs font-semibold rounded-lg">
                                        {{ typeLabel[block.type] }}
                                    </span>
                                    <h2 class="text-sm ml-3 font-semibold">{{ index + 1 }}. {{ block.title }}</h2>
                                </div>
                                <div class="flex items-center gap-1 text-blue-600 font-medium"
                                    :class="{ 'hover:underline cursor-pointer': block.numberOfConversations > 0 }"
                                    @click="block.numberOfConversations > 0 && (block.isConversationModalOpen = true)">
                                    <HeroIconChatBubbleLeftEllipsisOutline class="w-5 h-5" />
                                    <span>{{ block.numberOfConversations }} Conversation{{ block.numberOfConversations == 1
                                        ? '' :
                                        's'
                                    }}</span>
                                </div>
                            </div>
                            <div class="flex">
                                <!-- CSAT Score Section -->
                                <div v-if="block.csatScore !== null"
                                    class="flex flex-col items-center justify-center px-10 ml-10">
                                    <span class="text-lg font-medium text-gray-500">
                                        CSAT Score
                                    </span>
                                    <span class="text-6xl font-extrabold text-gray-900">
                                        {{ block.csatScore.toFixed(0) }}%
                                    </span>
                                </div>

                                <!-- NPS Score Section -->
                                <div v-if="block.type === 'nps'" class="w-full">
                                    <div v-if="block.hasResponses"
                                        class="flex md:flex-row bg-gray-100 rounded-lg p-4 w-full justify-around gap-4">
                                        <!-- Score Section -->
                                        <div class="flex flex-col items-center bg-white rounded-lg p-4 w-full md:w-1/2 gap-2">
                                            <span class="text-md font-medium text-gray-700 text-left w-full">Score</span>
                                            <div class="relative flex flex-col items-center justify-center mb-3">
                                                <apexchart type="radialBar" :options="getRadialBarOptions"
                                                    :series="[((block.npsData.score + 100) / 2)]" height="280" />
                                                <div
                                                    class="absolute top-[62%] left-1/2 transform -translate-x-1/2 translate-y-[-15%] text-center">
                                                    <div class="text-4xl font-semibold text-gray-700">{{ block.npsData.score
                                                        }}
                                                    </div>
                                                </div>
                                                <div class="absolute flex justify-between w-[72%] top-[90%]">
                                                    <span class="text-sm text-gray-500">-100</span>
                                                    <span class="text-sm text-gray-500">100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Responses Section -->
                                        <div class="flex flex-col items-center bg-white rounded-lg p-4 w-full md:w-1/2 gap-2">
                                            <span
                                                class="text-md font-medium text-gray-700 text-left w-full">Responses</span>
                                            <div class="flex items-center gap-4">
                                                <apexchart type="donut" :options="getDonutOptions" style="width: 100%;"
                                                    :series="[block.npsData.promoters, block.npsData.passives, block.npsData.detractors]"
                                                    height="150" />
                                                <div class="grid grid-rows-3 gap-4 mb-2 w-full">
                                                    <div class="flex gap-3 items-center">
                                                        <div class="flex items-center gap-1">
                                                            <span class="text-base"><svg width="20" height="20"
                                                                    viewBox="0 0 100 100"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="50" cy="50" r="45" stroke="#4CAF50"
                                                                        stroke-width="7" fill="white" />
                                                                    <circle cx="35" cy="35" r="7" fill="#4CAF50" />
                                                                    <circle cx="65" cy="35" r="7" fill="#4CAF50" />
                                                                    <path d="M 30 60 Q 50 80, 70 60" stroke="#4CAF50"
                                                                        stroke-width="7" fill="transparent"
                                                                        stroke-linecap="round" />
                                                                </svg></span>
                                                            <span class="text-sm text-gray-600">Promoters</span>
                                                        </div>
                                                        <span class="text-sm font-medium text-gray-800">{{
                                                            block.npsData.promotersCount }}
                                                            ({{
                                                                block.npsData.promoters }}%)</span>
                                                    </div>
                                                    <div class="flex gap-5 items-center">
                                                        <div class="flex items-center gap-1">
                                                            <span class="text-base"><svg width="20" height="20"
                                                                    viewBox="0 0 100 100"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="50" cy="50" r="45" stroke="#c1c6ce"
                                                                        stroke-width="7" fill="white" />
                                                                    <circle cx="35" cy="35" r="7" fill="#c1c6ce" />
                                                                    <circle cx="65" cy="35" r="7" fill="#c1c6ce" />
                                                                    <line x1="35" y1="65" x2="65" y2="65" stroke="#c1c6ce"
                                                                        stroke-width="7" stroke-linecap="round" />
                                                                </svg></span>
                                                            <span class="text-sm text-gray-600">Passives</span>
                                                        </div>
                                                        <span class="text-sm font-medium text-gray-800">{{
                                                            block.npsData.passivesCount }}
                                                            ({{
                                                                block.npsData.passives }}%)</span>
                                                    </div>
                                                    <div class="flex gap-3 items-center">
                                                        <div class="flex items-center gap-1">
                                                            <span class="text-base"><svg width="20" height="20"
                                                                    viewBox="0 0 100 100"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="50" cy="50" r="45" stroke="#F44336"
                                                                        stroke-width="7" fill="white" />
                                                                    <circle cx="35" cy="35" r="7" fill="#F44336" />
                                                                    <circle cx="65" cy="35" r="7" fill="#F44336" />
                                                                    <path d="M 30 70 Q 50 50, 70 70" stroke="#F44336"
                                                                        stroke-width="7" fill="transparent"
                                                                        stroke-linecap="round" />
                                                                </svg></span>
                                                            <span class="text-sm text-gray-600">Detractors</span>
                                                        </div>
                                                        <span class="text-sm font-medium text-gray-800">{{
                                                            block.npsData.detractorsCount }}
                                                            ({{
                                                                block.npsData.detractors }}%)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="px-10 py-8 w-full text-center text-gray-500">
                                        No responses for this block
                                    </div>

                                    <!-- Monthly NPS Trends -->
                                    <div v-if="block.hasResponses"
                                        class="flex flex-col bg-white rounded-lg p-4 w-full mt-4">
                                        <div class="relative mb-4">
                                            <div class="absolute right-0">
                                                <select v-model="timePeriod"
                                                    class="text-gray-700 focus:outline-none border border-gray-400 rounded-md px-4 py-2 text-sm font-medium"
                                                    @change="refreshNPSTrends">
                                                    <option value="monthly">Monthly</option>
                                                    <option value="quarterly">Quarterly</option>
                                                </select>
                                            </div>
                                            <div class="text-center">
                                                <span class="text-lg font-medium text-gray-700">
                                                    {{ timePeriod === 'monthly' ? 'Monthly Trends' : 'Quarterly Trends' }}
                                                </span>
                                            </div>
                                        </div>
                                        <apexchart type="bar" height="300" :options="getMonthlyNPSOptions"
                                            :series="getMonthlyNPSSeries" />
                                    </div>
                                </div>

                                <!-- Chart Section -->
                                <div v-if="block.chartOptions && block.type != 'explore' && block.type != 'nps'"
                                    class="px-10 py-4 w-full">
                                    <apexchart v-if="block.hasResponses" :options="block.chartOptions"
                                        :series="block.series" type="bar" :height="block.chartHeight" />
                                    <div v-else class="px-10 py-4 w-full text-center text-gray-500">
                                        No responses for this block
                                    </div>
                                </div>
                                <div v-if="block.type == 'explore' && !block.hasResponses"
                                    class="px-10 py-8 w-full text-center text-gray-500">
                                    No responses for this block
                                </div>

                            </div>
                        </div>

                        <!-- Modal -->
                        <div v-if="block.isConversationModalOpen"
                            class="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-30 backdrop-blur-sm transition-opacity duration-300">
                            <div
                                class="bg-white rounded-2xl shadow-2xl w-11/12 md:w-3/4 lg:w-1/2 max-h-[90vh] overflow-y-auto p-6 relative">
                                <!-- Close Icon -->
                                <button class="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition-colors"
                                    @click="block.isConversationModalOpen = false">
                                    <HeroIconXMark class="w-6 h-6" />
                                </button>
                                <!-- Conversations -->
                                <div v-for="(conversation, conversationIndex) in block.chatbotConv"
                                    :key="conversationIndex" class="mb-6 border border-gray-200 rounded-lg mt-5">
                                    <div class="flex items-center justify-between bg-gray-100 p-4 rounded-t-lg">
                                        <div class="flex items-center">
                                            <div class="flex items-center justify-center h-6 w-6 rounded-full bg-gray-300">
                                                <UserIcon class="h-5 w-5 text-gray-600" />
                                            </div>
                                            <h2 class="text-sm ml-3">{{ conversation.user?.user?.name || conversation.user?.user?.email || conversation.user?.user?.id || 'Anonymous'}}</h2>
                                        </div>
                                        <span class="text-sm">
                                            {{ new Date(conversation.con[0].sent_at).toLocaleDateString("en-US", {
                                                month: "short", day:
                                                    "2-digit", year: "numeric"
                                            }) }}
                                        </span>
                                    </div>
                                    <div v-for="(message, messageIndex) in conversation.con" :key="messageIndex"
                                        class="px-10 py-4">
                                        <div :class="{
                                            'flex justify-start space-x-2': message.author === 'user',
                                            'flex justify-end space-x-2': message.author === 'system'
                                        }">
                                            <div v-if="message.author === 'user'"
                                                class="flex items-center justify-center h-7 w-7 rounded-full bg-gray-300">
                                                <UserIcon class="h-6 w-6" />
                                            </div>

                                            <div :class="{
                                                'bg-indigo-500 text-white text-left px-5 py-4 rounded-lg rounded-tl-none max-w-sm': message.author === 'user',
                                                'bg-gray-100 text-gray-800 text-left px-5 py-4 rounded-lg rounded-tr-none max-w-sm': message.author === 'system'
                                            }">
                                                <p class="text-sm">{{ message.value }}</p>
                                            </div>

                                            <div v-if="message.author === 'system'" class="text-gray-600 text-3xl">
                                                🤖
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4 p-3 bg-gray-50">
                                        <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
                                            <div class="bg-white rounded-lg p-3 shadow-sm border border-gray-100 flex items-center">
                                                <div class="w-8 h-8 flex items-center justify-center bg-blue-50 rounded-full mr-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129" />
                                                    </svg>
                                                </div>
                                                <div class="flex flex-col items-start">
                                                    <span class="text-xs text-left text-gray-500 block">Language</span>
                                                    <span class="text-sm text-left font-medium">{{ conversation?.user?.language || 'Unknown' }}</span>
                                                </div>
                                            </div>
                                            
                                            <div class="bg-white rounded-lg p-3 shadow-sm border border-gray-100 flex items-center">
                                                <div class="w-8 h-8 flex items-center justify-center bg-green-50 rounded-full mr-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
                                                    </svg>
                                                </div>
                                                <div class="flex flex-col items-start">
                                                    <span class="text-xs text-left text-gray-500 block">Device</span>
                                                    <span class="text-sm text-left font-medium">{{ conversation?.user?.device || 'Unknown' }}</span>
                                                </div>
                                            </div>
                                            
                                            <div class="bg-white rounded-lg p-3 shadow-sm border border-gray-100 flex items-center">
                                                <div class="w-8 h-8 flex items-center justify-center bg-purple-50 rounded-full mr-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </div>
                                                <div class="flex flex-col items-start">
                                                    <span class="text-xs text-left text-gray-500 block">Timezone</span>
                                                    <span class="text-sm text-left font-medium">{{ conversation?.user?.timezone || 'Unknown' }}</span>
                                                </div>
                                            </div>
                                            
                                            <div v-if="conversation?.user?.user?.id" class="bg-white rounded-lg p-3 shadow-sm border border-gray-100 flex items-center">
                                                <div class="w-8 h-8 flex items-center justify-center bg-yellow-50 rounded-full mr-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-yellow-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
                                                    </svg>
                                                </div>
                                                <div class="flex flex-col items-start">
                                                    <span class="text-xs text-left text-gray-500 block">User ID</span>
                                                    <span class="text-sm text-left font-medium">{{ conversation?.user?.user?.id }}</span>
                                                </div>
                                            </div>
                                            
                                            <div v-if="conversation?.user?.user?.name" class="bg-white rounded-lg p-3 shadow-sm border border-gray-100 flex items-center">
                                                <div class="w-8 h-8 flex items-center justify-center bg-red-50 rounded-full mr-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                                    </svg>
                                                </div>
                                                <div class="flex flex-col items-start">
                                                    <span class="text-xs text-left text-gray-500 block">Name</span>
                                                    <span class="text-sm text-left font-medium">{{ conversation?.user?.user?.name }}</span>
                                                </div>
                                            </div>
                                            
                                            <div v-if="conversation?.user?.user?.email" class="bg-white rounded-lg p-3 shadow-sm border border-gray-100 flex items-center">
                                                <div class="w-8 h-8 flex items-center justify-center bg-teal-50 rounded-full mr-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-teal-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                                    </svg>
                                                </div>
                                                <div class="flex flex-col items-start">
                                                    <span class="text-xs text-left text-gray-500 block">Email</span>
                                                    <span class="text-sm text-left font-medium">{{ conversation?.user?.user?.email }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ChatBot Split View -->
        <div v-show="isChatOpen" class="w-1/3 border-l border-gray-200 relative">
            <ChatBot @close="toggleChat" @send-message="handleChatMessage"
                :chat-payload="responseMapping" class="h-full absolute top-0 left-0 right-0 bottom-0" />
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import ApexCharts from "vue3-apexcharts"
import { XMarkIcon as HeroIconXMark, TrashIcon, SparklesIcon } from '@heroicons/vue/24/outline'
import { UserIcon } from '@heroicons/vue/20/solid'
import { ChatBubbleLeftEllipsisIcon as HeroIconChatBubbleLeftEllipsisOutline } from "@heroicons/vue/24/outline"
import ChatBot from '../chatbot/ChatBot.vue'
import {
    fetchSurveyBlocks,
    fetchFilteredResponses,
    fetchResponseMessages,
    fetchTenantUserAttributesForResponse,
    fetchLanguages,
    getNPSTrends,
    fetchNumberOfResponsesBetweenRange
} from '@/services/supabaseService'
import { useAuthStore } from '@/stores/auth'

export default {
    name: "ViewResponse",
    components: {
        apexchart: ApexCharts,
        HeroIconChatBubbleLeftEllipsisOutline,
        HeroIconXMark,
        UserIcon,
        TrashIcon,
        SparklesIcon,
        ChatBot
    },
    props: {
        tenantId: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        const route = useRoute()
        const authStore = useAuthStore()
        // Reactive state
        const filter = ref("alltime")
        const showAppliedFilters = ref(false)
        const customStartDate = ref('')
        const customEndDate = ref('')
        const langLabelValMap = ref({})
        const filters = ref({
            applied: [],
            matchOption: "all",
            options: [],
        })
        const filterOptions = ref([
            { label: 'Last 7 Days', value: 'last7days' },
            { label: 'Last 30 Days', value: 'last30days' },
            { label: 'This Week', value: 'thisweek' },
            { label: 'This Month', value: 'thismonth' },
            { label: 'Custom', value: 'custom' },
            { label: 'All Time', value: 'alltime' },
        ])
        const isAIModalOpen = ref(false)
        const aiDateRange = ref('latest')
        const aiCustomStartDate = ref('')
        const aiCustomEndDate = ref(new Date().toISOString().split('T')[0])
        const isAILoading = ref(false)
        const dateError = ref('')
        const isLoading = ref(true)
        const section = ref('questions')
        const survey_id = ref(route.params.survey_id)
        const responseMapping = ref([])
        const typeLabel = ref({
            message: "Message",
            rate: "Rate",
            csat: "CSAT",
            select: "Select",
            multi_choice: "Multi-Choice",
            explore: "Explore",
            nps: "NPS"
        })
        const timePeriod = ref('monthly')
        const trendsData = ref({
            categories: [],
            score: [],
            promoters: [],
            passives: [],
            detractors: []
        })
        const isSuccessModalOpen = ref(false)
        const responseError = ref('')
        const rangeResponseCount = ref(0)
        const isChatOpen = ref(false)

        // Add a watcher for isChatOpen to redraw charts when chat is opened/closed
        watch(isChatOpen, () => {
            // Small delay to allow DOM to update before redrawing charts
            setTimeout(() => {
                if (responseMapping.value) {
                    // Force chart redraw by triggering a small update to the data
                    responseMapping.value = [...responseMapping.value];
                }
            }, 100);
        });

        // Add resize observer to handle container width changes
        onMounted(() => {
            const chartContainer = document.querySelector('.chart-container-parent');
            if (chartContainer) {
                const resizeObserver = new ResizeObserver(() => {
                    // Force chart redraw when container size changes
                    if (responseMapping.value) {
                        responseMapping.value = [...responseMapping.value];
                    }
                });
                resizeObserver.observe(chartContainer);
            }
        });

        // Add the radial bar chart options as a computed property
        const getRadialBarOptions = computed(() => ({
            chart: {
                type: 'radialBar',
                offsetY: -20,
                sparkline: {
                    enabled: true
                },
                animations: {
                    enabled: true
                },
                redrawOnWindowResize: true
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: '#e7e7e7',
                        strokeWidth: '87%',
                        margin: 5,
                        dropShadow: {
                            enabled: false,
                        }
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            show: false
                        }
                    }
                }
            },
            colors: [(() => {
                const score = responseMapping.value.find(block => block.type === 'nps')?.npsData?.score || 0;
                if (score <= 0) return '#ef4444';
                if (score <= 30) return '#f9b73e';
                if (score <= 70) return '#3cc273';
                return '#22aa58';
            })()],
            fill: {
                type: 'solid'
            },
            stroke: {
                lineCap: 'round',
                width: 3
            },
            min: -100,
            max: 100
        }))

        // Add the donut chart options as a computed property
        const getDonutOptions = computed(() => ({
            chart: {
                type: 'donut',
                offsetY: 0,
                animations: {
                    enabled: true
                },
                redrawOnWindowResize: true
            },
            colors: ['#22c55e', '#d1d5db', '#ef4444'],
            labels: ['Promoters', 'Passives', 'Detractors'],
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '70%'
                    }
                }
            },
            stroke: {
                show: false
            },
            states: {
                hover: {
                    filter: {
                        type: 'none' // Disable hover effect
                    }
                }
            },
            tooltip: {
                y: {
                    formatter: function (value) {
                        return `${value}%`
                    }
                }
            }
        }))

        // Add the monthly NPS options as a computed property
        const getMonthlyNPSOptions = computed(() => ({
            chart: {
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: false
                },
                animations: {
                    enabled: true
                },
                redrawOnWindowResize: true
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    borderRadius: 0,
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: [3, 0, 0, 0],
                curve: 'smooth',
                colors: ['transparent']
            },
            markers: {
                size: [6, 0, 0, 0],
                strokeWidth: 2,
                strokeColors: '#ffffff',
                colors: ['#000000', 'transparent', 'transparent', 'transparent'],
                hover: {
                    size: 8
                }
            },
            xaxis: {
                categories: trendsData.value.categories,
                labels: {
                    style: {
                        colors: '#6b7280',
                        fontSize: '12px'
                    }
                }
            },
            yaxis: [
                {
                    min: -100,
                    max: 100,
                    labels: {
                        style: {
                            colors: '#6b7280',
                            fontSize: '12px'
                        },
                        formatter: function (value) {
                            return value
                        }
                    }
                },
                {
                    opposite: true,
                    min: 0,
                    max: 100,
                    labels: {
                        style: {
                            colors: '#6b7280',
                            fontSize: '12px'
                        },
                        formatter: function (value) {
                            return value + '%'
                        }
                    }
                }
            ],
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                markers: {
                    radius: 12,
                },
                itemMargin: {
                    horizontal: 10
                }
            },
            grid: {
                borderColor: '#f3f4f6',
                strokeDashArray: 4,
                yaxis: {
                    lines: {
                        show: true
                    }
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (value, { seriesIndex }) {
                        return seriesIndex === 0 ? value : value + '%'
                    }
                }
            }
        }))

        // Add the monthly NPS series data as a computed property
        const getMonthlyNPSSeries = computed(() => [
            {
                name: 'Score',
                type: 'line',
                data: trendsData.value.score,
                color: '#000000',
                zIndex: 3,
                markers: {
                    size: 6,
                    strokeWidth: 2,
                    strokeColors: '#ffffff',
                    fillColors: '#000000',
                    radius: 4,
                    hover: {
                        size: 8
                    }
                }
            },
            {
                name: 'Promoters',
                type: 'bar',
                data: trendsData.value.promoters,
                color: '#22c55e'
            },
            {
                name: 'Passives',
                data: trendsData.value.passives,
                color: '#d1d5db'
            },
            {
                name: 'Detractors',
                data: trendsData.value.detractors,
                color: '#ef4444'
            }
        ])

        // Methods
        const calculateNPSMetrics = (values) => {
            const total = values.length
            if (total === 0) return {
                score: 0,
                promoters: 0,
                passives: 0,
                detractors: 0,
                promotersCount: 0,
                passivesCount: 0,
                detractorsCount: 0,
                total: 0
            }

            const promoters = values.filter(v => v >= 9).length
            const passives = values.filter(v => v >= 7 && v <= 8).length
            const detractors = values.filter(v => v <= 6).length

            const promotersPercentage = (promoters / total) * 100
            const detractorsPercentage = (detractors / total) * 100

            return {
                score: Math.round(promotersPercentage - detractorsPercentage),
                promoters: Math.round(promotersPercentage),
                passives: Math.round((passives / total) * 100),
                detractors: Math.round(detractorsPercentage),
                promotersCount: promoters,
                passivesCount: passives,
                detractorsCount: detractors,
                total
            }
        }

        const processNPSTrends = (data) => {
            const categories = []
            const score = []
            const promoters = []
            const passives = []
            const detractors = []

            data.forEach(item => {
                const metrics = calculateNPSMetrics(item.value_array)

                // Format period label
                let periodLabel = item.period
                if (timePeriod.value === 'monthly') {
                    const date = new Date(item.period + '-01')
                    periodLabel = date.toLocaleString('default', { month: 'short', year: 'numeric' })
                }

                categories.push(periodLabel)
                score.push(metrics.score)
                promoters.push(metrics.promoters)
                passives.push(metrics.passives)
                detractors.push(metrics.detractors)
            })

            trendsData.value = {
                categories,
                score,
                promoters,
                passives,
                detractors
            }
        }

        const refreshNPSTrends = async () => {
            try {
                const npsTrends = await getNPSTrends(survey_id.value, timePeriod.value)
                processNPSTrends(npsTrends)
            } catch (error) {
                console.error('Error refreshing NPS trends:', error)
            }
        }

        const fetchSurveyResponses = async () => {
            try {
                isLoading.value = true

                await refreshNPSTrends()

                const blocks = await fetchSurveyBlocks(survey_id.value)
                const blockIds = blocks.map((block) => block.id)

                const responses = await fetchFilteredResponses(survey_id.value, {
                    filter: filter.value,
                    customStartDate: customStartDate.value,
                    customEndDate: customEndDate.value,
                    appliedFilters: filters.value.applied,
                    langLabelValMap: langLabelValMap.value
                })

                const responseIds = responses.map((response) => response.id)
                const responseMessages = await fetchResponseMessages(blockIds, responseIds)

                const transformedData = blocks.sort((a, b) => a.position - b.position).map((block) => {
                    const appliedFilter = filters.value.applied.find(
                        (filter) => filter.type === "response_to" && filter.option.value === block.title
                    )

                    let blockResponses = responseMessages.filter((message) => message.block_id === block.id)

                    if (appliedFilter) {
                        const operator = mapOperatorToSupabase(appliedFilter.operator)
                        let criteria = appliedFilter.criteria

                        // Adjust criteria for specific operators
                        if (appliedFilter.operator === 'endsWith') {
                            criteria = `%${criteria}`
                        } else if (appliedFilter.operator === 'startsWith') {
                            criteria = `${criteria}%`
                        }

                        blockResponses = blockResponses.filter((message) => {
                            const value = block.type === "rate" || block.type === "csat" || block.type === "nps" ? parseInt(message.value, 10) : message.value
                            if (operator === "ilike" || operator === "like") {
                                return value.toString().toLowerCase().includes(criteria.toLowerCase())
                            } else if (operator === "eq") {
                                return value === criteria
                            } else if (operator === "neq") {
                                return value !== criteria
                            } else if (operator === "gt") {
                                return parseFloat(value) > parseFloat(criteria)
                            } else if (operator === "lt") {
                                return parseFloat(value) < parseFloat(criteria)
                            } else if (operator === "gte") {
                                return parseFloat(value) >= parseFloat(criteria)
                            } else if (operator === "lte") {
                                return parseFloat(value) <= parseFloat(criteria)
                            }
                            return false
                        })
                    }

                    const flatResponses = blockResponses.flatMap((message) => {
                        const response = responses.find((resp) => resp.id === message.response_id)
                        if (block.type === "multi_choice" && message.value.includes(",")) {
                            return message.value.split(",").map((value) => ({
                                value: value.trim(),
                                date: response?.created_at,
                                conversation: message.conversation,
                            }))
                        }
                        return {
                            value: message.value,
                            date: response?.created_at,
                            conversation: message.conversation,
                        }
                    })

                    const seriesData = flatResponses.reduce((acc, response) => {
                        acc[response.value] = (acc[response.value] || 0) + 1
                        return acc
                    }, {})

                    const numberOfBars = Object.keys(seriesData).length
                    const chartHeight = numberOfBars * 50 + 50

                    const isVerticalChart = block.type === "select" || block.type === "multi_select"

                    let csatScore = null
                    let npsData = null

                    if (block.type === "csat" && block.rating_min !== undefined && block.rating_max !== undefined) {
                        const csatValues = blockResponses.map((response) => {
                            const value = parseFloat(response.value)
                            if (!isNaN(value)) {
                                return ((value - block.rating_min) / (block.rating_max - block.rating_min)) * 100
                            }
                            return null
                        }).filter(value => value !== null)

                        csatScore = csatValues.length
                            ? csatValues.reduce((sum, score) => sum + score, 0) / csatValues.length
                            : null
                    }

                    if (block.type === "nps") {
                        const npsValues = blockResponses.map(response => parseInt(response.value))
                        npsData = calculateNPSMetrics(npsValues)
                    }

                    const chatbotConv = Array.from(new Set(blockResponses.map(response => {
                        const participantInfo = responses.find(r => r.id === response.response_id);
                        const part = participantInfo?.participant || 'Anonymous';
                        return {
                            user: { ...part, r_id: participantInfo?.id } || 'Anonymous',
                            con: response.conversation,
                            response: response.value
                        };
                    })))
                        .filter(conversation => conversation.con !== null)

                    return {
                        title: block.title,
                        position: block.position,
                        type: block.type,
                        chartHeight: chartHeight,
                        min_value: block.rating_min,
                        max_value: block.rating_max,
                        choices: block.choices,
                        numberOfConversations: chatbotConv.length,
                        hasResponses: Object.keys(seriesData).length > 0,
                        csatScore: csatScore,
                        npsData: npsData,
                        chatbotConv: chatbotConv,
                        isConversationModalOpen: false,
                        series: [{
                            name: "Responses",
                            data: Object.values(seriesData)
                        }],
                        chartOptions: {
                            chart: {
                                type: "bar",
                                height: chartHeight,
                                stacked: false,
                                toolbar: {
                                    show: false
                                }
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: !isVerticalChart,
                                    barHeight: "80%",
                                    borderRadius: 5,
                                    borderRadiusApplication: "end",
                                    distributed: true
                                }
                            },
                            dataLabels: {
                                enabled: false
                            },
                            legend: {
                                show: false
                            },
                            grid: {
                                show: false
                            },
                            tooltip: {
                                enabled: true
                            },
                            xaxis: {
                                categories: Object.keys(seriesData),
                                labels: {
                                    show: isVerticalChart
                                },
                                axisBorder: {
                                    show: isVerticalChart
                                }
                            },
                            yaxis: {
                                labels: {
                                    show: !isVerticalChart
                                },
                                axisBorder: {
                                    show: !isVerticalChart
                                }
                            }
                        },
                    }
                })
                responseMapping.value = transformedData
                
            } catch (error) {
                console.error("Error fetching survey responses:", error)
                throw error
            } finally {
                isLoading.value = false
            }
        }

        const addFilter = () => {
            filters.value.applied.push({ type: "Response to", value: "", operator: "=", criteria: "" })
        }

        const clearAllFilters = async () => {
            showAppliedFilters.value = false
            filters.value.applied = []
            await fetchSurveyResponses()
        }

        const setFilter = () => {
            if (filter.value === 'custom') {
                if (!customStartDate.value || !customEndDate.value) {
                    return
                }
            }
            fetchSurveyResponses()
        }

        const setSection = (newSection) => {
            section.value = newSection
        }

        const removeFilter = (index) => {
            filters.value.applied.splice(index, 1)
        }

        const fetchTenantUserAttributes = async () => {
            try {
                return await fetchTenantUserAttributesForResponse(props.tenantId)
            } catch (err) {
                console.error("Error fetching tenant user attributes:", err)
                return []
            }
        }

        const mapOperatorToSupabase = (operator) => {
            const operatorMap = {
                '>=': 'gte',
                '<=': 'lte',
                '=': 'eq',
                '!=': 'neq',
                '>': 'gt',
                '<': 'lt',
                'contains': 'ilike',
                'not contains': 'not ilike',
                'selected': 'eq',
                'not selected': 'neq',
                'is': 'is',
                'is not': 'not',
                'is greater than': 'gt',
                'is less than': 'lt',
                'equals': 'eq',
                'not equals': 'neq',
                'includes': 'ilike',
                'not includes': 'not ilike',
                'endsWith': 'like',
                'startsWith': 'like',
            }
            return operatorMap[operator] || operator
        }

        const getFilterSelectionOptions = async () => {
            try {
                const [userAttributes, languageData] = await Promise.all([
                    fetchTenantUserAttributes(),
                    fetchLanguages()
                ])

                // Populate langLabelValMap with language data
                langLabelValMap.value = languageData.reduce((acc, lang) => {
                    acc[lang.label] = lang.value;
                    return acc;
                }, {});

                let operator_type_map = {
                    'csat': ['>=', '<=', '=', '!=', '>', '<'],
                    'rate': ['>=', '<=', '=', '!=', '>', '<'],
                    'nps': ['>=', '<=', '=', '!=', '>', '<'],
                    'select': ['selected', 'not selected'],
                    'multi_choice': ["includes", "not includes"],
                    'explore': ["equals", "not equals", "contains", "not contains"],
                    'number': ['=', '>', '<', '>=', '<=', '!='],
                    'text': ['contains', 'equals', 'endsWith', 'startsWith',],
                    'bool': ['is', 'is not'],
                    'date': ['is greater than', 'is less than', 'equals', 'not equals'],
                    'datetime-local': ['is greater than', 'is less than'],
                }

                let criteria_map = {
                    'csat': res => Array.from({ length: res.max_value - res.min_value + 1 }, (_, i) => res.min_value + i),
                    'rate': res => Array.from({ length: res.max_value - res.min_value + 1 }, (_, i) => res.min_value + i),
                    'select': res => res.choices,
                    'multi_choice': res => res.choices,
                    'explore': () => 'text',
                    'number': () => 'number',
                    'text': () => 'text',
                    'bool': () => ['true', 'false'],
                    'date': () => 'date',
                    'datetime-local': () => 'datetime-local',
                    'device': () => ['desktop', 'mobile'],
                    'language': res => [res.label],
                    'nps': res => Array.from({ length: res.max_value - res.min_value + 1 }, (_, i) => res.min_value + i),
                }

                filters.value.options = [
                    {
                        label: 'Response to',
                        value: 'response_to',
                        options: responseMapping.value.map(res => ({
                            label: res.title,
                            value: res.title,
                            type: res.type,
                            max_value: res.max_value,
                            min_value: res.min_value,
                            operators: operator_type_map[res.type],
                            criteria: criteria_map[res.type](res),
                        })),
                    },
                    {
                        label: 'User Attribute',
                        value: 'user_attribute',
                        options: userAttributes.map(attr => ({
                            label: attr.attribute_name,
                            value: attr.attribute_name,
                            type: attr.data_type,
                            operators: operator_type_map[attr.data_type],
                            criteria: criteria_map[attr.data_type]({}),
                        })),
                    },
                    {
                        label: 'Device',
                        value: 'device',
                        options: [{
                            label: 'Device',
                            value: 'device',
                            type: 'device',
                            operators: [],
                            criteria: criteria_map['device'](),
                        }],
                    },
                    {
                        label: 'Language',
                        value: 'language',
                        options: languageData.map(lang => ({
                            label: lang.label,
                            value: lang.value,
                            operators: [],
                            criteria: criteria_map['language'](lang),
                        })),
                    },
                ]

            } catch (err) {
                console.error("Error fetching filter selection options:", err)
                return {}
            }
        }

        const applyFilters = async () => {
            showAppliedFilters.value = false
            await fetchSurveyResponses()
        }

        const analyzeWithAI = () => {
            isAIModalOpen.value = true
        }

        const closeAIModal = () => {
            isAIModalOpen.value = false
            aiDateRange.value = 'latest'
            aiCustomStartDate.value = ''
            aiCustomEndDate.value = ''
            rangeResponseCount.value = 0
        }

        const validateDates = () => {
            if (aiDateRange.value !== 'custom') {
                dateError.value = ''
                return true
            }

            if (!aiCustomStartDate.value || !aiCustomEndDate.value) {
                dateError.value = 'Both dates are required'
                return false
            }

            const startDate = new Date(aiCustomStartDate.value)
            const endDate = new Date(aiCustomEndDate.value)

            if (endDate < startDate) {
                dateError.value = 'End date cannot be before start date'
                return false
            }

            dateError.value = ''
            return true
        }

        const updateStartDate = async (event) => {
            aiCustomStartDate.value = event.target.value;
            if (validateDates()) {
                await fetchResponsesCount();
            }
        }

        const updateEndDate = async (event) => {
            aiCustomEndDate.value = event.target.value;
            if (validateDates()) {
                await fetchResponsesCount();
            }
        }

        const fetchResponsesCount = async () => {
            if (aiDateRange.value !== 'custom') return;

            isAILoading.value = true;
            try {
                const startDate = new Date(aiCustomStartDate.value).toISOString();
                const endDate = new Date(aiCustomEndDate.value).toISOString();
                rangeResponseCount.value = await fetchNumberOfResponsesBetweenRange(survey_id.value, startDate, endDate);
            } catch (error) {
                console.error('Error fetching response count:', error);
            } finally {
                isAILoading.value = false;
            }
        }

        const startAIAnalysis = async () => {
            if (!validateDates()) return;

            isAILoading.value = true;
            responseError.value = '';
            try {
                let startDate, endDate;

                if (aiDateRange.value === 'latest') {
                    const today = new Date();
                    endDate = today.toISOString();
                    startDate = new Date(0).toISOString();
                } else {
                    startDate = new Date(aiCustomStartDate.value).toISOString();
                    endDate = new Date(aiCustomEndDate.value).toISOString();
                }

                // Fetch the number of responses in the selected range
                rangeResponseCount.value = await fetchNumberOfResponsesBetweenRange(survey_id.value, startDate, endDate);

                // Check if there are responses in the selected range
                if (rangeResponseCount.value === 0) {
                    responseError.value = 'Selected range contains no responses and cannot be processed';
                    return;
                }

                const response = await fetch(`${process.env.VUE_APP_SUPABASE_EF_BASE_URL}/create-ai-analysis`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        survey_id: survey_id.value,
                        start_date: startDate,
                        end_date: endDate,
                        created_by: authStore.user.id
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to start AI analysis');
                }
                // Close the AI modal and show success modal
                closeAIModal();
                isSuccessModalOpen.value = true;
            } catch (error) {
                console.error('Error starting AI analysis:', error);
                responseError.value = 'An error occurred while processing your request';
            } finally {
                isAILoading.value = false;
            }
        }

        const closeSuccessModal = () => {
            isSuccessModalOpen.value = false;
        }

        const goToAllAnalysis = () => {
            closeSuccessModal();
            window.location.href = `/survey/edit/${survey_id.value}?tab=Reports`;
        }

        const toggleChat = () => {
            isChatOpen.value = !isChatOpen.value
        }

        const handleChatMessage = async () => {
            // Handle chat messages here
            // You can implement your chat logic here
        }

        // Computed property to check if there are any responses
        const hasAnyResponses = computed(() => {
            // If responseMapping is not yet loaded or empty, return false
            if (!responseMapping.value || responseMapping.value.length === 0) {
                return false;
            }
            
            // Check if any block has responses
            return responseMapping.value.some(block => block.hasResponses === true);
        });

        // Lifecycle hooks
        onMounted(async () => {
            await fetchSurveyResponses()
            await getFilterSelectionOptions()
        })

        return {
            // State
            filter,
            showAppliedFilters,
            customStartDate,
            customEndDate,
            langLabelValMap,
            filters,
            filterOptions,
            isLoading,
            section,
            survey_id,
            responseMapping,
            typeLabel,
            timePeriod,
            isAIModalOpen,
            aiDateRange,
            aiCustomStartDate,
            aiCustomEndDate,
            isAILoading,
            dateError,
            isSuccessModalOpen,
            responseError,
            rangeResponseCount,
            isChatOpen,

            // Methods
            fetchSurveyResponses,
            addFilter,
            clearAllFilters,
            setFilter,
            setSection,
            removeFilter,
            closeAIModal,
            closeSuccessModal,
            goToAllAnalysis,
            mapOperatorToSupabase,
            applyFilters,
            refreshNPSTrends,
            analyzeWithAI,
            startAIAnalysis,
            updateStartDate,
            updateEndDate,
            validateDates,
            toggleChat,
            handleChatMessage,

            // Computed
            getRadialBarOptions,
            getDonutOptions,
            getMonthlyNPSOptions,
            getMonthlyNPSSeries,
            hasAnyResponses,
        }
    }
}
</script>

<style scoped>
button {
    outline: none;
    cursor: pointer;
}

.chart-container {
    max-width: 100%;
    margin: 0 auto;
}

/* Add responsive styles for ApexCharts */
:deep(.apexcharts-canvas) {
    width: 100% !important;
}

:deep(.apexcharts-svg) {
    width: 100% !important;
}
</style>
