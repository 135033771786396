<template>
  <div class="flex grow flex-col gap-y-5 pt-2 overflow-y-auto bg-white px-4 pb-2">
    <div class="flex h-16 shrink-0 items-center">
      <router-link to="/">
        <img class="h-8 w-auto" src="../../assets/full-logo.svg" alt="Your Company" />
      </router-link>
    </div>
    <nav class="flex flex-1 flex-col">
      <ul role="list" class="flex flex-1 flex-col gap-y-7">
        <li>
          <ul role="list" class="-mx-2 space-y-1">
            <li v-for="item in navigation" :key="item.name">
              <router-link :to="item.href" :class="[
                item.id == activeId
                  ? 'bg-gray-50 text-indigo-600'
                  : 'text-gray-700 hover:text-indigo-600',
                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
              ]" @click="handleNavClick(item.id)">
                <component :is="item.icon" :class="[
                  item.id == activeId
                    ? 'text-indigo-600'
                    : 'text-gray-400 group-hover:text-indigo-600',
                  'h-6 w-6 shrink-0',
                ]" aria-hidden="true" />
                {{ item.name }}
              </router-link>
            </li>
          </ul>
        </li>
        <li class="mt-auto">
          <div class="relative mb-4">
            <OnboardingWidget v-if="!isSystemAdmin" ref="onboardingWidgetRef" />
          </div>
          
          <router-link @click="showProfileModal = true" to="#"
            class="flex items-center gap-x-4 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
            <UserIcon class="h-5 w-5 shrink-0 text-gray-400" />
            <span class="sr-only">Your profile</span>
            <span aria-hidden="true">{{ tenantName || "System Admin" }}</span>
          </router-link>
        </li>
      </ul>
    </nav>

    <div v-if="showProfileModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Logout Confirmation</h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">Are you sure you want to log out?</p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button type="button" @click="logout" class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto">
                Logout
              </button>
              <button type="button" @click="showProfileModal = false" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  FolderIcon,
  Cog6ToothIcon,
  CursorArrowRaysIcon,
  IdentificationIcon,
  UserIcon,
  UserGroupIcon,
} from "@heroicons/vue/24/outline";
import { useAuthStore } from '@/stores/auth';
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import OnboardingWidget from '@/components/onboarding/OnboardingWidget.vue'

export default defineComponent({
  name: "NavBar",
  components: {
    UserIcon,
    OnboardingWidget,
  },
  props: {
    activeId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const showProfileModal = ref(false);
    const authStore = useAuthStore();
    const currentUserId = ref(null);
    const userRole = ref(null);
    const tenantName = ref(null);
    const tenantId = ref(null);
    const router = useRouter();
    const onboardingWidgetRef = ref(null);

    const isSystemAdmin = computed(() => userRole.value === 'system_admin')

    const navigation = computed(() => {
      let items = [
        {
          name: "Surveys",
          href: "/",
          icon: FolderIcon,
          id: "surveys",
        },
        {
          name: "Events",
          href: "/events",
          icon: CursorArrowRaysIcon,
          id: "events",
        },
        {
          name: "User Attributes",
          href: "/attributes",
          icon: IdentificationIcon,
          id: "attributes",
        },
      ];

      if (userRole.value === 'system_admin') {
        items.push({
          name: "AdminSettings",
          href: "/admin-settings",
          icon: UserGroupIcon,
          id: "admin-settings",
        });
      } else {
        items.push({
          name: "Settings",
          href: "/tenant-settings",
          icon: Cog6ToothIcon,
          id: "tenant-settings",
        });
      }
      return items;
    });

    const logout = async () => {
      try {
        await authStore.signOut()
        router.push('/login')
      } catch (error) {
        console.error("Unexpected error during logout:", error)
      } finally {
        showProfileModal.value = false
      }
    }

    const handleNavClick = (id) => {
      setActiveId(id);
      if (onboardingWidgetRef.value) {
        onboardingWidgetRef.value.collapse();
      }
    };

    const setActiveId = (id) => {
      emit('active-id', id);
    };

    onMounted(() => {
      currentUserId.value = authStore.user.id;
      userRole.value = authStore.role;
      tenantName.value = authStore.tenantName;
      tenantId.value = authStore.tenantId;
    });

    return {
      showProfileModal,
      currentUserId,
      userRole,
      tenantName,
      tenantId,
      navigation,
      logout,
      setActiveId,
      isSystemAdmin,
      onboardingWidgetRef,
      handleNavClick,
    };
  },
});
</script>

<style></style>