import { defineStore } from "pinia";
import supabase from "../supabase";

export const useAuthStore = defineStore("auth", {
    state: () => ({
        user: null,
        role: null,
        tenantId: null,
        loading: true,
        tenantName: null,
        isAuthenticated: false,
    }),

    getters: {
        userRole: (state) => state.role,
        getUserTenantId: (state) => state.tenantId,
        getUserTenantName: (state) => state.tenantName,
    },

    actions: {
        // Cookie management functions
        getSurveyIdsFromCookie() {
            const SURVEY_COOKIE_KEY = 'user_created_surveys'
            const cookieValue = document.cookie
                .split('; ')
                .find(row => row.startsWith(SURVEY_COOKIE_KEY))
                ?.split('=')[1]
            
            return cookieValue ? JSON.parse(decodeURIComponent(cookieValue)) : []
        },

        clearSurveyCookie() {
            const SURVEY_COOKIE_KEY = 'user_created_surveys'
            document.cookie = `${SURVEY_COOKIE_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
        },

        async initializeAuth() {
            try {
                this.loading = true;
                // We need to clear the auth if user has access token in url
                const url = new URL(window.location.href).href;
                if (url.includes("access_token") && url.includes("login")) {
                    await this.signOut();
                } else {
                    const { data: { user } } = await supabase.auth.getUser();

                    if (user) {
                        await this.setUser(user);
                    }
                }
            } catch (error) {
                console.error("Error initializing auth:", error);
            } finally {
                this.loading = false;
            }
        },

        async setUser(user) {
            if (!user) {
                this.user = null;
                this.role = null;
                this.tenantId = null;
                this.isAuthenticated = false;
                return;
            }

            const { data: userProfile } = await supabase
                .from("users")
                .select("role_id, roles(name), tenant_id, tenants(name), name")
                .eq("id", user.id)
                .single();

            this.user = user;
            this.role = userProfile.roles?.name;
            this.tenantName = userProfile.tenants?.name || null;
            this.tenantId = userProfile?.tenant_id || null;
            this.isAuthenticated = true;

            // eslint-disable-next-line no-undef
            SondarSDK.setUser({
                id: user.id,
                email: user.email,
                name: userProfile.name,
            });
        },

        async requestAccessLink({ email }) {
            const { data: userData, error: userError } = await supabase
                .from("users")
                .select("is_active")
                .eq("email", email)
                .single();

            if (userError) throw new Error("No user found with this email");

            if (!userData.is_active) {
                throw new Error(
                    "Your account has been deactivated. Please contact your administrator.",
                );
            }

            const { error: sendOtpError } = await supabase.auth.signInWithOtp({
                email,
                options: {
                    emailRedirectTo:
                        `${window.location.protocol}//${window.location.host}/validate-link`,
                },
            });

            if (sendOtpError) throw sendOtpError;
        },

        async validateAccessLink({ accessToken, email }) {
            const { data: { user }, error } = await supabase.auth.verifyOtp({
                token: accessToken,
                type: "email",
                email,
            });
            if (error) {
                throw new Error("Invalid access token");
            }
            await this.setUser(user);
        },

        async signOut() {
            const { error } = await supabase.auth.signOut();
            if (error) throw error;

            this.user = null;
            this.role = null;
            this.tenantId = null;
            this.isAuthenticated = false;
        },

        generateRandomKey(length = 40) {
            const characters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            const charactersLength = characters.length;
            return [...Array(length)]
                .map(() => characters[(Math.random() * charactersLength) | 0])
                .join("");
        },

        async signUp({ email, name, organizationName }) {
            const { data: signUpData, error: signUpError } = await supabase.auth
                .signUp({
                    email,
                    password: this.generateRandomKey(),
                    options: {
                        data: {
                            full_name: name,
                            email_confirmed: false,
                        },
                        emailRedirectTo:
                            `${window.location.protocol}//${window.location.host}/validate-link`,
                    },
                });

            if (signUpError) throw signUpError;
            await this.signOut();

            // Get survey IDs from cookie before making the API call
            const pendingSurveys = this.getSurveyIdsFromCookie()

            await fetch(
                `${process.env.VUE_APP_SUPABASE_EF_BASE_URL}/post-signup-process`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email: email,
                        name: name,
                        organizationName: organizationName,
                        user_id: signUpData.user.id,
                        pending_surveys: pendingSurveys,
                        redirect_url:
                            `${window.location.protocol}//${window.location.host}/validate-link`,
                    }),
                },
            );

            // Clear the cookie after successful signup and API call
            this.clearSurveyCookie();
        },

        async resendMagicLink(email) {
            const { error } = await supabase.auth.signInWithOtp({
                email,
                options: {
                    emailRedirectTo:
                        `${window.location.protocol}//${window.location.host}/validate-link`,
                },
            });
            if (error) throw error;
        },
    },
});
