<template>
  <div class="inline-flex items-center text-sm">
    <div class="items-center w-full">
      <div class="flex items-center rounded-md justify-between gap-x-3 bg-gray-50 px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
        <a 
          :href="url" 
          target="_blank"
          class="font-mono"
        >
          {{ surveyUrl }}
        </a>
        <button
          @click="copyToClipboard"
          class="text-gray-400 hover:text-gray-500"
          title="Copy to clipboard"
        >
          <DocumentDuplicateIcon class="h-4 w-4" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { DocumentDuplicateIcon } from '@heroicons/vue/24/outline';
import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({
  surveyId: {
    type: String,
    required: true
  }
});

const emit = defineEmits(['copied']);

const copyToClipboard = async () => {
  try {
    await navigator.clipboard.writeText(surveyUrl.value);
    emit('copied');
  } catch (err) {
    console.error('Failed to copy text: ', err);
  }
};

// Computed properties
const surveyUrl = computed(() => {
  return `${window.location.origin}/survey/${props.surveyId}`;
});

</script> 