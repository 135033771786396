<template>
    <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-7xl mx-auto">
        <!-- Logo -->
        <a class="flex justify-center mb-6" href="https://www.sondar.ai">
            <img src="@/assets/full-logo.svg" alt="Logo" class="h-8" />
        </a>
        <div class="h-px bg-gray-200 mb-8"></div>

        <!-- Loading State -->
        <div v-if="isLoading" class="flex justify-center items-center h-64">
            <div class="animate-spin rounded-full h-8 w-8 border-2 border-indigo-500 border-t-transparent"></div>
        </div>

        <TransitionRoot as="template" v-else-if="isGenerating" :show="isGenerating">
            <Dialog as="div" class="relative z-50" @close="() => { }">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0"
                    enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div class="fixed inset-0 z-10 overflow-y-auto">
                    <div class="flex min-h-full items-center justify-center p-4 text-center">
                        <TransitionChild as="template" enter="ease-out duration-300"
                            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                            leave-from="opacity-100 translate-y-0 sm:scale-100"
                            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <DialogPanel
                                class="relative transform overflow-hidden rounded-2xl bg-white px-8 pb-8 pt-6 text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
                                <div class="flex flex-col items-center justify-center">
                                    <div
                                        class="flex h-16 w-16 items-center justify-center rounded-full bg-indigo-100 mb-6">
                                        <svg class="animate-spin h-10 w-10 text-indigo-600"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                            </path>
                                        </svg>
                                    </div>
                                    <h3 class="text-xl font-semibold text-gray-900 mb-2">Generating Your Survey</h3>
                                    <p class="text-sm text-gray-500 text-center">Please wait while we create your
                                        customized survey. This may take a few moments...</p>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>

        <!-- Main Content -->
        <div v-else>
            <div class="flex items-center justify-between mb-5">
                <div class="flex flex-col">
                    <div class="flex items-center gap-2">
                        <div v-if="isEditingTitle" class="flex items-center gap-2 w-full">
                            <input type="text" v-model="editedTitle" @blur="saveTitleEdit" @keyup.enter="saveTitleEdit"
                                ref="titleInput"
                                class="text-2xl font-semibold bg-white border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent" />
                        </div>
                        <h1 v-else class="text-2xl text-left font-semibold">{{ survey_title }}</h1>
                        <button @click="startTitleEdit"
                            class="text-gray-400 ml-3 hover:text-gray-600 focus:outline-none"
                            :class="{ 'opacity-0': isEditingTitle }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div>
                    <button type="button" @click="previewSurvey"
                        class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        Preview
                    </button>
                    <button type="button" @click="showDraftModal" :disabled="isUpdating"
                        class="ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        <span v-if="isUpdating"
                            class="animate-spin inline-block w-4 h-4 border-2 border-gray-900 border-t-transparent rounded-full"></span>
                        <span v-else>Save as Draft</span>
                    </button>
                    <button type="button" @click="showPublishModal" :disabled="isUpdating"
                        class="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        <span v-if="isUpdating"
                            class="animate-spin inline-block w-4 h-4 border-2 border-white border-t-transparent rounded-full"></span>
                        <span v-else>Publish</span>
                    </button>
                </div>
            </div>

            <!-- Sign Up Modal -->
            <div v-if="showModal" class="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
                aria-modal="true">
                <div class="flex min-h-screen items-center justify-center p-4 text-center sm:p-0">
                    <!-- Background overlay -->
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"
                        @click="closeModal"></div>

                    <!-- Modal panel -->
                    <div
                        class="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle">
                        <div>
                            <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100">
                                <svg class="h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                            </div>
                            <div class="mt-3 text-center sm:mt-5">
                                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                    Sign up to {{ modalAction === 'publish' ? 'publish' : 'save' }} your survey
                                </h3>
                                <div class="mt-2">
                                    <p class="text-sm text-gray-500">
                                        Create an account to {{ modalAction === 'publish' ? 'publish' : 'save' }} your
                                        survey and access it later. It only takes a minute!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 sm:mt-6 space-y-3">
                            <button type="button"
                                class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                @click="signUp">
                                Sign up
                            </button>
                            <button type="button"
                                class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                @click="closeModal">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Tab bar -->
            <div class="flex items-center space-x-7 mt-5">
                <div class="flex items-center space-x-1 cursor-pointer border-b-2 pb-4 px-2" :class="section === 'Build'
                    ? 'text-indigo-600 border-indigo-600 text-sm font-medium'
                    : 'text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    " @click="updateSection('Build')">
                    <span>Build</span>
                </div>
                <div class="flex items-center space-x-1 cursor-pointer border-b-2 pb-4 px-2" :class="section === 'Configure'
                    ? 'text-indigo-600 border-indigo-600 text-sm font-medium'
                    : 'text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    " @click="updateSection('Configure')">
                    <span>Configure</span>
                </div>
            </div>
        </div>
        <CreateSurvey class="overflow-y-auto" v-if="section === 'Build' && !isGenerating && !isLoading" />
        <ConfigureSurvey class="overflow-y-auto" v-if="section === 'Configure' && !isGenerating && !isLoading" />
    </div>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue'
import CreateSurvey from './CreateSurvey.vue'
import ConfigureSurvey from './ConfigureSurvey.vue'
import {
    updateSurveyTitle,
    createNewSurvey,
    createInitialMessageBlock,
    createBlocksInSurvey,
    fetchSurveyDetails,
} from '@/services/supabaseService'

export default {
    components: {
        CreateSurvey,
        ConfigureSurvey,
        Dialog,
        DialogPanel,
        TransitionRoot,
        TransitionChild
    },

    setup() {
        const route = useRoute()
        const router = useRouter()
        const authStore = useAuthStore()

        // Reactive state
        const currentUserId = ref(null)
        const userRole = ref(null)
        const tenantId = ref(null)
        const section = ref('Build')
        const survey_title = ref('Untitled Survey')
        const isLoading = ref(false)
        const isUpdating = ref(false)
        const survey_id = ref(null)
        const isEditingTitle = ref(false)
        const editedTitle = ref('')
        const titleInput = ref(null)
        const isGenerating = ref(false)

        // Add new reactive state for modal
        const showModal = ref(false)
        const modalAction = ref('publish') // 'publish' or 'draft'

        // Cookie management functions
        const SURVEY_COOKIE_KEY = 'user_created_surveys'

        const getSurveyIdsFromCookie = () => {
            const cookieValue = document.cookie
                .split('; ')
                .find(row => row.startsWith(SURVEY_COOKIE_KEY))
                ?.split('=')[1]
            
            return cookieValue ? JSON.parse(decodeURIComponent(cookieValue)) : []
        }

        const addSurveyToCookie = (surveyId) => {
            const existingSurveys = getSurveyIdsFromCookie()
            if (!existingSurveys.includes(surveyId)) {
                const updatedSurveys = [...existingSurveys, surveyId]
                const oneYear = 365 * 24 * 60 * 60 * 1000
                const expiryDate = new Date(Date.now() + oneYear).toUTCString()
                document.cookie = `${SURVEY_COOKIE_KEY}=${encodeURIComponent(JSON.stringify(updatedSurveys))}; expires=${expiryDate}; path=/`
            }
        }

        // Methods
        const updateSection = (newSection) => {
            section.value = newSection
            router.push({
                name: route.name,
                query: { tab: newSection }
            })
        }

        const previewSurvey = () => {
            if (survey_id.value) {
                window.open(`/survey/${survey_id.value}?isPreview=true`, '_blank')
            }
        }

        const showPublishModal = () => {
            modalAction.value = 'publish'
            showModal.value = true
        }

        const showDraftModal = () => {
            modalAction.value = 'draft'
            showModal.value = true
        }

        const closeModal = () => {
            showModal.value = false
        }

        const signUp = () => {
            // Navigate to signup page
            router.push('/signup')
            closeModal()
        }

        const startTitleEdit = async () => {
            editedTitle.value = survey_title.value
            isEditingTitle.value = true
            await nextTick()
            titleInput.value.focus()
        }

        const saveTitleEdit = async () => {
            if (editedTitle.value.trim() !== '') {
                try {
                    isUpdating.value = true
                    if (survey_id.value) {
                        await updateSurveyTitle(survey_id.value, editedTitle.value)
                    }
                    survey_title.value = editedTitle.value
                } catch (err) {
                    console.error('Error updating survey title:', err)
                } finally {
                    isUpdating.value = false
                }
            }
            isEditingTitle.value = false
        }

        const createSurvey = async (surveyData = null) => {
            try {
                isLoading.value = true
                isGenerating.value = true
                const data = await createNewSurvey({
                    tenantId: process.env.VUE_APP_GUEST_TENANT_ID,
                    title: surveyData?.title || survey_title.value,
                    distributionChannel: 'web'
                })
                survey_id.value = data.id
                survey_title.value = surveyData?.title || survey_title.value

                // Store survey ID in cookie
                addSurveyToCookie(data.id)

                // Update the URL with the new survey ID
                router.replace(`/survey-builder/${data.id}`)
                if (surveyData?.questions) {
                    await createBlocksInSurvey(
                        surveyData.questions.map((question, index) => ({
                            type: question.type === 'Multi Select' ? 'multi_choice' : question.type.toLowerCase(),
                            title: question.title,
                            description: question.description,
                            rating_min: question.minValue,
                            rating_max: question.maxValue,
                            rating_max_label: question.maxLabel,
                            rating_min_label: question.minLabel,
                            choices: question.options,
                            button_label: question.buttonLabel,
                            required: question.required || false,
                            survey_id: data.id,
                            position: index + 1,
                            tenant_id: process.env.VUE_APP_GUEST_TENANT_ID,
                        }))
                    )
                }
                await createInitialMessageBlock(data.id, process.env.VUE_APP_GUEST_TENANT_ID, surveyData?.questions?.length || 0 + 1)
                window.location.reload()
            } catch (err) {
                console.error('Error creating survey:', err)
            } finally {
                isLoading.value = false
                isGenerating.value = false
            }
        }

        const fetchExistingSurvey = async (id) => {
            try {
                isLoading.value = true
                const data = await fetchSurveyDetails(id)
                survey_id.value = data.id
                survey_title.value = data.title
            } catch (err) {
                console.error('Error fetching survey:', err)
                // If survey not found, create a new one
                await createSurvey()
            } finally {
                isLoading.value = false
            }
        }

        const parseSurveyDataFromUrl = () => {
            const urlData = route.query.data
            if (urlData) {
                try {
                    const decodedData = decodeURIComponent(atob(urlData))
                    return JSON.parse(decodedData)
                } catch (err) {
                    console.error('Error parsing survey data from URL:', err)
                    return null
                }
            }
            return null
        }

        // Lifecycle hooks
        onMounted(async () => {
            currentUserId.value = authStore.user?.id
            userRole.value = authStore.role
            tenantId.value = authStore.tenantId

            // Check for survey data in URL
            const surveyData = parseSurveyDataFromUrl()

            // Check if survey ID exists in the route
            const surveyIdFromRoute = route.params.survey_id
            if (surveyIdFromRoute) {
                await fetchExistingSurvey(surveyIdFromRoute)
            } else if (surveyData) {
                await createSurvey(surveyData)
            } else {
                await createSurvey()
            }

            // Update initial section based on route query
            const initialTab = route.query.tab
            updateSection(initialTab || 'Build')
        })

        return {
            // State
            currentUserId,
            userRole,
            tenantId,
            section,
            survey_title,
            isLoading,
            isUpdating,
            survey_id,
            isEditingTitle,
            editedTitle,
            titleInput,
            showModal,
            modalAction,
            isGenerating,

            // Methods
            updateSection,
            previewSurvey,
            showPublishModal,
            showDraftModal,
            closeModal,
            signUp,
            startTitleEdit,
            saveTitleEdit,
            getSurveyIdsFromCookie,
        }
    },
}
</script>

<style scoped></style>
<style scoped></style>