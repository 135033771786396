import axios from 'axios';

/**
 * Makes a call to the OpenAI API with the specified prompt
 * @param {string} prompt - The text prompt to send to OpenAI
 * @param {Object} options - Additional options for the API call
 * @param {string} options.model - The model to use (default: 'gpt-3.5-turbo')
 * @param {number} options.maxTokens - Maximum number of tokens in the response (default: 500)
 * @param {number} options.temperature - Controls randomness (0-1) (default: 0.7)
 * @param {string} options.apiKey - OpenAI API key (required if not set in environment)
 * @returns {Promise<Object>} - The response from OpenAI
 */
export const callOpenAI = async (prompt, options = {}) => {
  if (!prompt) {
    throw new Error('Prompt is required');
  }

  const apiKey = options.apiKey || process.env.VUE_APP_OPENAI_API_KEY;
  
  if (!apiKey) {
    throw new Error('OpenAI API key is required. Provide it in options or set VUE_APP_OPENAI_API_KEY in environment variables.');
  }

  const {
    model = 'gpt-4o',
    maxTokens = 1000,
    temperature = 0.7,
  } = options;

  try {
    const response = await axios.post('https://api.openai.com/v1/chat/completions', {
      model,
      messages: [
        {
          role: 'user',
          content: prompt
        }
      ],
      max_tokens: maxTokens,
      temperature,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error calling OpenAI API:', error.response?.data || error.message);
    throw error;
  }
};

/**
 * A simpler version to directly get text from OpenAI
 * @param {string} prompt - The text prompt to send to OpenAI
 * @param {Object} options - See callOpenAI for available options
 * @returns {Promise<string>} - The generated text content
 */
export const getOpenAIResponse = async (prompt, options = {}) => {
  const response = await callOpenAI(prompt, options);
  return response.choices[0]?.message?.content || '';
};
