<template>
  <div class="p-6 max-w-[75rem] w-[75rem] mx-auto w-full">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-bold">User Attributes</h2>
      <button type="button" @click="showPopup = true"
        class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        New Attribute
      </button>
    </div>

    <div v-if="isLoading">
      <div class="mt-8 flow-root animate-pulse">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-0">
                    <div class="h-4 w-20 bg-gray-300 rounded"></div>
                  </th>
                  <th scope="col" class="px-3 py-3.5">
                    <div class="h-4 w-24 bg-gray-300 rounded"></div>
                  </th>
                  <th scope="col" class="px-3 py-3.5">
                    <div class="h-4 w-16 bg-gray-300 rounded"></div>
                  </th>
                  <th scope="col" class="px-3 py-3.5">
                    <div class="h-4 w-24 bg-gray-300 rounded"></div>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr v-for="i in 4" :key="i">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-0">
                    <div class="h-4 w-32 bg-gray-300 rounded"></div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4">
                    <div class="h-4 w-48 bg-gray-300 rounded"></div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4">
                    <div class="h-4 w-24 bg-gray-300 rounded"></div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4">
                    <div class="h-6 w-24 bg-gray-300 rounded"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="attributes.length === 0" class="text-center py-4 mt-10 bg-gray-100 rounded-lg shadow">
        <p class="text-gray-700 font-medium">
          There are no custom attributes. Create one now!
        </p>
      </div>

      <div v-else class="overflow-hidden">
        <div class="mt-8 flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Name</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Description</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
                    <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Data Type</th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                  <tr v-for="(attribute, index) in paginatedAttributes" :key="index" class="hover:bg-gray-50">
                    <td class="whitespace-nowrap text-left py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">
                      {{ attribute.name }}
                    </td>
                    <td class="whitespace-nowrap text-left px-3 py-4 text-sm text-gray-500">
                      {{ attribute.description || '-' }}
                    </td>
                    <td class="whitespace-nowrap text-left px-3 py-4 text-sm text-gray-500">
                      {{ formatDate(attribute.createdAt) }}
                    </td>
                    <td class="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">
                      <span class="inline-flex items-center justify-center rounded-md bg-gray-50 w-24 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">
                        {{ attribute.dataTypeDisplay[0].toUpperCase() + attribute.dataTypeDisplay.slice(1) }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isLoading && attributes.length > itemsPerPage" class="mt-4">
      <div class="flex flex-1 justify-between sm:hidden">
        <button @click="previousPage" :disabled="currentPage === 1"
          class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed">
          Previous
        </button>
        <button @click="nextPage" :disabled="currentPage === totalPages"
          class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed">
          Next
        </button>
      </div>
      <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p class="text-sm text-gray-700">
            Showing
            <span class="font-medium">{{ ((currentPage - 1) * itemsPerPage) + 1 }}</span>
            to
            <span class="font-medium">{{ Math.min(currentPage * itemsPerPage, attributes.length) }}</span>
            of
            <span class="font-medium">{{ attributes.length }}</span>
            results
          </p>
        </div>
        <div>
          <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button @click="previousPage" :disabled="currentPage === 1"
              class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed">
              <span class="sr-only">Previous</span>
              <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
            </button>

            <button v-for="page in totalPages" :key="page" @click="currentPage = page" :class="[
              page === currentPage
                ? 'relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                : 'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
            ]">
              {{ page }}
            </button>

            <button @click="nextPage" :disabled="currentPage === totalPages"
              class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed">
              <span class="sr-only">Next</span>
              <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>

    <!-- Create a New Attribute Modal -->
    <div v-if="showPopup" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div>
              <div>
                <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">
                  Create a New Attribute
                </h3>
              </div>
            </div>

            <div class="mt-6 mb-16">
              <div class="mb-4">
                <label for="attribute-name" class="block text-sm font-medium leading-6 text-gray-900">Attribute
                  Name</label>
                <input id="attribute-name" v-model="newAttributeName" type="text" placeholder="e.g. planType"
                  class="input-sondar" />
                <p v-if="errorMessageName" class="mt-1 text-sm text-red-600">
                  {{ errorMessageName }}
                </p>
              </div>

              <div class="mb-4">
                <label for="attribute-data-type" class="block text-sm font-medium leading-6 text-gray-900">Data
                  Type</label>
                <select id="attribute-data-type" v-model="newAttributeDataType" class="select-sondar">
                  <option value="" disabled>Select data type</option>
                  <option value="number">Number</option>
                  <option value="text">Text</option>
                  <option value="bool">Boolean</option>
                  <option value="datetime-local">Date & Time</option>
                  <option value="date">Date</option>
                </select>
                <p v-if="errorMessageDataType" class="mt-1 text-sm text-red-600">
                  {{ errorMessageDataType }}
                </p>
              </div>

              <div class="mb-4">
                <label for="attribute-description"
                  class="block text-sm font-medium leading-6 text-gray-900">Description</label>
                <input id="attribute-description" v-model="newAttributeDescription" type="text"
                  placeholder="Enter attribute description (optional)" class="input-sondar" />
              </div>

              <p v-if="errorMessage" class="mt-2 text-sm text-red-600">
                {{ errorMessage }}
              </p>
            </div>

            <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button type="button" @click="closeModal()" class="btn-secondary-sondar">
                Cancel
              </button>
              <button type="button" @click="createAttribute" class="btn-primary-sondar">
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { format } from "date-fns"
import { useAuthStore } from '@/stores/auth'
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline"
import { fetchTenantAttributes, createTenantAttribute } from '@/services/supabaseService'

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon
  },
  setup() {
    // State variables using ref
    const currentUserId = ref(null)
    const userRole = ref(null)
    const tenantId = ref(null)
    const attributes = ref([])
    const currentPage = ref(1)
    const itemsPerPage = ref(10)
    const isLoading = ref(true)
    const showPopup = ref(false)
    const newAttributeName = ref("")
    const newAttributeDataType = ref("")
    const newAttributeDescription = ref("")
    const errorMessageName = ref("")
    const errorMessageDataType = ref("")
    const errorMessage = ref("")

    // Computed properties
    const totalPages = computed(() => {
      return Math.ceil(attributes.value.length / itemsPerPage.value)
    })

    const paginatedAttributes = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value
      return attributes.value.slice(start, start + itemsPerPage.value)
    })

    // Methods
    const closeModal = () => {
      showPopup.value = false
      newAttributeName.value = ""
      newAttributeDataType.value = ""
      newAttributeDescription.value = ""
      errorMessageName.value = ""
      errorMessageDataType.value = ""
      errorMessage.value = ""
    }

    const fetchAttributes = async () => {
      isLoading.value = true
      try {
        attributes.value = await fetchTenantAttributes(tenantId.value)
      } catch (err) {
        console.error("Error fetching attributes:", err)
      } finally {
        isLoading.value = false
      }
    }

    const formatDate = (date) => {
      return format(new Date(date), "MMM do, yyyy")
    }

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++
      }
    }

    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--
      }
    }

    const createAttribute = async () => {
      let isValid = true

      if (!newAttributeName.value.trim() || newAttributeName.value.includes(" ")) {
        errorMessageName.value = "Attribute name is required. Must not contain any spaces."
        isValid = false
      } else {
        errorMessageName.value = ""
      }

      if (!newAttributeDataType.value) {
        errorMessageDataType.value = "Data type is required."
        isValid = false
      } else {
        errorMessageDataType.value = ""
      }

      if (!isValid) return

      try {
        await createTenantAttribute({
          name: newAttributeName.value,
          dataType: newAttributeDataType.value,
          description: newAttributeDescription.value,
          tenantId: tenantId.value
        })

        closeModal()
        fetchAttributes()
      } catch (err) {
        console.error("Error creating attribute:", err)
        errorMessage.value = "Failed to create attribute. Try again later."
      }
    }

    const editAttribute = (id) => {
      console.log(id)
    }

    const deleteAttribute = async (id) => {
      console.log(id)
    }

    // Lifecycle hook
    onMounted(async () => {
      const authStore = useAuthStore()
      currentUserId.value = authStore.user.id
      userRole.value = authStore.role
      tenantId.value = authStore.tenantId
      await fetchAttributes()
    })

    // Return all refs, computed properties and methods that are used in the template
    return {
      currentUserId,
      userRole,
      tenantId,
      attributes,
      currentPage,
      itemsPerPage,
      isLoading,
      showPopup,
      newAttributeName,
      newAttributeDataType,
      newAttributeDescription,
      errorMessageName,
      errorMessageDataType,
      errorMessage,
      totalPages,
      paginatedAttributes,
      closeModal,
      fetchAttributes,
      formatDate,
      nextPage,
      previousPage,
      createAttribute,
      editAttribute,
      deleteAttribute
    }
  }
}
</script>
