<template>
  <div v-if="channel === 'web'" class="flex items-center gap-x-2">
    <div class="h-2.5 w-2.5 rounded-full" :class="sdkConnected ? 'bg-green-500' : 'bg-red-500'"></div>
    <span class="text-sm text-gray-600">{{ sdkConnected ? 'Connected' : 'Not Connected' }}</span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SDKConnectionStatus',
  props: {
    channel: {
      type: String,
      required: true
    },
    sdkConnected: {
      type: Boolean,
      required: true
    }
  }
});
</script> 