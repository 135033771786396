<template>
  <div class="mb-8">
    <label v-if="showHeading" class="block text-sm font-medium leading-6 text-gray-900 mb-2">Select a Distribution Channel</label>
    <div class="flex space-x-4">
      <div @click="$emit('update:modelValue', 'web')" :class="{
        'border-indigo-600 ring-2 ring-indigo-600': modelValue === 'web',
        'hover:ring-1 hover:ring-gray-300': modelValue !== 'web',
      }"
        class="group relative flex cursor-pointer rounded-lg text-left border border-gray-300 bg-white px-4 py-4 shadow-xs focus:outline-hidden data-focus:border-indigo-600 data-focus:ring-2 data-focus:ring-indigo-600">
        <heroicon-outline-globe-alt class="w-6 h-6 text-gray-900" />
        <div class="ml-1.5 mt-0.5">
          <h4 class="block text-sm font-semibold text-gray-900">
            Web
          </h4>
          <p class="text-sm text-gray-500 mt-1">
            Collect feedback directly on your website
          </p>
        </div>
      </div>

      <div @click="$emit('update:modelValue', 'sharable link')" :class="{
        'border-indigo-600 ring-2 ring-indigo-600': modelValue === 'sharable link',
        'hover:ring-1 hover:ring-gray-300': modelValue !== 'sharable link',
      }"
        class="group relative flex cursor-pointer rounded-lg text-left border border-gray-300 bg-white px-4 py-4 shadow-xs focus:outline-hidden data-focus:border-indigo-600 data-focus:ring-2 data-focus:ring-indigo-600">
        <heroicon-outline-link class="w-6 h-6 text-gray-900" />
        <div class="ml-1.5 mt-0.5">
          <h4 class="block text-sm font-semibold text-gray-900">
            Sharable Link
          </h4>
          <p class="text-sm text-gray-500 mt-1">
            Share this survey on any channel with a link
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GlobeAltIcon, LinkIcon } from "@heroicons/vue/24/outline"

export default {
  name: 'ChannelSelection',
  components: {
    HeroiconOutlineGlobeAlt: GlobeAltIcon,
    HeroiconOutlineLink: LinkIcon,
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    showHeading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue']
}
</script> 