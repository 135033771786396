<template>
  <div class="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-10 w-auto" src="../../assets/full-logo.svg" alt="Your Company" />
      <h2 class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Sign in to your account
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">

        <!-- Email Sent Success Message -->
        <div v-if="emailSentSuccess" class="mb-6 rounded-md bg-green-50 p-4">
          <div class="flex">
            <div class="ml-3">
              <h3 class="text-sm font-medium text-green-800">Access Link Sent!</h3>
              <div class="mt-2 text-sm text-left text-green-700">
                <p>We've sent a secure access link to your email address. Please check your inbox and click the link to sign in to your account. The link will expire in 60 minutes for security reasons.</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Access Revoked Error -->
        <div v-if="accessRevokedError" class="rounded-md bg-red-50 p-4 mb-3">
          <div class="flex">
            <div class="ml-3">
              <div class="mt-2 text-sm text-red-700">
                <p>Your account has been deactivated. Please contact your administrator for assistance.</p>
              </div>
            </div>
          </div>
        </div>

        <form @submit.prevent="handleSignIn" class="space-y-6">
          <div>
            <label for="email" class="block text-left text-sm font-medium leading-6 text-gray-900">Email address</label>
            <div class="mt-2">
              <input v-model="email" type="email" name="email" id="email" autocomplete="email" required
                placeholder="you@example.com" @input="validateEmail"
                class="block w-full p-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              <p v-if="emailError" class="text-left mt-1 text-sm text-red-600">{{ emailError }}</p>
            </div>
          </div>

          <div>
            <button type="submit" :disabled="isLoading || !isFormValid"
              class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-300 disabled:cursor-not-allowed">
              <template v-if="isLoading">
                <svg class="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
                Sending access link...
              </template>
              <template v-else>
                Login
              </template>
            </button>
          </div>

          <p v-if="formError" class="mt-4 text-sm text-red-600 text-center">{{ formError }}</p>
        </form>
      </div>

      <p class="mt-10 text-center text-sm text-gray-500">
        Don't have an account?
        <router-link to="/signup" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
          Sign up
        </router-link>
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useAuthStore } from '@/stores/auth'

// Reactive state
const email = ref('')
const emailError = ref('')
const formError = ref('')
const isLoading = ref(false)
const isEmailValid = ref(false)
const accessRevokedError = ref(false)
const emailSentSuccess = ref(false)
const authStore = useAuthStore()

const isFormValid = computed(() => {
  return isEmailValid.value && !emailError.value
})

// Methods
const validateEmail = () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const isValid = emailRegex.test(email.value)
  emailError.value = isValid ? '' : 'Please enter a valid email address'
  isEmailValid.value = isValid
}

const handleSignIn = async () => {
  emailError.value = ''
  formError.value = ''
  accessRevokedError.value = false

  if (!email.value) {
    emailError.value = 'Email is required'
    return
  }

  try {
    isLoading.value = true
    await authStore.requestAccessLink({
      email: email.value
    })
    emailSentSuccess.value = true
  } catch (err) {
    if (err.message.includes('deactivated')) {
      accessRevokedError.value = true
    } else {
      formError.value = err.message || 'An unexpected error occurred. Please try again later.'
    }
  } finally {
    isLoading.value = false
  }
}
</script>
