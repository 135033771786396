<template>
  <div id="team-members">
    <!-- Team Members List -->
    <div class="overflow-hidden">
      <div class="bg-white">
        <table class="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th scope="col" class="py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Role</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
              <th v-if="isAdmin" scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr v-for="(user, index) in teamMembers" :key="index" class="hover:bg-gray-50">
              <td class="whitespace-nowrap py-4 text-sm">
                <div class="flex items-center">
                  <div class="h-8 w-8 flex-shrink-0 rounded-full bg-indigo-100 flex items-center justify-center">
                    <span class="text-xs font-medium text-indigo-700">{{ getInitials(user.email) }}</span>
                  </div>
                  <div class="ml-4">
                    <div class="font-medium text-gray-900">{{ user.email }}</div>
                  </div>
                </div>
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm">
                <span :class="[
                  user.role === 'tenant_admin'
                    ? 'bg-blue-50 text-blue-700 ring-blue-600/20'
                    : 'bg-green-50 text-green-700 ring-green-600/20',
                  'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset'
                ]">
                  {{ user.role === 'tenant_admin' ? 'Admin' : 'Member' }}
                </span>
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm">
                <span :class="[
                  user.is_active
                    ? 'bg-green-50 text-green-700 ring-green-600/20'
                    : 'bg-gray-50 text-gray-600 ring-gray-500/10',
                  'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset'
                ]">
                  {{ user.is_active ? 'Active' : 'Paused' }}
                </span>
              </td>
              <td v-if="isAdmin"
                class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <button v-if="user.email !== currentUser.email" @click="$emit('edit-member', user)"
                  class="rounded-md p-2 text-gray-400 hover:bg-gray-50 hover:text-gray-500">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                  </svg>
                  <span class="sr-only">Edit {{ user.email }}</span>
                </button>
                <span v-else class="text-xs text-gray-400 mr-2">
                  (You)
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TeamTab',
  props: {
    teamMembers: {
      type: Array,
      required: true
    },
    currentUser: {
      type: Object,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: true
    }
  },
  emits: ['edit-member'],
  setup() {
    const getInitials = (email) => {
      return email
        .split('@')[0]
        .split(/[-._]/)
        .map(part => part[0])
        .join('')
        .toUpperCase()
        .slice(0, 2);
    };

    return {
      getInitials
    };
  }
});
</script> 