<template>
  <div class="min-h-screen bg-white">
    <!-- Main container with narrower width -->
    <div class="mx-auto max-w-[1080px] px-4 sm:px-6 lg:px-8 py-8">
      <!-- Back button -->
      <div class="mb-8 flex justify-between items-center">
        <router-link
          to="/"
          class="group inline-flex items-center gap-x-2 text-sm font-medium text-gray-500 hover:text-gray-700"
        >
          <svg
            class="-ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M17 10a.75.75 0 01-.75.75H5.612l4.158 4.158a.75.75 0 11-1.04 1.04l-5.5-5.5a.75.75 0 010-1.08l5.5-5.5a.75.75 0 111.04 1.04L5.612 9.25H16.25A.75.75 0 0117 10z"
              clip-rule="evenodd"
            />
          </svg>
          Back
        </router-link>
        <img
          class="h-8 w-auto absolute left-1/2 -translate-x-1/2"
          src="../../assets/full-logo.svg"
          alt="Your Company"
        />
        <div class="w-[38px]"></div>
      </div>
      <div class="border-b border-gray-200 mb-8"></div>

      <div v-if="isLoading" class="animate-pulse">
        <div class="h-8 bg-gray-200 rounded w-1/3 mb-4"></div>
        <div class="h-4 bg-gray-200 rounded w-2/3 mb-8"></div>
        <div class="space-y-4">
          <div class="h-4 bg-gray-200 rounded w-full"></div>
          <div class="h-4 bg-gray-200 rounded w-5/6"></div>
          <div class="h-4 bg-gray-200 rounded w-4/6"></div>
        </div>
      </div>

      <div v-else>
        <div class="mb-8">
          <h1 class="text-2xl font-bold text-gray-900 mb-4 text-left">
            {{ template.title }}
          </h1>
          <p class="text-md text-gray-600 text-left">
            {{ description }}
          </p>

          <!-- Action buttons with proper spacing -->
          <div class="mt-4 flex gap-4 justify-start">
            <button
              type="button"
              class="inline-flex items-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              @click="previewTemplate"
            >
              Preview Template
            </button>
            <button
              type="button"
              class="inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              @click="useTemplate"
            >
              Use Template
            </button>
          </div>
        </div>

        <!-- Add this after the title and description -->
        <div v-if="thumbnail" class="mt-6 mb-8 flex justify-center">
          <img
            :src="thumbnail"
            :alt="template.title"
            class="w-[700px] rounded-lg shadow-sm"
          />
        </div>

        <!-- Content sections with proper spacing -->
        <div class="space-y-8">
          <template v-for="[title, content] in sortedSections" :key="title">
            <section
              :aria-labelledby="`${title
                .toLowerCase()
                .replace(/\s+/g, '-')}-heading`"
            >
              <h2
                :id="`${title.toLowerCase().replace(/\s+/g, '-')}-heading`"
                class="text-lg font-bold text-gray-900 mb-3 text-left"
              >
                {{ title }}
              </h2>
              <div
                class="text-sm text-gray-600 leading-relaxed text-left"
                v-html="content"
              ></div>
            </section>
          </template>
        </div>
      </div>
    </div>

    <!-- Add the confirmation modal -->
    <TransitionRoot as="template" appear :show="showCloneModal">
      <Dialog as="div" class="relative z-50" @close="showCloneModal = false">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
              >
                <div class="sm:flex sm:items-start">
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      class="text-base font-semibold leading-6 text-gray-900"
                    >
                      Clone Template
                    </DialogTitle>

                    <div class="mt-4">
                      <ChannelSelection v-model="selectedChannel" />
                    </div>
                    <p class="text-sm text-gray-500">
                      Cloning will make a copy of this survey in your account
                      that you are can modify.
                    </p>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    @click="cloneTemplateHandler"
                    :disabled="isCloning"
                  >
                    <template v-if="isCloning">
                      <svg
                        class="animate-spin h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </template>
                    <template v-else> Yes, Clone it </template>
                  </button>
                  <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    @click="showCloneModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { useRouter } from 'vue-router'
import { useAuthStore } from "@/stores/auth";
import {
  fetchTemplateDetails,
  incrementTemplateStat,
  cloneTemplate,
} from "@/services/supabaseService";
import ChannelSelection from "@/components/commons/ChannelSelection.vue";

export default {
  name: "PreviewTemplate",
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ChannelSelection,
  },
  props: {
    templateId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()
    const tenantId = ref(null);
    const selectedChannel = ref("web");
    const template = ref({
      title: "",
      description: "",
      metadata: {
        description: "",
        template_content: {},
        category: "",
        thumbnail: null,
        preview_url: null,
      },
    });
    const isLoading = ref(true);
    const showCloneModal = ref(false);
    const isCloning = ref(false);

    // Computed properties
    const sortedSections = computed(() => {
      const order = [
        "Thumbnail",
        "When to Use",
        "Target Audience",
        "What You Will Learn",
        "Questions",
      ];

      const templateContent = template.value?.metadata?.template_content || {};
      const sections = Object.entries(templateContent);

      return sections.sort((a, b) => {
        const indexA = order.indexOf(a[0]);
        const indexB = order.indexOf(b[0]);

        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;
        return 0;
      });
    });

    const categories = computed(() => {
      return (
        template.value?.metadata?.category
          ?.split(",")
          .map((cat) => cat.trim()) || []
      );
    });

    const description = computed(() => {
      return (
        template.value?.metadata?.description || "No description available"
      );
    });

    const thumbnail = computed(() => {
      return template.value?.metadata?.thumbnail || null;
    });

    // Methods
    const fetchTemplate = async () => {
      try {
        isLoading.value = true;
        const templateData = await fetchTemplateDetails(props.templateId);
        if (templateData) {
          template.value = templateData;
          incrementStat("views_count");
        }
      } catch (error) {
        console.error("Error fetching template:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const incrementStat = async (statType) => {
      try {
        await incrementTemplateStat(props.templateId, statType);
      } catch (error) {
        console.error(`Error incrementing ${statType}:`, error);
      }
    };

    const previewTemplate = () => {
      window.open(`/survey/${props.templateId}?isPreview=true`, "_blank");
    };

    const useTemplate = () => {
      showCloneModal.value = true;
    };

    const cloneTemplateHandler = async () => {
      try {
        isCloning.value = true;
        await incrementStat("clones_count");
        await cloneTemplate(
          props.templateId,
          tenantId.value,
          selectedChannel.value
        );
        showCloneModal.value = false;
        router.push('/')
      } catch (err) {
        console.error("Error cloning template:", err);
        alert("Failed to clone template. Please try again.");
      } finally {
        isCloning.value = false;
      }
    };

    // Lifecycle hooks
    onMounted(async () => {
      const authStore = useAuthStore();
      tenantId.value = authStore.tenantId;
      await fetchTemplate();
    });

    return {
      // State
      template,
      isLoading,
      showCloneModal,
      isCloning,
      selectedChannel,

      // Computed
      sortedSections,
      categories,
      description,
      thumbnail,

      // Methods
      previewTemplate,
      useTemplate,
      cloneTemplateHandler,
    };
  },
};
</script> 