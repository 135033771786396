<template>
  <div class="min-h-screen bg-white">
    <!-- Main container with narrower width -->
    <div class="mx-auto max-w-[1080px] px-4 sm:px-6 lg:px-8 py-8">
      <!-- Back button and logo -->
      <div class="mb-8 flex justify-between items-center">
        <router-link :to="backLink"
          class="group inline-flex items-center gap-x-2 text-sm font-medium text-gray-500 hover:text-gray-700">
          <svg class="-ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" viewBox="0 0 20 20" fill="currentColor"
            aria-hidden="true">
            <path fill-rule="evenodd"
              d="M17 10a.75.75 0 01-.75.75H5.612l4.158 4.158a.75.75 0 11-1.04 1.04l-5.5-5.5a.75.75 0 010-1.08l5.5-5.5a.75.75 0 111.04 1.04L5.612 9.25H16.25A.75.75 0 0117 10z"
              clip-rule="evenodd" />
          </svg>
          Back to Survey
        </router-link>
        <img class="h-8 w-auto absolute left-1/2 -translate-x-1/2" src="../../assets/full-logo.svg" alt="Sondar" />
        <div class="flex-grow"></div>
        <button @click="giveFeedback" :disabled="isSurveyLoading" :class="{ 'opacity-50 cursor-not-allowed': isSurveyLoading }" class="bg-indigo-500 text-white text-sm py-2 px-3 rounded hover:bg-indigo-600">
          {{ isSurveyLoading ? 'Loading...' : 'Give Feedback' }}
        </button>
      </div>
      <div class="border-b border-gray-200 mb-8"></div>

      <!-- Loading State -->
      <div v-if="isLoading" class="animate-pulse">
        <div class="h-8 bg-gray-200 rounded w-1/3 mb-4"></div>
        <div class="h-4 bg-gray-200 rounded w-2/3 mb-8"></div>
        <div class="space-y-4">
          <div class="h-4 bg-gray-200 rounded w-full"></div>
          <div class="h-4 bg-gray-200 rounded w-5/6"></div>
          <div class="h-4 bg-gray-200 rounded w-4/6"></div>
        </div>
      </div>

      <!-- Report Content -->
      <div v-else>
        <!-- Header Section -->
        <div class="mb-8 flex items-center justify-center gap-x-3">
          <h1 v-if="reportTitle" class="text-2xl font-bold text-gray-900">{{ reportTitle }}</h1>
          <span class="inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ring-1 ring-inset whitespace-nowrap bg-indigo-500 text-white">
            BETA
          </span>
        </div>

        <!-- Scope Section -->
        <section class="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden mb-8">
          <div class="px-6 py-5">
            <!-- Header -->
            <h2 class="text-lg font-semibold leading-6 text-gray-900 mb-6">Report Scope</h2>

            <!-- Survey Details Table -->
            <div class="mb-6">
              <div class="grid grid-cols-4 gap-4 text-md border-b border-gray-200 pb-4">
                <div>
                  <div class="font-medium text-gray-500 mb-1">Created By</div>
                  <div class="text-gray-900">{{ report.scope.created_by }}</div>
                </div>
                <div>
                  <div class="font-medium text-gray-500 mb-1">Created On</div>
                  <div class="text-gray-900">{{ report.scope.created_at }}</div>
                </div>
                <div>
                  <div class="font-medium text-gray-500 mb-1">Survey Period</div>
                  <div class="text-gray-900">{{ report.scope.date_range }}</div>
                </div>
                <div>
                  <div class="font-medium text-gray-500 mb-1">Responses</div>
                  <div class="text-gray-900">{{ report.scope.responses }}</div>
                </div>
              </div>
            </div>

            <!-- Segmentation -->
            <div class="mb-2">
              <div class="text-left flex items-baseline gap-x-4">
                <h3 class="text-md font-medium text-gray-500">User Attributes:</h3>
                <div class="flex-1">
                  <span v-for="(segment, idx) in report.scope.segmentation_and_attributes.split(', ')" :key="idx"
                    class="text-md text-gray-900">
                    {{ segment }}{{ idx < report.scope.segmentation_and_attributes.split(', ').length - 1 ? ', ' : '' }}
                  </span>
                </div>
              </div>
            </div>

            <!-- Questions -->
            <div>
              <h3 class="text-md text-left font-medium text-gray-500 mb-3">Questions:</h3>
              <div class="pl-4">
                <div v-for="(question, index) in report.scope.questions" :key="index" 
                    class="flex gap-x-4 text-md text-gray-900 mb-2">
                  <span class="text-gray-500 w-5">{{ index + 1 }}.</span>
                  <span>{{ question }}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Key Findings -->
        <section class="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden mb-8">
          <div class="px-6 py-5">
            <h2 class="text-lg font-semibold text-gray-900 mb-4">Key Findings</h2>
            <div class="space-y-6 text-left">
              <div v-for="(finding, index) in report.key_findings.points" :key="index"
                class="border-l-4 border-[#74C5F9] pl-4 py-2">
                <h3 class="text-md font-semibold text-gray-900" v-html="finding.title"></h3>
                <p class="mt-1 text-md text-gray-900" v-html="finding.description"></p>
                <ul v-if="finding.bullets" class="mt-2 list-disc list-inside text-md text-gray-900">
                  <li v-for="(bullet, bIndex) in finding.bullets" :key="bIndex" v-html="bullet"></li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <!-- Trends -->
        <section class="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden mb-8">
          <div class="px-6 py-5 pb-8">
            <h2 class="text-lg font-semibold text-gray-900 mb-4">Top Trends</h2>
            <div class="space-y-6 text-left">
              <div v-for="(trend, index) in report.trends.points" :key="index" class="flex items-start gap-4">
                <div class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-[#FFEBD0]">
                  <span class="text-lg text-[#ED902B]">{{ index + 1 }}</span>
                </div>
                <div>
                  <h3 class="text-md font-semibold text-gray-900" v-html="trend.title"></h3>
                  <p class="mt-1 text-md text-gray-900" v-html="trend.description"></p>
                  <ul v-if="trend.bullets" class="mt-2 list-disc list-inside text-md text-gray-900">
                    <li v-for="(bullet, bIndex) in trend.bullets" :key="bIndex" v-html="bullet"></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Opportunities -->
        <section class="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          <div class="px-6 py-5">
            <h2 class="text-lg font-semibold text-gray-900 mb-4">Top Opportunities</h2>
            <div class="grid grid-cols-1 gap-6 text-left">
              <div v-for="(opportunity, index) in report.opportunities.points" :key="index"
                class="bg-gradient-to-br to-emerald-50 rounded-lg p-2">
                <div class="flex items-start gap-4">
                  <div class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-lg bg-[#DAEEDF]">
                    <svg class="h-6 w-6 text-[#5DB260]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                    </svg>
                  </div>
                  <div>
                    <h3 class="text-md font-semibold text-gray-900" v-html="opportunity.title"></h3>
                    <p class="mt-1 text-md text-gray-900" v-html="opportunity.description"></p>
                    <ul v-if="opportunity.bullets" class="mt-2 space-y-2">
                      <li v-for="(bullet, bIndex) in opportunity.bullets" :key="bIndex" class="text-md" v-html="bullet">
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { fetchAnalysisReport } from '@/services/supabaseService';

export default {
  name: 'AIAnalysisReport',
  setup() {
    const isLoading = ref(true);
    const surveyId = ref(null);
    const report = ref({});
    const reportTitle = ref('');
    const route = useRoute();
    const isSurveyLoading = ref(false);
    const reportId = ref(route.params.reportId);
    // Computed property for the back link
    const backLink = computed(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const surveyIdFromQuery = searchParams.get('survey_id');
      return surveyIdFromQuery ? `/survey/edit/${surveyIdFromQuery}` : '/';
    });

    const getStatusClass = (status) => {
      const classes = {
        completed: 'bg-green-50 text-green-700 ring-green-600/20',
        processing: 'bg-blue-50 text-blue-700 ring-blue-600/20',
        failed: 'bg-red-50 text-red-700 ring-red-600/20',
        pending: 'bg-yellow-50 text-yellow-700 ring-yellow-600/20'
      };
      return classes[status.toLowerCase()] || 'bg-gray-50 text-gray-700 ring-gray-600/20';
    };

    const formatDateRange = (dateRange) => {
      const [start, end] = dateRange.split(' to ').map(date => new Date(date));
      return `${formatDate(start)} to ${formatDate(end)}`;
    };

    const formatDate = (date) => {
      return date.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      });
    };

    const giveFeedback = () => {
      isSurveyLoading.value = true;
      // eslint-disable-next-line no-undef
      SondarSDK.showSurvey(process.env.VUE_APP_AI_ANALYSIS_SURVEY_ID);

      setTimeout(() => {
        isSurveyLoading.value = false;
      }, 3000);
    };

    const fetchReport = async () => {
      try {
        isLoading.value = true;
        const data = await fetchAnalysisReport(reportId.value);
        const reportData = data.data || {};
        report.value = reportData;
        surveyId.value = data.survey_id;
        reportTitle.value = data.title;
      } catch (error) {
        console.error('Error fetching report:', error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchReport();
    });

    return {
      isLoading,
      report,
      getStatusClass,
      formatDateRange,
      backLink,
      giveFeedback,
      reportTitle,
      isSurveyLoading
    };
  }
};
</script>