<template>
    <div class="chat-container w-full">
        <div class="chat-messages flex flex-col max-h-96 overflow-y-auto" ref="chatMessages">
            <div v-for="(message, index) in messages" :key="index" :class="['message', message.type]">
                <img src="../../assets/robot-face.png" alt="logo">
                <p>{{ message.text }}</p>
            </div>
            <div v-if="(!openingMessage && !chatEnded) || waitingForResponse" class="message system typing-indicator">
                <img src="../../assets/robot-face.png" alt="logo">
                <div class="bg-indigo-700 text-white px-5 py-2 rounded-xl rounded-tl-sm">
                    <span class="dot">.</span><span class="dot">.</span><span class="dot">.</span>
                </div>
            </div>
        </div>

        <transition name="fade" @after-leave="handleChatEnd">
            <div v-if="!chatEnded" class="chat-input flex p-1">
                <input v-model="userInput" @keyup.enter="sendMessage" type="text" placeholder="Type here..."
                    class="mr-2 block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:outline-none sm:text-sm/6">
                <button @click="sendMessage"
                    class=" rounded-lg bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                    </svg>
                </button>
            </div>
        </transition>
    </div>
</template>

<script>
import { ref, watch, nextTick } from 'vue'
import axios from 'axios'

export default {
    name: 'FollowUp',
    props: {
        openingMessage: {
            type: String,
            default: null
        },
        responseId: {
            type: String,
            required: true
        },
        blockId: {
            type: String,
            required: true
        },
        fuQuestionsRemaining: {
            type: Number,
            required: false,
        },
        messageId: {
            type: String,
            required: false,
        }
    },
    setup(props, { emit }) {
        // Reactive state
        const messages = ref(props.openingMessage ? [{ text: props.openingMessage, type: 'system' }] : [])
        const userInput = ref('')
        const chatEnded = ref(false)
        const questionRem = ref(props.fuQuestionsRemaining)
        const waitingForResponse = ref(false)
        const conversationStarted = ref(false)
        const message_id = ref(props.messageId)
        const chatMessages = ref(null)

        // Methods
        const scrollToBottom = () => {
            nextTick(() => {
                if (chatMessages.value) {
                    chatMessages.value.scrollTop = chatMessages.value.scrollHeight
                }
            })
        }

        const handleChatEnd = () => {
            emit('chatEnded')
        }

        const handleConversationStarted = () => {
            emit('conversationStarted')
        }

        const postResponse = async (message) => {
            const apiUrl = `${process.env.VUE_APP_SUPABASE_EF_BASE_URL}/save-response-item`
            const data = {
                response_id: props.responseId,
                block_id: props.blockId,
                value: message,
            }

            if (message_id.value) {
                data.message_id = message_id.value
            }
            waitingForResponse.value = true
            if (questionRem.value >= 0) questionRem.value--

            try {
                const response = await axios.post(apiUrl, data)
                if (!conversationStarted.value) {
                    conversationStarted.value = true
                    handleConversationStarted()
                }
                messages.value.push({
                    text: response.data.message,
                    type: 'system'
                })
                if (questionRem.value == -1) chatEnded.value = true
                scrollToBottom()
                message_id.value = response.data.message_id
            } catch (error) {
                console.error('Error making API request:', error)
            } finally {
                waitingForResponse.value = false
            }
        }

        const sendMessage = () => {
            if (userInput.value.trim() !== '') {
                messages.value.push({ text: userInput.value, type: 'user' })
                postResponse(userInput.value)
                userInput.value = ''
                scrollToBottom()
            }
        }

        // Watchers
        watch(() => props.openingMessage, (newMessage) => {
            if (newMessage) {
                messages.value = [{ text: newMessage, type: 'system' }]
                scrollToBottom()
            }
        })

        watch(() => props.messageId, (newVal) => {
            message_id.value = newVal
        })

        return {
            // Refs
            messages,
            userInput,
            chatEnded,
            waitingForResponse,
            chatMessages,

            // Methods
            sendMessage,
            handleChatEnd,
            scrollToBottom
        }
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.typing-indicator {
    display: flex;
    align-items: center;
    color: #333;
}

.dot {
    display: inline-block;
    margin: 0 2px;
    border-radius: 50%;
    font-size: 1.5rem;
    animation: bounce 0.6s infinite alternate;
    color: white;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {
    to {
        transform: translateY(-30%);
    }
}
</style>