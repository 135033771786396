<template>
  <div class="relative" v-if="!isOnboardingHidden">
    <!-- Collapsed Widget -->
    <div 
      v-if="!isExpanded" 
      @click="toggleExpanded"
      ref="triggerRef"
      class="cursor-pointer flex items-center gap-2 rounded-md bg-indigo-600 p-1.5 shadow-sm ring-1 ring-indigo-500 hover:ring-indigo-400"
    >
      <!-- Donut Progress -->
      <div class="relative h-5 w-5">
        <svg class="h-5 w-5" viewBox="0 0 36 36">
          <circle 
            cx="18" 
            cy="18" 
            r="16" 
            fill="none" 
            class="stroke-[3] stroke-indigo-400"
          />
          <circle 
            cx="18" 
            cy="18" 
            r="16" 
            fill="none" 
            class="stroke-[3] stroke-white transition-all duration-500"
            :style="{
              strokeDasharray: `${(completedSteps / totalSteps) * 100} 100`,
              transform: 'rotate(-90deg)',
              transformOrigin: 'center'
            }"
          />
        </svg>
        <div class="absolute inset-0 flex items-center justify-center">
          <div class="h-2 w-2 rounded-full bg-white"></div>
        </div>
      </div>

      <div class="flex-1 min-w-0 flex items-center">
        <div class="flex-1">
          <div class="flex items-center gap-2">
            <span class="text-sm text-left font-medium text-white">Get setup</span>
          </div>
          <p v-if="nextStep" class="text-left text-xs text-indigo-100 truncate">
            {{ nextStep.title }}
          </p>
        </div>
        <ChevronDownIcon class="h-4 w-4 text-indigo-100 flex-shrink-0" />
      </div>
    </div>

    <!-- Expanded Widget -->
    <Teleport to="body">
      <Transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <div 
          v-if="isExpanded"
          class="fixed w-96 rounded-lg bg-white shadow-lg ring-1 ring-indigo-200 divide-y divide-gray-100 z-[100] origin-bottom-left"
          :style="expandedPosition"
        >
          <!-- Header -->
          <div 
            @click="toggleExpanded"
            class="flex items-center justify-between p-4 cursor-pointer bg-indigo-600 rounded-t-lg"
          >
            <div class="flex items-center gap-3">
              <!-- Donut Progress -->
              <div class="relative h-5 w-5">
                <svg class="h-5 w-5" viewBox="0 0 36 36">
                  <circle 
                    cx="18" 
                    cy="18" 
                    r="16" 
                    fill="none" 
                    class="stroke-[3] stroke-indigo-400"
                  />
                  <circle 
                    cx="18" 
                    cy="18" 
                    r="16" 
                    fill="none" 
                    class="stroke-[3] stroke-white transition-all duration-500"
                    :style="{
                      strokeDasharray: `${(completedSteps / totalSteps) * 100} 100`,
                      transform: 'rotate(-90deg)',
                      transformOrigin: 'center'
                    }"
                  />
                </svg>
                <div class="absolute inset-0 flex items-center justify-center">
                  <div class="h-2 w-2 rounded-full bg-white"></div>
                </div>
              </div>

              <div class="flex-1 min-w-0">
                <div class="flex items-center gap-2">
                  <span class="text-sm font-medium text-white">Get setup</span>
                  <span class="text-sm text-indigo-100">• {{ completedSteps }}/{{ totalSteps }} steps</span>
                </div>
              </div>
            </div>
            <ChevronUpIcon class="h-5 w-5 text-white" />
          </div>

          <!-- Steps List -->
          <div class="divide-y divide-gray-100">
            <div 
              v-for="(step, index) in steps" 
              :key="index"
              class="group"
            >
              <div 
                @click="toggleStep(index)"
                class="flex items-center gap-3 p-3 cursor-pointer hover:bg-gray-50"
              >
                <div class="flex-shrink-0">
                  <div 
                    class="h-5 w-5 rounded-full flex items-center justify-center"
                    :class="step.completed ? 'bg-green-100' : 'bg-indigo-100'"
                  >
                    <CheckIcon 
                      v-if="step.completed" 
                      class="h-3.5 w-3.5 text-green-600" 
                    />
                    <div 
                      v-else 
                      class="h-2 w-2 rounded-full bg-indigo-600"
                    />
                  </div>
                </div>
                <div class="flex-1 min-w-0">
                  <div class="flex items-center justify-between gap-2">
                    <h3 class="text-sm font-medium" :class="step.completed ? 'text-green-600' : 'text-gray-900'">
                      {{ step.title }}
                    </h3>
                    <ChevronRightIcon 
                      class="h-4 w-4 text-gray-400 transition-transform duration-200"
                      :class="expandedStepIndex === index ? 'rotate-90' : ''"
                    />
                  </div>
                </div>
              </div>

              <Transition
                enter-active-class="transition-all duration-300 ease-out overflow-hidden"
                enter-from-class="h-0 opacity-0"
                enter-to-class="h-auto opacity-100"
                leave-active-class="transition-all duration-300 ease-in-out overflow-hidden"
                leave-from-class="h-auto opacity-100"
                leave-to-class="h-0 opacity-0"
              >
                <div 
                  v-if="expandedStepIndex === index"
                  class="origin-top"
                >
                  <div 
                    class="px-11 pb-3 transition-all duration-300"
                    :class="expandedStepIndex === index ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2'"
                  >
                    <p class="text-sm text-gray-500">{{ step.description }}</p>
                    <a 
                      v-if="step.cta && step.ctaUrl"
                      :href="step.ctaUrl"
                      target="_blank"
                      class="mt-2 inline-flex items-center text-sm font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {{ step.cta }}
                      <ArrowTopRightOnSquareIcon class="ml-1 h-4 w-4" />
                    </a>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>

    <!-- Completion Modal -->
    <TransitionRoot appear :show="showCompletionModal" as="template">
      <Dialog as="div" @close="closeCompletionModal" class="relative z-[110]">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">
                      Onboarding Complete!
                    </DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Congratulations! You've successfully completed all the setup steps for Sondar.ai. You're now ready to start collecting valuable user feedback.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <button
                    type="button"
                    class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    @click="closeCompletionModal"
                  >
                    Continue to dashboard
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch, defineExpose } from 'vue'
import { ChevronDownIcon, ChevronUpIcon, CheckIcon, ArrowTopRightOnSquareIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useAuthStore } from '@/stores/auth'
import { useRouter } from 'vue-router'
import { fetchOnboardingStatus } from '@/services/supabaseService'

const authStore = useAuthStore()
const router = useRouter()
const isExpanded = ref(false)
const triggerRef = ref(null)
const expandedPosition = ref({
  left: '0px',
  bottom: '0px',
  transformOrigin: 'bottom left'
})

const expandedStepIndex = ref(null);
const numberOfCalls = ref(0);

const updatePosition = () => {
  if (!triggerRef.value) return
  
  const rect = triggerRef.value.getBoundingClientRect()
  expandedPosition.value = {
    left: `${rect.left}px`,
    bottom: `${window.innerHeight - rect.bottom}px`,
    transformOrigin: 'bottom left'
  }
}

// Set the next incomplete step as expanded by default
const initializeExpandedStep = () => {
  const nextIncompleteIndex = steps.value.findIndex(step => !step.completed)
  expandedStepIndex.value = nextIncompleteIndex >= 0 ? nextIncompleteIndex : null
}

const toggleStep = (index) => {
  if (expandedStepIndex.value === index) {
    expandedStepIndex.value = null
  } else {
    expandedStepIndex.value = index
  }
}

const toggleExpanded = () => {
  if (!isExpanded.value) {
    updatePosition()
    initializeExpandedStep()
  }
  isExpanded.value = !isExpanded.value
  
  if (isExpanded.value) {
    window.addEventListener('resize', updatePosition)
    window.addEventListener('scroll', updatePosition)
  } else {
    window.removeEventListener('resize', updatePosition)
    window.removeEventListener('scroll', updatePosition)
  }
}

onUnmounted(() => {
  window.removeEventListener('resize', updatePosition)
  window.removeEventListener('scroll', updatePosition)
})

const steps = ref([
  {
    title: 'Setup a Sondar.ai account',
    description: 'Sign up for a Sondar account to start collecting feedback from your users.',
    completed: true,
    cta: null,
    ctaUrl: null
  },
  {
    title: 'Install the SDK',
    description: 'Integrate the Sondar SDK into your website or app to enable in-product surveys.',
    completed: false,
    cta: 'Install Now',
    ctaUrl: 'https://help.sondar.ai/en/collections/11537477-developer-documentation'
  },
  {
    title: 'Create a Survey',
    description: 'Design your survey using templates or custom questions to gather targeted feedback.',
    completed: false,
    cta: 'Create a Survey',
    ctaUrl: 'https://app.sondar.ai/'
  },
  {
    title: 'Launch a Survey',
    description: 'Publish the survey to your audience and start collecting user insights in real time.',
    completed: false,
    cta: 'Launch a Survey',
    ctaUrl: 'https://help.sondar.ai/en/articles/10454741-triggering-surveys'
  },
//   {
//     title: 'Review Survey Results',
//     description: 'Analyze responses with AI-powered insights to uncover trends and actionable opportunities.',
//     completed: false,
//     cta: 'Understanding Results',
//     ctaUrl: 'https://help.sondar.ai/en/articles/10497602-understanding-your-survey-results'
//   }
])

const totalSteps = computed(() => steps.value.length)
const completedSteps = computed(() => steps.value.filter(step => step.completed).length)
const nextStep = computed(() => steps.value.find(step => !step.completed))

const showCompletionModal = ref(false)
const isOnboardingHidden = ref(false)
const previousCompletionState = ref(false)

const closeCompletionModal = () => {
  showCompletionModal.value = false
  setOnboardingCompletedCookie();
}

const getOnboardingCookieKey = () => {
  return `onboarding_completed_${authStore.user.id}`
}

const checkOnboardingCookie = () => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(getOnboardingCookieKey()))
    ?.split('=')[1]
  
  return cookieValue === 'true'
}

const setOnboardingCompletedCookie = () => {
  const oneYear = 365 * 24 * 60 * 60 * 1000
  const expiryDate = new Date(Date.now() + oneYear).toUTCString()
  document.cookie = `${getOnboardingCookieKey()}=true; expires=${expiryDate}; path=/`
  isOnboardingHidden.value = true
}

const updateStepsCompletion = async () => {
  try {
    numberOfCalls.value++;
    const status = await fetchOnboardingStatus(authStore.tenantId)
    steps.value[0].completed = status.accountSetup
    steps.value[1].completed = status.sdkInstalled
    steps.value[2].completed = status.surveyCreated
    steps.value[3].completed = status.surveyPublished

    const currentCompletionState = steps.value.every(step => step.completed)
    
    // Show completion modal if all steps just got completed
    if (currentCompletionState && !previousCompletionState.value) {
        if(numberOfCalls.value > 1) {
            showCompletionModal.value = true
        } else {
            setOnboardingCompletedCookie();
        }
    }
    
    previousCompletionState.value = currentCompletionState
  } catch (error) {
    console.error('Error fetching onboarding status:', error)
  }
}

// Add polling interval reference
const pollingInterval = ref(null)

onMounted(() => {
  // Check cookie first
  if (checkOnboardingCookie()) {
    isOnboardingHidden.value = true
    return
  }

  // Check if user came from login page
  if (router.options.history.state.back === '/login') {
    isExpanded.value = true
    updatePosition()
    initializeExpandedStep()
  }

  // If not hidden by cookie, start normal flow
  updateStepsCompletion()
  pollingInterval.value = setInterval(updateStepsCompletion, 5000)
})

onUnmounted(() => {
  if (pollingInterval.value) {
    clearInterval(pollingInterval.value)
  }
})

const allStepsCompleted = computed(() => steps.value.every(step => step.completed))

// Watch for completion to stop polling
watch(allStepsCompleted, (newValue) => {
  if (newValue && pollingInterval.value) {
    clearInterval(pollingInterval.value)
  }
})

// Add collapse method
const collapse = () => {
  if (isExpanded.value) {
    isExpanded.value = false
    window.removeEventListener('resize', updatePosition)
    window.removeEventListener('scroll', updatePosition)
  }
}

// Expose the collapse method
defineExpose({
  collapse
})
</script>

<style scoped>
[class~="stroke-[3]"] {
  stroke-width: 3;
}

.h-auto {
  height: auto;
}

.h-0 {
  height: 0;
}
</style> 