<template>
  <div v-if="subscriptionStatus === null || subscriptionStatus === 'canceled'">
    <div class="bg-white py-4">
      <!-- Billing Period Selector -->
      <div class="flex flex-col items-center mb-8">
        <label class="block text-sm font-medium text-gray-500 mb-3">
          Billing Frequency
        </label>
        <div class="bg-gray-100 rounded-lg p-1 inline-flex">
          <div class="flex space-x-1">
            <template v-for="option in billingPeriods" :key="option.value">
              <label 
                :class="[
                  'relative flex cursor-pointer rounded-md px-3 py-2',
                  selectedBillingPeriod === option.value ? 'bg-white shadow-sm' : 'hover:bg-gray-50'
                ]"
              >
                <input 
                  type="radio" 
                  :value="option.value"
                  v-model="selectedBillingPeriod"
                  class="sr-only"
                  name="billing-period"
                >
                <span 
                  :class="[
                    'flex items-center',
                    selectedBillingPeriod === option.value ? 'text-indigo-600' : 'text-gray-700'
                  ]"
                >
                  <span class="text-sm font-medium">{{ option.label }}</span>
                </span>
              </label>
            </template>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
        <div v-for="plan in filteredPlans" 
            :key="`${plan.name}-${plan.billingPeriod}`"
            class="flex flex-col rounded-2xl bg-white p-8 ring-1 ring-gray-200 h-full relative transition-all duration-200 hover:ring-2 hover:ring-indigo-600 hover:shadow-lg">
          <div v-if="plan.popular" class="absolute top-3 right-3 rounded-full bg-indigo-100 px-3 py-1 text-sm font-semibold text-indigo-600">
            Popular
          </div>
          <div class="flex items-center gap-x-4">
            <h3 class="text-lg font-semibold leading-8 text-indigo-600">{{ plan.name }}</h3>
          </div>
          <p class="mt-4 text-sm leading-6 text-gray-600 h-16">{{ plan.description }}</p>
          <p class="mt-6 flex items-center gap-x-2">
            <span class="text-4xl font-bold tracking-tight text-gray-900">{{ plan.price }}</span>
            <span v-if="plan.price !== 'Let\'s Talk'" class="text-sm font-semibold leading-6 text-gray-600">/ month</span>
            <span 
              v-if="plan.save && plan.billingPeriod === 'yearly'" 
              class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-sm font-medium text-green-700"
            >
              Save {{ plan.save }}
            </span>
          </p>

          <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600 flex-grow">
            <li v-for="feature in plan.features" :key="feature" class="flex gap-x-3">
              <CheckCircleIcon class="h-6 w-6 flex-none text-indigo-600" />
              {{ feature }}
            </li>
          </ul>

          <div v-if="plan.callToAction.href">
            <a :href="plan.callToAction.href" target="_blank" rel="noopener noreferrer" class="block">
              <button class="w-full mt-8 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                {{ plan.callToAction.text }}
              </button>
            </a>
          </div>
          <button 
            v-else 
            @click="plan.callToAction.onClick"
            :disabled="loading"
            class="mt-8 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {{ plan.callToAction.text }}
          </button>

          <p v-if="error" class="mt-2 text-sm text-red-600">{{ error }}</p>
        </div>
      </div>
    </div>
  </div>

  <div v-if="subscriptionStatus !== null && subscriptionStatus !== 'canceled'">
      <button 
        type="submit" 
        @click="handleManageBilling" 
        :disabled="loading"
        class="w-64 mt-8 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed">
        {{ loading ? 'Loading...' : 'Manage Billing' }}
      </button>
  </div>
</template>

<script setup>
import { CheckCircleIcon } from '@heroicons/vue/24/outline'
import { ref, computed, onMounted } from 'vue'
import { useAuthStore } from '@/stores/auth'
import supabase from '@/supabase'

const auth = useAuthStore()
const tenant = ref({})
const stripe_cust_id = ref(null)
const subscriptionStatus = ref(null)
const loading = ref(false)
const error = ref(null)


const billingPeriods = [
  { value: 'monthly', label: 'Monthly' },
  { value: 'yearly', label: 'Yearly' }
]

const selectedBillingPeriod = ref('yearly')

const filteredPlans = computed(() => {
  return subscriptionPlans.filter(plan => plan.billingPeriod === selectedBillingPeriod.value)
})

const subscriptionPlans = [
  {
    name: 'Essentials',
    description: 'Ideal for getting started with surveys.',
    price: '$65',
    save: '$240',
    billingPeriod: 'yearly',
    popular: false,
    features: [
      'Unlimited surveys',
      '50 Survey responses / mth',
      '10k MAU for in-product surveys',
      'Unlimited seats',
      'In-Product Surveys',
      'Sharable survey links',
      '50+ survey templates',
      'AI generated follow-up questions',
      'Advanced survey triggers',
      'User segmentation',
      'Email support'
    ],
    callToAction: {
      text: loading.value ? 'Loading...' : 'Subscribe',
      onClick: () => handleSubscribe(process.env.VUE_APP_STRIPE_PLAN_ESSENTIALS_YEARLY)
    }
  },
  {
    name: 'Essentials',
    description: 'Ideal for getting started with surveys.',
    price: '$85',
    billingPeriod: 'monthly',
    popular: false,
    features: [
      'Unlimited surveys',
      '50 Survey responses / mth',
      '10k MAU for in-product surveys',
      'Unlimited seats',
      'In-Product Surveys',
      'Sharable survey links',
      '50+ survey templates',
      'AI generated follow-up questions',
      'Advanced survey triggers',
      'User segmentation',
      'Email support'
    ],
    callToAction: {
      text: loading.value ? 'Loading...' : 'Subscribe',
      onClick: () => handleSubscribe(process.env.VUE_APP_STRIPE_PLAN_ESSENTIALS_MONTHLY)
    }
  },
  {
    name: 'Growth',
    description: 'Perfect for teams looking to accelerate user research and insights.',
    price: '$125',
    save: '$480',
    billingPeriod: 'yearly',
    popular: true,
    features: [
      'Everything from Essentials plus...',
      '200 Survey responses / mth',
      '25k MAU for in-product surveys',
      'AI Analysis of responses',
      'Dedicated onboarding specialist',
      'Chat + Email support'
    ],
    callToAction: {
      text: loading.value ? 'Loading...' : 'Subscribe',
      onClick: () => handleSubscribe(process.env.VUE_APP_STRIPE_PLAN_GROWTH_YEARLY)
    }
  },
  {
    name: 'Growth',
    description: 'Perfect for teams looking to accelerate user research and insights.',
    price: '$165',
    billingPeriod: 'monthly',
    popular: true,
    features: [
      'Everything from Essentials plus...',
      '200 Survey responses / mth',
      '25k MAU for in-product surveys',
      'AI Analysis of responses',
      'Dedicated onboarding specialist',
      'Chat + Email support'
    ],
    callToAction: {
      text: loading.value ? 'Loading...' : 'Subscribe',
      onClick: () => handleSubscribe(process.env.VUE_APP_STRIPE_PLAN_GROWTH_MONTHLY)
    }
  },
  {
    name: 'Enterprise',
    description: 'Perfect for large teams looking to scale user research and insights.',
    price: 'Let\'s Talk',
    billingPeriod: 'monthly',
    popular: false,
    features: [
      'Everything from Growth plus...',
      'Custom response limits',
      'Custom MAU limits',
      'Mobile app surveys',
      'SSO',
      'Full brand & design customization',
      'Pay by invoice',
      'Dedicated account manager',
      'SSO & custom branding'
    ],
    callToAction: {
      text: loading.value ? 'Loading...' : 'Schedule a Call',
      href: 'https://tidycal.com/lixia/sondar-demo-call',
      onClick: () => handleSubscribe(null)
    }
  },
  {
    name: 'Enterprise',
    description: 'Perfect for large teams looking to scale user research and insights.',
    price: 'Let\'s Talk',
    billingPeriod: 'yearly',
    popular: false,
    features: [
      'Everything from Growth plus...',
      'Custom response limits',
      'Custom MAU limits',
      'Mobile app surveys',
      'SSO',
      'Full brand & design customization',
      'Pay by invoice',
      'Dedicated account manager',
      'SSO & custom branding'
    ],
    callToAction: {
      text: loading.value ? 'Loading...' : 'Schedule a Call',
      href: 'https://tidycal.com/lixia/sondar-demo-call',
      onClick: () => handleSubscribe(null)
    }
  },
]

onMounted(() => {
  fetchTenant()
})

async function fetchTenant() {
  loading.value = true
  error.value = null

  try {
    if (!supabase) {
      throw new Error('Supabase client is not initialized')
    }

    // Fetch tenant data
    const { data, error: fetchError } = await supabase
      .from('tenants')
      .select('*')
      .eq('id', auth.tenantId)
      .single()

    if (fetchError) {
      throw fetchError
    }
    tenant.value = data

    // Fetch stripe_cust_id from billing table
    const { data: billingData, error: billingError } = await supabase
      .from('billing')
      .select('stripe_cust_id, subscription_status')
      .eq('tenant', auth.tenantId)
      .single()

    if (billingError && billingError.code !== 'PGRST116') { // Ignore "not found" errors
      throw billingError
    }
    
    if (billingData) {
      stripe_cust_id.value = billingData.stripe_cust_id
      subscriptionStatus.value = billingData.subscription_status
    }
  } catch(err) {
    error.value = 'Failed to fetch tenant information. Please try again.'
    console.error('Fetch tenant error:', err)
  } finally {
    loading.value = false
  }
}

async function handleSubscribe(priceId) {
  if (!priceId) return
  
  loading.value = true
  error.value = null
  
  try {
    const response = await fetch(`${process.env.VUE_APP_SUPABASE_EF_BASE_URL}/stripe-checkout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.VUE_APP_SUPABASE_KEY}`
      },
      body: JSON.stringify({
        priceId,
        customerId: stripe_cust_id.value,
        successUrl: `${window.location.origin}/stripe/checkout_success`,
        cancelUrl: `${window.location.origin}/tenant-settings/`
      })
    })

    if (!response.ok) {
      throw new Error('Failed to initiate checkout')
    }

    const data = await response.json()
    window.location.href = data.url
    
  } catch (err) {
    error.value = 'Failed to initiate checkout. Please try again.'
    console.error('Checkout error:', err)
  } finally {
    loading.value = false
  }
}

async function handleManageBilling() {
  loading.value = true

  try {
    const response = await fetch(`${process.env.VUE_APP_SUPABASE_EF_BASE_URL}/stripe-cust-portal`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.VUE_APP_SUPABASE_KEY}`
      },
      body: JSON.stringify({
        stripeCustomerId: stripe_cust_id.value,
      })
    })

    if (!response.ok) {
      throw new Error("Failed to initiate customer portal");
      
    }

    const data = await response.json()
    window.location.href = data.url

  } catch (error) {
    console.error('Customer Portal error:', error)
  }
}
</script>

<style scoped>

</style>
