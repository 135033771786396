<template>
  <div class="p-6">
    <!-- Loading State -->
    <div v-if="isLoading">
      <div class="animate-pulse space-y-4">
        <div v-for="i in 4" :key="i" class="bg-white rounded-lg shadow-sm p-6">
          <div class="flex items-center justify-between">
            <div class="space-y-3 flex-1">
              <div class="h-4 bg-gray-200 rounded w-1/4"></div>
              <div class="h-3 bg-gray-200 rounded w-1/6"></div>
            </div>
            <div class="h-8 w-8 bg-gray-200 rounded-full"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Empty State -->
    <div v-else-if="analyses.length === 0" class="text-center bg-white rounded-lg shadow-sm py-12 px-6">
      <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
        <svg class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
      </div>
      <h3 class="mt-4 text-sm font-semibold text-gray-900">No analysis data</h3>
      <p class="mt-2 text-sm text-gray-500">Analysis data will appear here once the AI processes your survey responses.
      </p>
    </div>

    <!-- Analysis Cards -->
    <div v-else class="space-y-6">
      <div v-for="analysis in analyses" :key="analysis.id"
        :class="[
          'relative group bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-200 border border-gray-200 overflow-hidden',
          analysis.status.toLowerCase() === 'processing' ? 'shimmer-effect' : ''
        ]">
        <div class="py-4  ">
          <!-- Header Row -->
          <div class="flex flex-col sm:flex-row sm:items-center gap-4 pb-4 px-4 border-b border-gray-100">
            <!-- Title and Status -->
            <div class="flex-1 min-w-0">
              <div class="flex items-center gap-3">
                <h3 class="text-medium font-semibold text-gray-900 truncate">{{ analysis.title }}</h3>
                <span v-if="analysis.status.toLowerCase() !== 'completed'" :class="getStatusClass(analysis.status)"
                  class="inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ring-1 ring-inset whitespace-nowrap">
                  {{ analysis.status }}
                </span>
              </div>
            </div>

            <!-- Action Button -->
            <div class="flex-shrink-0">
              <button @click="viewReport(analysis.id)" :disabled="analysis.status.toLowerCase() !== 'completed'" :class="[
                'inline-flex items-center px-4 py-2 text-sm font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-150 shadow-sm w-full sm:w-auto justify-center',
                analysis.status.toLowerCase() === 'completed'
                  ? 'text-white bg-indigo-600 hover:bg-indigo-500 focus:ring-indigo-500'
                  : 'text-gray-400 bg-gray-100 cursor-not-allowed'
              ]">
                View Report
                <svg class="ml-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </div>

          <!-- Stats Grid -->
          <div class="grid grid-cols-1 sm:grid-cols-4 px-4 gap-6 pt-6">
            <!-- Responses -->
            <div class="flex items-center gap-4">
              <div class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-lg bg-indigo-50">
                <svg class="h-5 w-5 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
              </div>
              <div class="min-w-0">
                <p class="text-sm font-medium text-gray-500 text-left truncate">Responses</p>
                <p class="mt-1 text-medium font-semibold text-left text-gray-900">{{ analysis.responseCount }}</p>
              </div>
            </div>

            <!-- Date Range -->
            <div class="flex items-center gap-4">
              <div class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-lg bg-yellow-50">
                <svg class="h-5 w-5 text-yellow-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
              </div>
              <div class="min-w-0">
                <p class="text-sm font-medium text-gray-500 text-left truncate">Date Range</p>
                <p class="mt-1 text-sm font-semibold text-left text-gray-900">{{ formatDateRange(analysis.dateRange) }}
                </p>
              </div>
            </div>

            <!-- Created By -->
            <div class="flex items-center gap-4">
              <div class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-lg bg-green-50">
                <svg class="h-5 w-5 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </div>
              <div class="min-w-0">
                <p class="text-sm font-medium text-gray-500 text-left truncate">Created By</p>
                <p class="mt-1 text-sm font-semibold text-left text-gray-900">{{ analysis.createdBy }}</p>
              </div>
            </div>

            <!-- Created At -->
            <div class="flex items-center gap-4">
              <div class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-lg bg-purple-50">
                <svg class="h-5 w-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <div class="min-w-0">
                <p class="text-sm font-medium text-gray-500 text-left truncate">Created At</p>
                <p class="mt-1 text-sm font-semibold text-left text-gray-900">{{ getLocalDateTimeString(analysis.createdAt) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { getLocalDateTimeString } from '@/utils';
import { useRoute } from 'vue-router'
import { fetchAnalysisReports } from '@/services/supabaseService';

export default {
  name: 'AIAnalysis',
  setup() {
    const isLoading = ref(true);
    const route = useRoute()
    const survey_id = ref(route.params.survey_id)
    const analyses = ref([]);

    const getStatusClass = (status) => {
      const classes = {
        completed: 'bg-green-50 text-green-700 ring-green-600/20',
        processing: 'bg-blue-50 text-blue-700 ring-blue-600/20',
        failed: 'bg-red-50 text-red-700 ring-red-600/20',
        pending: 'bg-yellow-50 text-yellow-700 ring-yellow-600/20'
      };
      return classes[status.toLowerCase()] || 'bg-gray-50 text-gray-700 ring-gray-600/20';
    };

    const formatDate = (date) => {
      return new Date(date).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
    };

    const formatDateRange = (dateRange) => {
      if (!dateRange) return 'N/A';

      try {
        const [start, end] = dateRange.split(' to ').map(date => new Date(date));
        return `${formatDate(start)} to ${formatDate(end)}`;
      } catch (error) {
        console.error('Error formatting date range:', error);
        return dateRange || 'N/A';
      }
    };

    const viewReport = (id) => {
      window.open(`/view-report/${id}?survey_id=${survey_id.value}`, '_blank');
    };

    const transformReports = (reports) => {
      return reports.map(report => ({
        id: report.id,
        title: report.title || 'Survey Analysis',
        responseCount: report.responses || 0,
        dateRange: report.start_date && report.end_date
          ? `${formatDate(new Date(report.start_date))} to ${formatDate(new Date(report.end_date))}`
          : null,
        status: report.status || 'Pending',
        createdAt: report.created_at,
        createdBy: report.users?.name || 'AI System'
      }));
    };

    const isAllComplete = (reports) => {
      return reports.every(report => 
        report.status === 'Completed' || report.status === 'Failed'
      );
    };

    let pollingInterval = null;

    const startPolling = async () => {
      try {
        const reports = await fetchAnalysisReports(survey_id.value);
        const transformedReports = transformReports(reports);
        analyses.value = transformedReports;

        // If all reports are complete, no need to start polling
        if (isAllComplete(reports)) {
          return;
        }

        // Start polling every 3 seconds
        pollingInterval = setInterval(async () => {
          try {
            const newReports = await fetchAnalysisReports(survey_id.value);
            const transformedNewReports = transformReports(newReports);
            
            // Only update if there are changes
            if (JSON.stringify(transformedNewReports) !== JSON.stringify(analyses.value)) {
              analyses.value = transformedNewReports;
            }

            // Stop polling if all reports are complete
            if (isAllComplete(newReports)) {
              clearInterval(pollingInterval);
            }
          } catch (error) {
            console.error('Error polling reports:', error);
            // Don't stop polling on error, let it continue
          }
        }, 3000);
      } catch (error) {
        console.error('Error fetching analyses:', error);
        analyses.value = [];
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      isLoading.value = true;
      startPolling();
    });

    onUnmounted(() => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    });

    return {
      isLoading,
      route,
      survey_id,
      analyses,
      getStatusClass,
      formatDate,
      formatDateRange,
      viewReport,
      getLocalDateTimeString
    };
  }
};
</script>

<style scoped>
.shimmer-effect {
  position: relative;
  overflow: hidden;
  background: rgba(249, 250, 251, 0.8);
}

.shimmer-effect::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.3) 20%,
    rgba(255, 255, 255, 0.8) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 1.5s infinite linear;
  content: '';
  pointer-events: none;
  z-index: 1;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>