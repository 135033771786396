<template>
  <div class="p-6 max-w-4xl mx-auto w-full">
    <GlobalNotification
      :key="ok"
      v-if="showSuccessNotification"
      :type="'ok'"
      :message="'Tenant invited successfully'"
      @close="showSuccessNotification = false"
    />
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-bold">Tenants</h2>
      <button
        type="button"
        class="btn-primary-sondar"
        @click="showPopup = true"
      >
        New Tenant
      </button>
    </div>

    <!-- Loading State -->
    <div v-if="isLoading">
      <div class="animate-pulse space-y-4 p-4">
        <div
          class="flex items-center justify-between rounded bg-gray-100 p-4 shadow"
        >
          <div class="space-y-2">
            <div class="h-4 w-1/3 rounded bg-gray-300"></div>
            <div class="h-3 w-1/2 rounded bg-gray-300"></div>
          </div>
          <div class="h-6 w-6 rounded bg-gray-300"></div>
        </div>

        <div
          class="flex items-center justify-between rounded bg-gray-100 p-4 shadow"
        >
          <div class="space-y-2">
            <div class="h-4 w-1/3 rounded bg-gray-300"></div>
            <div class="h-3 w-1/2 rounded bg-gray-300"></div>
          </div>
          <div class="h-6 w-6 rounded bg-gray-300"></div>
        </div>

        <div
          class="flex items-center justify-between rounded bg-gray-100 p-4 shadow"
        >
          <div class="space-y-2">
            <div class="h-4 w-1/3 rounded bg-gray-300"></div>
            <div class="h-3 w-1/2 rounded bg-gray-300"></div>
          </div>
          <div class="h-6 w-6 rounded bg-gray-300"></div>
        </div>

        <div
          class="flex items-center justify-between rounded bg-gray-100 p-4 shadow"
        >
          <div class="space-y-2">
            <div class="h-4 w-1/3 rounded bg-gray-300"></div>
            <div class="h-3 w-1/2 rounded bg-gray-300"></div>
            <div class="h-3 w-1/3 rounded bg-gray-300"></div>
          </div>
          <div class="h-6 w-6 rounded bg-gray-300"></div>
        </div>
      </div>
    </div>

    <!-- Tenants List -->
    <div v-if="!isLoading">
      <div v-if="tenants.length === 0" class="text-center py-4 mt-10 bg-gray-100 rounded-lg shadow">
        <p class="text-gray-700 font-medium">There are no tenants. Add one now!</p>
      </div>

      <div v-else class="overflow-hidden">
        <div class="mt-8 flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Name</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
                    <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Actions</th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                  <tr v-for="(tenant, index) in paginatedTenants" :key="index" class="hover:bg-gray-50">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-left text-gray-900 sm:pl-0">
                      {{ tenant.name }}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-left text-sm text-gray-500">
                      {{ formatDate(tenant.created_at) }}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-right text-sm">
                      <button
                        type="button"
                        class="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        @click="deleteTenant(tenant.id)"
                      >
                        <TrashIcon class="h-5 w-5" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <div v-if="!isLoading && tenants.length > itemsPerPage" class="mt-4">
      <div class="flex flex-1 justify-between sm:hidden">
        <button 
          @click="previousPage" 
          :disabled="currentPage === 1"
          class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Previous
        </button>
        <button 
          @click="nextPage" 
          :disabled="currentPage === totalPages"
          class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </div>
      <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p class="text-sm text-gray-700">
            Showing
            <span class="font-medium">{{ ((currentPage - 1) * itemsPerPage) + 1 }}</span>
            to
            <span class="font-medium">{{ Math.min(currentPage * itemsPerPage, tenants.length) }}</span>
            of
            <span class="font-medium">{{ tenants.length }}</span>
            results
          </p>
        </div>
        <div>
          <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button 
              @click="previousPage" 
              :disabled="currentPage === 1"
              class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <span class="sr-only">Previous</span>
              <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
            </button>

            <button 
              v-for="page in totalPages" 
              :key="page" 
              @click="currentPage = page"
              :class="[
                page === currentPage
                  ? 'relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  : 'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
              ]"
            >
              {{ page }}
            </button>

            <button 
              @click="nextPage" 
              :disabled="currentPage === totalPages"
              class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <span class="sr-only">Next</span>
              <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>

    <!-- New Tenant Modal -->
    <div v-if="showPopup" class="relative z-50">
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <div
            class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
          >
            <div>
              <h3 class="text-base font-semibold leading-6 text-gray-900">
                Invite a New Tenant
              </h3>
              <div class="py-4">
                <div class="mb-4">
                  <label
                    for="tenant-name"
                    class="block text-left text-sm font-medium leading-6 text-gray-900"
                  >
                    Organization Name
                  </label>
                  <div class="mt-2">
                    <input
                      id="tenant-name"
                      v-model="newTenantName"
                      type="text"
                      placeholder="e.g. Acme Corp"
                      @blur="validateOrganizationName"
                      class="input-sondar"
                    />
                    <p v-if="organizationNameError" class="mt-2 text-sm text-red-600">
                      {{ organizationNameError }}
                    </p>
                  </div>
                </div>

                <div class="mb-4">
                  <label
                    for="admin-name"
                    class="block text-left text-sm font-medium leading-6 text-gray-900"
                  >
                    Admin Name
                  </label>
                  <div class="mt-2">
                    <input
                      id="admin-name"
                      v-model="newAdminName"
                      type="text"
                      placeholder="e.g. John Doe"
                      @blur="validateAdminName"
                      class="input-sondar"
                    />
                    <p v-if="adminNameError" class="mt-2 text-sm text-red-600">
                      {{ adminNameError }}
                    </p>
                  </div>
                </div>

                <div class="mb-4">
                  <label
                    for="email"
                    class="block text-sm font-medium leading-6 text-left text-gray-900"
                  >
                    Admin Email
                  </label>
                  <div class="mt-2">
                    <input
                      id="email"
                      v-model="newEmail"
                      type="email"
                      placeholder="e.g. john@acmecorp.com"
                      @blur="validateEmail"
                      class="input-sondar"
                    />
                    <p v-if="adminEmailError" class="mt-2 text-sm text-red-600">
                      {{ adminEmailError }}
                    </p>
                  </div>
                </div>

                <p v-if="errorMessage" class="mt-2 text-sm text-red-600">
                  {{ errorMessage }}
                </p>
              </div>
            </div>

            <div
              class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"
            >
              <button
                type="button"
                @click="closeModal()"
                class="btn-secondary-sondar"
              >
                Cancel
              </button>
              <button
                type="button"
                @click="createEntity"
                :disabled="isCreating || !isFormValid"
                class="btn-primary-sondar"
                :class="{ 'opacity-50 cursor-not-allowed': !isFormValid }"
              >
                <template v-if="isCreating">
                  <div
                    class="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mx-auto"
                  ></div>
                </template>
                <template v-else>Invite</template>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue'
import { TrashIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline"
import GlobalNotification from "@/components/commons/GlobalNotification.vue"
import { 
  fetchTenants as fetchTenantsService,
  checkExistingUser,
  createTenant,
  inviteUser,
  createUserProfile,
  deleteTenant as deleteTenantService
} from "@/services/supabaseService"

export default {
  name: 'AdminSettings',
  components: {
    TrashIcon,
    GlobalNotification,
    ChevronLeftIcon,
    ChevronRightIcon
  },
  setup() {
    // Reactive state
    const tenants = ref([])
    const isLoading = ref(true)
    const showPopup = ref(false)
    const newTenantName = ref("")
    const newEmail = ref("")
    const newAdminName = ref("")
    const organizationNameError = ref("")
    const adminNameError = ref("")
    const adminEmailError = ref("")
    const showSuccessNotification = ref(false)
    const errorMessage = ref("")
    const itemsPerPage = ref(10)
    const currentPage = ref(1)
    const isCreating = ref(false)

    // Computed properties
    const paginatedTenants = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value
      return tenants.value.slice(start, start + itemsPerPage.value)
    })

    const totalPages = computed(() => {
      return Math.ceil(tenants.value.length / itemsPerPage.value)
    })

    const isFormValid = computed(() => {
      const isEmailValid = newEmail.value && isValidEmail(newEmail.value)
      const isNameValid = newAdminName.value && newAdminName.value.length >= 2
      const isOrgValid = newTenantName.value && newTenantName.value.length >= 2
      
      return isEmailValid && isNameValid && isOrgValid
    })

    // Methods
    const fetchTenants = async () => {
      isLoading.value = true
      try {
        tenants.value = await fetchTenantsService()
      } catch (error) {
        console.error("Error fetching tenants:", error)
      }
      isLoading.value = false
    }

    const formatDate = (date) => {
      return new Date(date).toLocaleDateString()
    }

    const closeModal = () => {
      newTenantName.value = ""
      newEmail.value = ""
      newAdminName.value = ""
      organizationNameError.value = ""
      adminNameError.value = ""
      adminEmailError.value = ""
      errorMessage.value = ""
      showPopup.value = false
    }

    const generateRandomKey = (length = 40) => {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      const charactersLength = characters.length
      return [...Array(length)]
        .map(() => characters[(Math.random() * charactersLength) | 0])
        .join("")
    }

    const validateOrganizationName = () => {
      organizationNameError.value = ""
      if (!newTenantName.value) {
        organizationNameError.value = "Organization name is required"
      } else if (newTenantName.value.length < 2) {
        organizationNameError.value = "Organization name must be at least 2 characters"
      }
    }

    const validateAdminName = () => {
      adminNameError.value = ""
      if (!newAdminName.value) {
        adminNameError.value = "Admin name is required"
      } else if (newAdminName.value.length < 2) {
        adminNameError.value = "Admin name must be at least 2 characters"
      }
    }

    const validateEmail = () => {
      adminEmailError.value = ""
      if (!newEmail.value) {
        adminEmailError.value = "Email is required"
      } else if (!isValidEmail(newEmail.value)) {
        adminEmailError.value = "Please enter a valid email address"
      }
    }

    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(email)
    }

    const createEntity = async () => {
      errorMessage.value = ""
      organizationNameError.value = ""
      adminNameError.value = ""
      adminEmailError.value = ""
      isCreating.value = true

      try {
        let hasError = false

        // Validations
        validateOrganizationName()
        validateAdminName()
        validateEmail()

        if (organizationNameError.value || adminNameError.value || adminEmailError.value) {
          hasError = true
        }

        if (hasError) {
          return
        }

        // Check if email already exists
        const existingUser = await checkExistingUser(newEmail.value)
        if (existingUser) {
          adminEmailError.value = "Cannot invite this user. This email is already registered."
          return
        }

        // Create tenant
        const tenantData = await createTenant(newTenantName.value, generateRandomKey())

        // Invite user
        const redirectUrl = `${window.location.protocol}//${window.location.host}/reset-password`
        const userData = await inviteUser(newEmail.value, redirectUrl)

        // Create user profile
        await createUserProfile(
          userData.user.id,
          newEmail.value,
          newAdminName.value,
          2, // tenant_admin role
          tenantData.id
        )

        closeModal()
        showSuccessNotification.value = true
        await fetchTenants()
        setTimeout(() => {
          showSuccessNotification.value = false
        }, 4000)

      } catch (error) {
        errorMessage.value = error.message
      } finally {
        isCreating.value = false
      }
    }

    const deleteTenant = async (id) => {
      try {
        await deleteTenantService(id)
        await fetchTenants()
      } catch (error) {
        console.error("Error deleting tenant:", error)
      }
    }

    // Watchers
    watch(newTenantName, validateOrganizationName)
    watch(newAdminName, validateAdminName)
    watch(newEmail, validateEmail)

    // Lifecycle hooks
    onMounted(fetchTenants)

    // Return all refs, computed properties, and methods that are used in the template
    return {
      tenants,
      isLoading,
      showPopup,
      newTenantName,
      newEmail,
      newAdminName,
      organizationNameError,
      adminNameError,
      adminEmailError,
      showSuccessNotification,
      errorMessage,
      itemsPerPage,
      currentPage,
      isCreating,
      paginatedTenants,
      totalPages,
      isFormValid,
      fetchTenants,
      formatDate,
      closeModal,
      generateRandomKey,
      validateOrganizationName,
      validateAdminName,
      validateEmail,
      isValidEmail,
      createEntity,
      deleteTenant
    }
  }
}
</script>

<style scoped></style>
