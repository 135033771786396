<template>
  <div class="text-left">
    <!-- Notification Component -->
    <GlobalNotification :key="copied" v-if="showCopiedNotification" :type="'ok'"
      :message="'Code snippet copied to clipboard'" />

    <!-- Loading -->
    <div v-if="isLoading">
      <div class="animate-pulse space-y-6 p-4">
        <!-- Trigger Modes Section -->
        <div class="space-y-4">
          <div class="h-6 w-1/4 rounded bg-gray-300"></div>
          <div class="flex justify-between space-x-4">
            <div class="h-10 w-1/3 rounded bg-gray-300"></div>
            <div class="h-10 w-1/3 rounded bg-gray-300"></div>
            <div class="h-10 w-1/3 rounded bg-gray-300"></div>
          </div>
        </div>

        <!-- URL Input Section -->
        <div class="flex space-x-4">
          <div class="h-10 w-1/6 rounded bg-gray-300"></div>
          <div class="h-10 flex-grow rounded bg-gray-300"></div>
        </div>

        <!-- User Attributes Section -->
        <div class="space-y-4">
          <div class="h-6 w-1/4 rounded bg-gray-300"></div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <div class="h-6 w-1/2 rounded bg-gray-300"></div>
              <div class="mt-2 h-10 w-full rounded bg-gray-300"></div>
            </div>
            <div class="flex-1">
              <div class="h-6 w-1/2 rounded bg-gray-300"></div>
              <div class="mt-2 h-10 w-full rounded bg-gray-300"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="animate-pulse space-y-6 p-4">
        <!-- Trigger Modes Section -->
        <div class="space-y-4">
          <div class="h-6 w-1/4 rounded bg-gray-300"></div>
          <div class="flex justify-between space-x-4">
            <div class="h-10 w-1/3 rounded bg-gray-300"></div>
            <div class="h-10 w-1/3 rounded bg-gray-300"></div>
            <div class="h-10 w-1/3 rounded bg-gray-300"></div>
          </div>
        </div>

        <!-- URL Input Section -->
        <div class="flex space-x-4">
          <div class="h-10 w-1/6 rounded bg-gray-300"></div>
          <div class="h-10 flex-grow rounded bg-gray-300"></div>
        </div>

        <!-- User Attributes Section -->
        <div class="space-y-4">
          <div class="h-6 w-1/4 rounded bg-gray-300"></div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <div class="h-6 w-1/2 rounded bg-gray-300"></div>
              <div class="mt-2 h-10 w-full rounded bg-gray-300"></div>
            </div>
            <div class="flex-1">
              <div class="h-6 w-1/2 rounded bg-gray-300"></div>
              <div class="mt-2 h-10 w-full rounded bg-gray-300"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isLoading" class="p-6 space-y-6">
      <!-- Distribution Channel Cards -->
      <div class="grid grid-cols-4 gap-5 pt-5 pb-5 border-b border-gray-200">
        <!-- Left Column -->
        <div class="col-span-1">
          <h2 class="text-base/7 font-semibold text-gray-900">
            Distribution Channel
          </h2>
          <p class="mt-1 text-sm/6 text-gray-600">
            Where this survey is available
          </p>
        </div>

        <!-- Right Column -->
        <div class="col-span-3">
          <ChannelSelection v-model="distributionChannel" />
        </div>
      </div>

      <!-- Context Field -->
      <div class="grid grid-cols-4 gap-5 pt-5 pb-10 border-b border-gray-200">
        <div class="flex flex-col mt-3">
          <label for="context" class="block text-medium text-left font-semibold text-gray-900">Context
            (Optional)</label>
          <span class="text-sm text-left font-medium text-gray-500">Provide AI with context to increase relevance of <a
              href="https://help.sondar.ai/en/articles/10472882-ai-generated-follow-up-questions" target="_blank"
              class="underline hover:text-gray-900">AI generated follow-up questions.</a></span>
        </div>
        <div class="col-span-3 mt-2">
          <textarea id="context" v-model="context" rows="3" @input="debounceUpdateContext"
            class="block w-full rounded-lg border border-gray-300 p-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="e.g. Product: Accounting Software. Target Market: Small Businesses."></textarea>
        </div>
      </div>
      <!-- ======================= -->
      <!-- ======================= -->
      <!-- Triggers Section       -->
      <!-- ======================= -->
      <!-- ======================= -->
      <div v-if="distributionChannel.toLowerCase() !== 'sharable link'"
        class="grid grid-cols-4 gap-5 pt-5 pb-10 border-b border-gray-200">
        <!-- Left Column -->
        <div class="col-span-1">
          <h2 class="text-base/7 font-semibold text-gray-900">Triggers</h2>
          <p class="mt-1 text-sm/6 text-gray-600">
            Define an action that will show this survey to your users
          </p>
        </div>

        <!-- Right Column -->
        <div class="col-span-3 space-y-4">
          <div class="flex space-x-4">
            <div @click="triggerSettings.type = 'page_visit'" :class="[
              triggerSettings.type === 'page_visit'
                ? 'ring-2 ring-indigo-600 bg-indigo-50'
                : 'bg-white',
              'group relative flex cursor-pointer rounded-lg text-left border border-gray-300 px-2 py-4 shadow-xs hover:border-indigo-600 transition-all duration-200'
            ]">
              <heroicon-outline-globe-alt class="w-12 h-6 text-gray-900" />
              <div class="ml-1.5 mt-0.5">
                <h4 class="block text-sm font-semibold text-gray-900">
                  Page Visit
                </h4>
                <p class="text-sm text-gray-500 mt-1">
                  Show a survey when a user visits a specific web page
                </p>
              </div>
            </div>

            <div @click="triggerSettings.type = 'tracked_event'" :class="[
              triggerSettings.type === 'tracked_event'
                ? 'ring-2 ring-indigo-600 bg-indigo-50'
                : 'bg-white',
              'group relative flex cursor-pointer rounded-lg text-left border border-gray-300 px-2 py-4 shadow-xs hover:border-indigo-600 transition-all duration-200'
            ]">
              <heroicon-outline-cursor-arrow-rays class="w-12 h-6 text-gray-900" />
              <div class="ml-1.5 mt-0.5">
                <h4 class="block text-sm font-semibold text-gray-900">
                  Tracked Event
                </h4>
                <p class="text-sm text-gray-500 mt-1">
                  Show a survey when a user performs a specific action
                </p>
              </div>
            </div>

            <div @click="triggerSettings.type = 'manual'" :class="[
              triggerSettings.type === 'manual'
                ? 'ring-2 ring-indigo-600 bg-indigo-50'
                : 'bg-white',
              'group relative flex cursor-pointer rounded-lg text-left border border-gray-300 px-2 py-4 shadow-xs hover:border-indigo-600 transition-all duration-200'
            ]">
              <heroicon-outline-code-bracket-square class="w-12 h-6 text-gray-900" />
              <div class="ml-1.5 mt-0.5">
                <h4 class="block text-sm font-semibold text-gray-900">
                  Manual
                </h4>
                <p class="text-sm text-gray-500 mt-1">
                  Show a survey by calling a method directly in the SDK
                </p>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <legend v-if="triggerSettings.type !== 'manual'" class="mb-2 text-sm text-left font-semibold text-gray-900">
              Conditions
            </legend>
            <legend v-if="triggerSettings.type === 'manual'" class="mb-2 text-sm text-left font-semibold text-gray-900">
              Code Snippet
            </legend>
            <div class="flex flex-col" v-if="triggerSettings.type === 'page_visit'">
              <div class="flex items-center space-x-2">
                <select v-model="triggerSettings.page_visit.selected"
                  class="rounded-md cursor-default bg-white p-1.5 text-left text-sm text-gray-900 border border-gray-300 focus:border-indigo-600 focus:border-2">
                  <option v-for="option in triggerSettings.page_visit.options" :value="option.value" :key="option">
                    {{ option.label }}
                  </option>
                </select>
                <input v-model="triggerSettings.page_visit.value" type="text" placeholder="https://myapp.com/profile"
                  :required="triggerSettings.page_visit.selected !== 'any'"
                  :disabled="triggerSettings.page_visit.selected === 'any'" :class="[
                    'w-full rounded-md p-1.5 text-sm border focus:outline-none focus:border-2 focus:border-indigo-600',
                    triggerSettings.page_visit.selected === 'any'
                      ? 'bg-gray-100 border-gray-200 text-gray-500'
                      : 'border-gray-300',
                    triggerSettings.page_visit.selected !== 'any' &&
                      !triggerSettings.page_visit.value
                      ? 'border-red-500 border-2'
                      : 'border-gray-300',
                  ]" />
              </div>
              <span v-if="
                triggerSettings.page_visit.selected !== 'any' &&
                !triggerSettings.page_visit.value
              " class="mt-2 text-sm font-medium text-red-700 validation-error text-left ml-32">This field is
                required.</span>
            </div>

            <div class="flex flex-col" v-if="triggerSettings.type === 'tracked_event'">
              <div class="space-y-4">
                <select v-model="triggerSettings.tracked_events.selected[0].event"
                  class="w-full rounded-md cursor-default p-1.5 text-left text-sm text-gray-900 border border-gray-300 focus:border-indigo-600 focus:border-2"
                  :class="[
                    !triggerSettings.tracked_events.selected[0].event
                      ? 'border-red-500'
                      : 'border-gray-300',
                  ]">
                  <option value="" disabled selected>Select event</option>
                  <option v-for="option in triggerSettings.tracked_events.options" :value="option.name" :key="option">
                    {{ option.name }}
                  </option>
                </select>
                <span v-if="
                  !triggerSettings.tracked_events.selected[0].event ||
                  !triggerSettings.tracked_events.selected[0].value
                " class="text-left mt-3 text-red-700 validation-error text-sm font-medium">This field is
                  required.</span>
                <div class="flex items-center space-x-2">
                  <span class="text-sm text-gray-900">Show this survey when above event has occurred</span>
                  <input type="number" min="1" value="1" v-model="triggerSettings.tracked_events.selected[0].value"
                    :class="[
                      !triggerSettings.tracked_events.selected[0].value
                        ? 'border-red-500'
                        : 'border-gray-300',
                      'focus:border-indigo-600 focus:border-2 focus:outline-none',
                    ]" class="w-16 rounded-md cursor-default p-1.5 text-left text-sm text-gray-900 border" />
                  <span class="text-sm text-gray-900">time(s).</span>
                </div>
              </div>
            </div>

            <div class="p-2 rounded-md bg-indigo-50 border border-indigo-600" v-if="triggerSettings.type === 'manual'">
              <div class="px-4 py-2 font-mono text-sm whitespace-pre-wrap relative flex items-center justify-center">
                <code v-text="`SondarSDK.showSurvey('${survey_id}')`"></code>
                <button @click="
                  copyToClipboard(`SondarSDK.showSurvey('${survey_id}')`)
                  "
                  class="absolute right-2 p-1.5 rounded-md text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  title="Copy to clipboard">
                  <heroicon-outline-document-duplicate class="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ======================= -->
      <!-- ======================= -->
      <!-- User Attributes Section -->
      <!-- ======================= -->
      <!-- ======================= -->
      <div v-if="distributionChannel.toLowerCase() !== 'sharable link' && triggerSettings.type !== 'manual'"
        class="grid grid-cols-4 gap-5 pt-5 pb-10 border-b border-gray-200">
        <!-- Left Column -->
        <div class="col-span-1">
          <h2 class="text-base/7 font-semibold text-gray-900">
            User Attributes
          </h2>
          <p class="mt-1 text-sm/6 text-gray-600">
            Choose the audience for this survey
          </p>
        </div>

        <!-- Right Column -->
        <div class="col-span-3">
          <div class="space-y-6">
            <div class="">
              <label class="text-sm/6 font-semibold text-gray-900 w-1/4 mb-1 flex items-center">
                Match with
              </label>
              <select v-model="user_attributes_settings.matchOption"
                class="w-1/2 rounded-md cursor-default bg-white p-1.5 text-sm text-gray-900 border border-gray-300">
                <option value="all">All Attributes</option>
                <option value="any">Any Attribute</option>
              </select>
            </div>
            <div v-for="(attr, index) in user_attributes_settings.defaultAttributes" :key="index" class="">
              <label class="text-sm/6 font-semibold text-gray-900 w-1/4">
                {{ attr.label }}:
              </label>
              <div class="flex space-x-2">
                <select :value="''" @change="(event) => addSelectedItem(event, index)"
                  class="w-1/2 rounded-md cursor-default bg-white p-1.5 text-sm text-gray-900 border border-gray-300">
                  <option value="" disabled>
                    {{ attr.selected.length > 0 ? "Select" : "Any" }}
                  </option>
                  <option v-for="option in attr.options" :key="option.value" :value="option.value">
                    {{ option.label }}
                  </option>
                </select>
                <div class="flex flex-wrap space-x-2">
                  <span v-for="(item, i) in attr.selected" :key="i"
                    class="inline-flex items-center gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-sm font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                    {{ item.label }}
                    <button type="button" @click="removeSelectedItem(attr, i)"
                      class="group relative -mr-1 h-3.5 w-3.5 hover:text-gray-800">
                      <span class="sr-only">Remove</span>
                      <svg viewBox="0 0 14 14" class="h-3.5 w-3.5">
                        <path d="M4 4l6 6m0-6l-6 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      </svg>
                    </button>
                  </span>
                </div>
                <button v-if="attr.selected.length > 0" @click="clearSelection(attr)"
                  class="text-sm font-medium text-indigo-600 hover:underline">
                  Reset
                </button>
              </div>
            </div>

            <!-- Custom Attributes  -->
            <div v-for="(attr, index) in user_attributes_settings.customAttributes
              .selected" :key="index" class="flex flex-col">
              <label class="text-sm/6 font-semibold text-gray-900">{{ attr.label }}:</label>
              <div class="flex space-x-2">
                <div class="flex w-1/2 space-x-2">
                  <select v-model="attr.operator"
                    class="w-1/3 rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset sm:text-sm sm:leading-6"
                    :class="[
                      !attr.operator ? 'ring-red-500' : 'ring-gray-300', 'focus:ring-2 focus:ring-indigo-600'
                    ]">
                    <option disabled value="">Select...</option>
                    <option v-for="option in attr.options" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                  <input v-if="attr.type != 'bool'" v-model="attr.value" :type="attr.type"
                    class="flex-1 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6"
                    :class="[!attr.value ? 'ring-red-500' : 'ring-gray-300', 'focus:ring-2 focus:ring-indigo-600']" />
                </div>

                <button @click="removeCustomAttribute(index)" class="text-gray-500 hover:text-red-700 text-sm">
                  <heroicon-outline-trash class="w-5 h-5" />
                </button>
              </div>
              <span v-if="
                !attr.operator ||
                (attr.type != 'bool' ? !attr.value : false)
              " class="validation-error text-red-700 text-sm font-medium flex items-center">This field is
                required.</span>
            </div>
          </div>

          <div v-if="triggerSettings.type !== 'page_visit'" class="mt-6">
            <button @click="openAttributeModal"
              class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              Add Attribute
            </button>
          </div>
        </div>

        <!-- Custome Attribute Modal -->
        <div v-if="isModalOpen" class="relative z-50">
          <!-- Background backdrop -->
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <!-- Header -->
                <div class="mb-4">
                  <h3 class="text-base font-semibold leading-6 text-gray-900">Select Custom Attribute</h3>
                </div>

                <!-- Content -->
                <div class="space-y-2">
                  <template v-for="(attr, index) in user_attributes_settings.customAttributes.options" :key="index">
                    <div @click="selectCustomAttribute(attr)"
                      class="group relative flex items-center space-x-3 rounded-lg border border-gray-300 px-5 py-4 shadow-sm hover:border-indigo-600 hover:border-2 hover:bg-indigo-50 cursor-pointer">
                      <div class="min-w-0 flex-1">
                        <div class="flex items-center justify-between">
                          <span class="text-sm font-medium text-gray-900">{{ attr.label }}</span>
                          <span class="text-sm text-gray-500">({{ attr.type }})</span>
                        </div>
                      </div>
                      <span class="text-gray-400 group-hover:text-gray-500">
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clip-rule="evenodd" />
                        </svg>
                      </span>
                    </div>
                  </template>
                </div>

                <!-- Footer -->
                <div class="mt-5 sm:mt-6">
                  <button type="button" @click="closeModal"
                    class="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ======================= -->
      <!-- ======================= -->
      <!-- More Conditions Section -->
      <!-- ======================= -->
      <!-- ======================= -->
      <div class="grid grid-cols-4 gap-5 pt-5 pb-10">
        <!-- Left Column -->
        <div class="col-span-1">
          <h2 class="text-base/7 font-semibold text-gray-900">
            Survey Settings
          </h2>
          <p class="mt-1 text-sm/6 text-gray-600">
            Fine tune survey behaviour
          </p>
        </div>
        <!-- Right Column -->
        <div class="col-span-3 space-y-6">
          <div v-if="distributionChannel.toLowerCase() !== 'sharable link'" class="">
            <label class="text-sm/6 font-semibold text-gray-900 w-1/4 mb-1 flex items-center">
              Time Delay
            </label>
            <div class="flex items-center space-x-2">
              <input type="number" v-model="more_conditions.time_delay" :class="[
                more_conditions.time_delay === '' ? 'ring-red-500' : 'ring-gray-300',
                'focus:ring-2 focus:ring-indigo-600'
              ]"
                class="w-1/2 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6" />
              <span class="text-sm text-gray-900">Seconds</span>
            </div>
            <span v-if="more_conditions.time_delay === ''"
              class="mt-2 validation-error text-sm font-medium text-red-700">This
              field is required.</span>
          </div>

          <div class="">
            <label class="text-sm/6 font-semibold text-gray-900 w-1/4 mb-1 flex items-center">
              Response Limit
            </label>
            <div class="flex items-center space-x-2">
              <select v-model="more_conditions.response_limit.limit"
                class="w-1/2 rounded-md border-0 px-1.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option :value="true">Set Limit</option>
                <option :value="false">No Limit</option>
              </select>
              <div v-if="more_conditions.response_limit.limit" class="flex-1 flex items-center space-x-2">
                <input type="number" v-model="more_conditions.response_limit.value" :class="[
                  !more_conditions.response_limit.value ? 'ring-red-500' : 'ring-gray-300',
                  'focus:ring-2 focus:ring-indigo-600'
                ]"
                  class="w-24 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6" />
                <span class="text-sm text-gray-900 whitespace-nowrap">Responses</span>
              </div>
            </div>
            <span v-if="more_conditions.response_limit.limit && !more_conditions.response_limit.value"
              class="mt-2 text-sm font-medium text-red-700 validation-error">This field is required.</span>
          </div>

          <div v-if="distributionChannel.toLowerCase() !== 'sharable link'" class="">
            <label class="text-sm/6 font-semibold text-gray-900 w-1/4 mb-1 flex items-center">
              Allow User to Dismiss Survey
            </label>
            <select v-model="more_conditions.can_dismiss"
              class="w-1/2 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <option :value="true">Yes</option>
              <option :value="false">No</option>
            </select>
          </div>

          <div v-if="distributionChannel.toLowerCase() !== 'sharable link'" class="">
            <label class="text-sm/6 font-semibold text-gray-900 w-1/4 mb-1 flex items-center">
              Widget Position
            </label>
            <div class="flex flex-col gap-2">
              <select v-model="more_conditions.widget_position" @change="debounceUpdateWidgetPosition"
                class="w-1/2 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option v-for="position in positions" :key="position.value" :value="position.value">
                  {{ position.label }}
                </option>
              </select>
              <div class="flex justify-left">
                <WidgetPosition :position="more_conditions.widget_position" />
              </div>
            </div>
          </div>

          <div v-if="distributionChannel.toLowerCase() !== 'sharable link'" class="">
            <div class="flex items-center gap-4">
              <label class="text-sm/6 font-semibold text-gray-900 flex items-center">
                Dim Page Background When Survey Appears
              </label>
              <label class="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" v-model="more_conditions.background_tint" class="sr-only peer">
                <div
                  class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-indigo-600">
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { useRoute } from 'vue-router'
import {
  CodeBracketSquareIcon,
  GlobeAltIcon,
  CursorArrowRaysIcon,
  TrashIcon,
  DocumentDuplicateIcon,
} from "@heroicons/vue/24/outline"
import GlobalNotification from "../commons/GlobalNotification.vue"
import WidgetPosition from './WidgetPosition.vue'
import ChannelSelection from '../commons/ChannelSelection.vue'
import { useAuthStore } from '@/stores/auth'
import {
  fetchTenantEvents,
  fetchLanguages,
  fetchTenantUserAttributes,
  fetchSurveyConfiguration,
  updateSurveyConfiguration,
  updateSurveyWidgetPosition,
  updateSurveyDistributionConfig,
  updateSurveyContext
} from '@/services/supabaseService'

export default {
  components: {
    HeroiconOutlineCodeBracketSquare: CodeBracketSquareIcon,
    HeroiconOutlineGlobeAlt: GlobeAltIcon,
    HeroiconOutlineCursorArrowRays: CursorArrowRaysIcon,
    HeroiconOutlineTrash: TrashIcon,
    HeroiconOutlineDocumentDuplicate: DocumentDuplicateIcon,
    GlobalNotification,
    WidgetPosition,
    ChannelSelection,
  },
  name: "ConfigureSurvey",
  setup() {
    const route = useRoute()
    const authStore = useAuthStore()

    // State variables
    const debounceTimer = ref(null)
    const showDialog = ref(false)
    const previousBlocksState = ref(null)
    const transformedData = ref({})
    const isLoading = ref(true)
    const survey_id = ref(route.params.survey_id)
    const tenantId = ref("")
    const isModalOpen = ref(false)
    const backup_state = ref({})
    const showCopiedNotification = ref(false)
    const checkChangesTimer = ref(null)
    const showErrorNotification = ref(false)
    const distributionChannel = ref("")
    const context = ref("")

    // Data mappings
    const dataOptionsMapping = {
      number: ["=", ">", "<", ">=", "<=", "!="],
      text: ["contains", "equals", "endsWith", "startsWith"],
      bool: ["true", "false"],
      date: ["=", ">", "<", ">=", "<=", "!="],
      "datetime-local": ["=", ">", "<", ">=", "<=", "!="],
    }

    // Complex state objects
    const more_conditions = ref({
      time_delay: 0,
      response_limit: {
        limit: false,
        value: 0,
      },
      can_dismiss: true,
      widget_position: "bottom_center",
      background_tint: false,
    })

    const positions = ref([
      { value: "top_left", label: "Top Left" },
      { value: "top_center", label: "Top Center" },
      { value: "top_right", label: "Top Right" },
      { value: "center_left", label: "Center Left" },
      { value: "page_center", label: "Page Center" },
      { value: "center_right", label: "Center Right" },
      { value: "bottom_left", label: "Bottom Left" },
      { value: "bottom_center", label: "Bottom Center" },
      { value: "bottom_right", label: "Bottom Right" },
    ])

    const user_attributes_settings = ref({
      matchOption: "all",
      defaultAttributes: [
        {
          label: "Language",
          attribute: "language",
          type: "dropdown",
          options: [],
          selected: [],
        },
        {
          label: "Device",
          attribute: "device",
          type: "dropdown",
          options: [
            {
              label: "Mobile",
              value: "mobile",
            },
            {
              label: "Desktop",
              value: "desktop",
            },
          ],
          selected: [],
        },
      ],
      customAttributes: {
        options: [],
        selected: [],
      },
    })

    const triggerSettings = ref({
      type: "page_visit",
      page_visit: {
        options: [
          { value: "any", label: "Any" },
          { value: "exact_match", label: "Exact Match" },
          { value: "contains", label: "Contains" },
          { value: "starts_with", label: "Starts With" },
          { value: "ends_with", label: "Ends With" },
          { value: "regex", label: "Regex" },
        ],
        selected: "any",
        value: "",
      },
      tracked_events: {
        options: [],
        triggering_events: [],
        selected: [
          {
            event: "",
            operator: "=",
            attribute: "count",
            value: 1,
          },
        ],
      },
    })

    // Methods
    const transformDataBeforeSave = async () => {
      // Trigger Settings
      let conditions = []
      let trigger_events = []
      const trigger_type = triggerSettings.value.type

      if (
        trigger_type === "page_visit" &&
        triggerSettings.value.page_visit.selected !== "any"
      ) {
        conditions = [
          {
            condition_type: "AND",
            operator: triggerSettings.value.page_visit.selected,
            value: triggerSettings.value.page_visit.value,
          },
        ]
      } else if (trigger_type === "tracked_event") {
        const eventDetails = triggerSettings.value.tracked_events.selected[0]
        conditions = [
          {
            condition_type: "AND",
            operator: eventDetails.operator,
            event: eventDetails.event,
            attribute: eventDetails.attribute,
            value: eventDetails.value,
          },
        ]
        trigger_events = [eventDetails.event]
      }

      // User Attributes
      let userAttributes = []
      user_attributes_settings.value.defaultAttributes.forEach((attribute) => {
        if (attribute.selected.length > 0) {
          userAttributes.push({
            condition_type:
              user_attributes_settings.value.matchOption == "all" ? "AND" : "OR",
            operator: "in",
            attribute: attribute.attribute,
            value: attribute.selected.map((item) => item.value),
          })
        }
      })

      user_attributes_settings.value.customAttributes.selected.forEach(
        (attribute) => {
          userAttributes.push({
            condition_type:
              user_attributes_settings.value.matchOption == "all" ? "AND" : "OR",
            operator: attribute.type == "bool" ? "=" : attribute.operator,
            type: attribute.type,
            attribute: attribute.label,
            value:
              attribute.type == "bool" ? attribute.operator : attribute.value,
          })
        }
      )

      const updateData = {
        trigger_type: trigger_type,
        conditions: conditions,
        trigger_events: trigger_events,
        user_attrs: userAttributes,
        can_dismiss: more_conditions.value.can_dismiss,
        delay_seconds: more_conditions.value.time_delay,
        response_limit: more_conditions.value.response_limit.limit
          ? more_conditions.value.response_limit.value
          : null,
        widget_position: more_conditions.value.widget_position,
        background_tint: more_conditions.value.background_tint,
      }
      transformedData.value = updateData
    }

    const syncBlocks = async () => {
      clearTimeout(debounceTimer.value)
      debounceTimer.value = setTimeout(async () => {
        try {
          await updateSurveyConfiguration(survey_id.value, transformedData.value)
        } catch (err) {
          console.error("Error updating survey configuration:", err)
        }
      }, 2000)
    }

    const fetchData = async () => {
      // Fetching the tenant created events
      try {
        const events = await fetchTenantEvents(tenantId.value)
        triggerSettings.value.tracked_events.options = events
      } catch (err) {
        console.error("Error fetching events:", err)
      }

      // Fetching the languages available in the db
      try {
        const languages = await fetchLanguages()
        user_attributes_settings.value.defaultAttributes[0].options = languages
      } catch (err) {
        console.error("Error fetching languages:", err)
      }

      // Fetching the tenant created user attributes
      try {
        const attributes = await fetchTenantUserAttributes(tenantId.value)
        user_attributes_settings.value.customAttributes.options = attributes.map(
          (event) => ({
            label: event.attribute_name,
            type: event.data_type,
            options: dataOptionsMapping[event.data_type],
            operator: "",
            value: "",
          })
        )
      } catch (err) {
        console.error("Error fetching user attributes:", err)
      }

      // Fetching survey data to prefill the form
      try {
        const data = await fetchSurveyConfiguration(survey_id.value)
        setInitialSurveyData(data)
      } catch (err) {
        console.error("Error fetching survey configuration:", err)
      }
    }

    const setInitialSurveyData = (data) => {
      // More conditions
      if (data.delay_seconds !== undefined) {
        more_conditions.value.time_delay = data.delay_seconds
      }

      // Set context if available
      if (data.llm_context !== undefined) {
        context.value = data.llm_context
      }

      if (data.response_limit) {
        more_conditions.value.response_limit = {
          limit: true,
          value: data.response_limit,
        }
      }

      if (data.can_dismiss !== undefined) {
        more_conditions.value.can_dismiss = data.can_dismiss
      }

      if (data.widget_position !== undefined) {
        more_conditions.value.widget_position = data.widget_position
      }

      if (data.background_tint !== undefined) {
        more_conditions.value.background_tint = data.background_tint
      }

      // Distribution channel
      distributionChannel.value = data.survey_distribution_config?.[0]?.channel || ""

      // User attributes
      if (data.user_attrs && data.user_attrs.length > 0) {
        user_attributes_settings.value.matchOption =
          data.user_attrs[0].condition_type == "AND" ? "all" : "any"
        // Default attributes
        var default_attrs = user_attributes_settings.value.defaultAttributes.map(
          (item) => item.attribute
        )
        default_attrs.forEach((ele, index) => {
          var def_attr = data.user_attrs.find((attr) => attr.attribute == ele)
          if (def_attr) {
            user_attributes_settings.value.defaultAttributes[index].selected =
              user_attributes_settings.value.defaultAttributes[
                index
              ].options.filter((item) => def_attr.value.includes(item.value))
          }
        })

        // Custom attributes
        var custom_attrs = data.user_attrs.filter(
          (item) => !default_attrs.includes(item.attribute)
        )
        custom_attrs.forEach((obj) => {
          user_attributes_settings.value.customAttributes.selected.push({
            label: obj.attribute,
            type: obj.type,
            options: dataOptionsMapping[obj.type],
            operator: obj.type == "bool" ? obj.value : obj.operator,
            value: obj.value,
          })
        })
      }

      // Trigger settings
      if (data.trigger_type !== undefined) {
        triggerSettings.value.type = data.trigger_type
      }

      if (data.trigger_type == "tracked_event") {
        var tracked_event_condition = data.conditions[0]
        triggerSettings.value.tracked_events.triggering_events = [
          tracked_event_condition.event,
        ]
        triggerSettings.value.tracked_events.selected[0].event =
          tracked_event_condition.event
        triggerSettings.value.tracked_events.selected[0].value =
          tracked_event_condition.value
      }

      if (data.trigger_type == "page_visit" && data.conditions.length > 0) {
        var url_condition = data.conditions[0]
        triggerSettings.value.page_visit.selected = url_condition.operator
        triggerSettings.value.page_visit.value = url_condition.value
      }
      backup_state.value = {
        trigger_settings: JSON.parse(JSON.stringify(triggerSettings.value)),
        more_conditions: JSON.parse(JSON.stringify(more_conditions.value)),
        user_attributes_settings: JSON.parse(
          JSON.stringify(user_attributes_settings.value)
        ),
      }
      isLoading.value = false
    }

    const clearSelection = (attr) => {
      attr.selected = []
    }

    const addSelectedItem = (event, index) => {
      const selectedOption = user_attributes_settings.value.defaultAttributes[
        index
      ].options.find((option) => option.value === event.target.value)

      if (
        !user_attributes_settings.value.defaultAttributes[index].selected.some(
          (selected) => selected.value === selectedOption.value
        )
      ) {
        user_attributes_settings.value.defaultAttributes[index].selected.push(
          selectedOption
        )
      }

      event.target.value = ""
    }

    const removeSelectedItem = (attr, index) => {
      attr.selected.splice(index, 1)
    }

    const openAttributeModal = () => {
      isModalOpen.value = true
    }

    const closeModal = () => {
      isModalOpen.value = false
    }

    const removeCustomAttribute = (index) => {
      user_attributes_settings.value.customAttributes.selected.splice(index, 1)
    }

    const selectCustomAttribute = (option) => {
      user_attributes_settings.value.customAttributes.selected.push({
        label: option.label,
        type: option.type,
        options: option.options,
        operator: "",
        value: "",
      })
      closeModal()
    }

    const saveChanges = () => {
      const errorFields = document.querySelectorAll(".validation-error")
      const hasErrors = Array.from(errorFields).some(
        (field) => window.getComputedStyle(field).display !== "none"
      )
      if (hasErrors) {
        showErrorNotification.value = true
        setTimeout(() => {
          showErrorNotification.value = false
        }, 3000)
      } else {
        transformDataBeforeSave()
      }
    }

    const copyToClipboard = async (text) => {
      try {
        if (navigator.clipboard && window.isSecureContext) {
          await navigator.clipboard.writeText(text)
        } else {
          // Fallback for older browsers
          const textArea = document.createElement("textarea")
          textArea.value = text
          textArea.style.position = "fixed"
          textArea.style.left = "-999999px"
          textArea.style.top = "-999999px"
          document.body.appendChild(textArea)
          textArea.focus()
          textArea.select()
          try {
            document.execCommand("copy")
            textArea.remove()
          } catch (err) {
            console.error("Failed to copy text: ", err)
          }
        }
        // Show notification
        showCopiedNotification.value = true
        setTimeout(() => {
          showCopiedNotification.value = false
        }, 3000)
      } catch (err) {
        console.error("Failed to copy text: ", err)
      }
    }

    const startChangeChecker = () => {
      transformDataBeforeSave()
      previousBlocksState.value = JSON.stringify(transformedData.value)
      checkChangesTimer.value = setInterval(() => {
        transformDataBeforeSave()
        const currentBlocksState = JSON.stringify(transformedData.value)
        const errorFields = document.querySelectorAll(".validation-error")
        const hasErrors = Array.from(errorFields).some(
          (field) => window.getComputedStyle(field).display !== "none"
        )
        if (
          previousBlocksState.value !== currentBlocksState && !hasErrors
        ) {
          previousBlocksState.value = currentBlocksState
          syncBlocks()
        }
      }, 500)
    }

    const debounce = (func, wait) => {
      let timeout
      return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => func.apply(null, args), wait)
      }
    }

    const updateWidgetPosition = async () => {
      try {
        await updateSurveyWidgetPosition(survey_id.value, more_conditions.value.widget_position)
      } catch (err) {
        console.error('Unexpected error updating widget position:', err)
      }
    }

    const debounceUpdateWidgetPosition = debounce(updateWidgetPosition, 2000)

    const updateDistributionChannel = async (channel) => {
      try {
        distributionChannel.value = channel;
        await updateSurveyDistributionConfig(survey_id.value, channel);
      } catch (err) {
        console.error('Error updating distribution channel:', err);
      }
    };

    const debounceUpdateContext = debounce(async () => {
      try {
        await updateSurveyContext(survey_id.value, context.value)
      } catch (err) {
        console.error('Error updating context:', err)
      }
    }, 2000)

    // Lifecycle hooks
    onMounted(async () => {
      tenantId.value = authStore.tenantId
      await fetchData()
      startChangeChecker()
    })

    onBeforeUnmount(() => {
      clearInterval(checkChangesTimer.value)
    })

    // Watchers
    watch(() => triggerSettings.value.page_visit.selected, (newValue) => {
      if (newValue === "any") {
        triggerSettings.value.page_visit.value = ""
      }
    })

    watch(() => triggerSettings.value.type, (newValue) => {
      if (newValue === "manual") {
        user_attributes_settings.value.defaultAttributes.forEach((attribute) => {
          attribute.selected = []
        })
        user_attributes_settings.value.customAttributes.selected = []
      } else if (newValue == "page_visit") {
        user_attributes_settings.value.customAttributes.selected = []
      }
    })

    return {
      // State
      showDialog,
      isLoading,
      survey_id,
      isModalOpen,
      showCopiedNotification,
      more_conditions,
      user_attributes_settings,
      triggerSettings,
      showErrorNotification,
      distributionChannel,
      positions,
      context,

      // Methods
      clearSelection,
      addSelectedItem,
      removeSelectedItem,
      openAttributeModal,
      closeModal,
      removeCustomAttribute,
      selectCustomAttribute,
      saveChanges,
      copyToClipboard,
      debounceUpdateWidgetPosition,
      updateDistributionChannel,
      debounceUpdateContext
    }
  }
}
</script>

<style scoped>
select {
  outline: none;
  cursor: pointer;
}

button {
  outline: none;
}
</style>