<template>
  <div id="tenant-settings" class="w-full text-left">
    <!-- Loading State -->
    <div v-if="isLoading" class="flex items-center justify-center w-full">
      <div class="p-6 space-y-6  w-[75rem] animate-pulse">
        <!-- Tabs -->
        <div class="flex space-x-4">
          <div class="h-8 w-24 bg-gray-300 rounded"></div>
          <div class="h-8 w-36 bg-gray-200 rounded"></div>
        </div>

        <!-- Organization Profile -->
        <div class="space-y-4">
          <div class="h-6 w-40 bg-gray-300 rounded"></div>
          <div class="space-y-2">
            <div class="h-4 w-24 bg-gray-200 rounded"></div>
            <div class="h-10 w-full bg-gray-200 rounded"></div>
          </div>
          <div class="space-y-2">
            <div class="h-4 w-24 bg-gray-200 rounded"></div>
            <div class="h-10 w-full bg-gray-200 rounded"></div>
          </div>
        </div>

        <!-- My Profile -->
        <div class="space-y-4">
          <div class="h-6 w-40 bg-gray-300 rounded"></div>
          <div class="space-y-2">
            <div class="h-4 w-24 bg-gray-200 rounded"></div>
            <div class="h-10 w-full bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <GlobalNotification :key="ok" v-if="showSuccessNotification" :type="'ok'" :message="successNotificationMsg"
        @close="showSuccessNotification = false" />

      <!-- Page Heading -->
      <div class="w-[1080px] mx-auto px-5 pt-5 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Settings
          </h2>
        </div>
      </div>

      <!-- Tab Menus -->
      <div class="border-b border-gray-200 sm:pb-0">
        <div class="w-[1080px] mx-auto px-5 pt-5">
          <div class="mt-3 sm:mt-4">
            <div class="hidden sm:flex sm:justify-between sm:items-center">
              <nav class="-mb-px flex space-x-8">
                <a v-for="tab in tabs" :key="tab.name" :href="tab.href" :class="[
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap cursor-pointer border-b-2 px-1 pb-4 text-sm font-medium',
                ]" :aria-current="tab.current ? 'page' : undefined" @click.prevent="changeTab(tab.name)">
                  {{ tab.name }}
                </a>
              </nav>
              <div class="flex items-center relative">
                <button 
                  v-if="userRole === 'tenant_admin' && tabs.find(tab => tab.current && tab.name === 'Team Members')" 
                  type="button" 
                  class="btn-primary-sondar absolute right-0 top-[-25px]"
                  @click="showPopup = true"
                >
                  Invite
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tab Content -->
      <div class="w-[1080px] mx-auto p-5">
        <ProfileTab
          v-if="tabs.find((tab) => tab.current && tab.name === 'Profile')"
          :current-user="currentUser"
          :tenant-name="tenantName"
          :tenant-api-key="tenantApiKey"
          :sdk-connected="sdkConnected"
          @copy-success="handleCopySuccess"
        />

        <TeamTab
          v-if="tabs.find((tab) => tab.current && tab.name === 'Team Members')"
          :team-members="teamMembers"
          :current-user="currentUser"
          :is-admin="userRole === 'tenant_admin'"
          @edit-member="editMember"
        />

        <BillingTab 
          v-if="tabs.find((tab) => tab.current && tab.name === 'Billing')"
          :tenant-id="tenantId"
          :tenant-name="tenantName"
        />
      </div>
      


      <!-- Invite Team Member Modal -->
      <div v-if="showPopup && canInviteMembers" class="relative z-50">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 z-10 overflow-y-auto text-left">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div>
                <div class="relative">
                  <h3 class="text-base font-semibold leading-6 text-gray-900">
                    Invite Team Member
                  </h3>

                  <div class="mt-6">
                    <div class="mb-4">
                      <label for="teamMemberName" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
                      <div class="mt-2">
                        <input id="teamMemberName" v-model="teamMemberName" type="text" placeholder="e.g. Elon Musk"
                          class="input-sondar" />
                        <p v-if="teamMemberNameError" class="mt-2 text-sm text-red-600">
                          {{ teamMemberNameError }}
                        </p>
                      </div>
                    </div>

                    <div class="mb-4">
                      <label for="teamMemberEmail"
                        class="block text-sm font-medium leading-6 text-gray-900">Email</label>
                      <div class="mt-2">
                        <input id="teamMemberEmail" v-model="teamMemberEmail" type="email"
                          placeholder="Enter email address" class="input-sondar" />
                        <p v-if="emailError" class="mt-2 text-sm text-red-600">
                          {{ emailError }}
                        </p>
                      </div>
                    </div>

                    <div class="mb-4">
                      <label for="teamMemberRole" class="block text-sm font-medium leading-6 text-gray-900">Role</label>
                      <div class="mt-2">
                        <select id="teamMemberRole" v-model="teamMemberRole" class="select-sondar">
                          <option value="" disabled>Select role</option>
                          <option value="3">Member</option>
                          <option value="2">Admin</option>
                        </select>
                        <p class="mt-2 text-xs text-gray-500">
                          Admins can manage team members and settings. Members can only manage surveys.
                        </p>
                        <p v-if="teamMemberRoleError" class="mt-2 text-sm text-red-600">
                          {{ teamMemberRoleError }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- Action Buttons -->
                  <div class="mt-8 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button type="button" @click="clearModal()" class="btn-secondary-sondar">
                      Cancel
                    </button>
                    <button type="button" @click="inviteTeamMember(teamMemberName, teamMemberEmail)"
                      class="btn-primary-sondar" :disabled="isInviting">
                      <div v-if="isInviting" class="flex items-center justify-center">
                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                          fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                          </circle>
                          <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                          </path>
                        </svg>
                      </div>
                      <span v-else>Invite</span>
                    </button>
                  </div>

                  <!-- General Error Message -->
                  <div v-if="errorMessage" class="mt-4 rounded-md bg-red-50 p-4">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                            clip-rule="evenodd" />
                        </svg>
                      </div>
                      <div class="ml-3">
                        <p class="text-sm font-medium text-red-800">{{ errorMessage }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Edit Member Modal -->
      <div v-if="showEditModal" class="relative z-50">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-base font-semibold leading-6 text-gray-900">
                    Edit Team Member
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      {{ selectedMember?.email }}
                    </p>
                  </div>
                </div>

                <div class="mt-6 space-y-6">
                  <div>
                    <label for="editRole" class="block text-sm font-medium text-gray-700">Role</label>
                    <select id="editRole" v-model="editForm.role"
                      class="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                      <option value="tenant_member">Member</option>
                      <option value="tenant_admin">Admin</option>
                    </select>
                  </div>

                  <div>
                    <label for="editStatus" class="block text-sm font-medium text-gray-700">Status</label>
                    <select id="editStatus" v-model="editForm.is_active"
                      class="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                      <option :value="true">Active</option>
                      <option :value="false">Paused</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button type="button"
                  class="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1"
                  @click="showEditModal = false">
                  Cancel
                </button>
                <button type="button"
                  class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                  @click="updateMember">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalNotification from '@/components/commons/GlobalNotification.vue';
import { useAuthStore } from '@/stores/auth';
import { defineComponent, ref, computed, onMounted, onBeforeUnmount } from 'vue';
import {
  getCurrentUserDetails,
  getTenantById,
  getTeamMembersByTenantId,
  checkTenantSDKConnection,
  checkExistingUser,
  inviteTeamMember,
  updateTeamMember
} from '@/services/supabaseService';
import ProfileTab from './ProfileTab.vue';
import TeamTab from './TeamTab.vue';
import BillingTab from './BillingTab.vue'

export default defineComponent({
  name: "TenantSettings",
  components: {
    GlobalNotification,
    ProfileTab,
    TeamTab,
    BillingTab,
  },
  setup() {
    const authStore = useAuthStore();

    const tabs = ref([
      { name: "Profile", current: true },
      { name: "Team Members", current: false },
      { name: "Billing", current: false },
    ]);
    const currentUser = ref({});
    const userRole = ref(null);
    const teamMembers = ref([]);
    const tenantApiKey = ref("");
    const tenantName = ref("");
    const sdkConnected = ref(false);
    const pollingInterval = ref(null);
    const showSuccessNotification = ref(false);
    const successNotificationMsg = ref("");
    const showPopup = ref(false);
    const errorMessage = ref("");
    const isLoading = ref(false);
    const isInviting = ref(false);
    const tenantId = ref(null);
    const teamMemberName = ref("");
    const teamMemberEmail = ref("");
    const teamMemberRole = ref("");
    const emailError = ref("");
    const teamMemberNameError = ref("");
    const teamMemberRoleError = ref("");
    const showEditModal = ref(false);
    const selectedMember = ref(null);
    const editForm = ref({
      role: 'tenant_member',
      is_active: true,
    });

    // Computed properties
    const canInviteMembers = computed(() => userRole.value === 'tenant_admin');

    // Methods
    const getCurrentUser = async () => {
      try {
        if (authStore.user) {
          const userData = await getCurrentUserDetails(authStore.user.id);
          currentUser.value = { ...authStore.user, name: userData.name };
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    const getTenantDetails = async () => {
      try {
        const data = await getTenantById(tenantId.value);
        tenantApiKey.value = data.api_key;
        tenantName.value = data.name;
        sdkConnected.value = data.sdk_connected;

        if (!sdkConnected.value) {
          pollingInterval.value = setInterval(checkSDKConnection, 3000);
        }
      } catch (error) {
        console.error("Error getting Tenant Details:", error.message);
      }
    };

    const getTeamMembers = async () => {
      try {
        teamMembers.value = await getTeamMembersByTenantId(tenantId.value);
      } catch (error) {
        console.error('Error fetching team members:', error);
      }
    };

    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(tenantApiKey.value);
        successNotificationMsg.value = "Copied to Clipboard";
        showSuccessNotification.value = true;
        setTimeout(() => showSuccessNotification.value = false, 4000);
      } catch (err) {
        console.error('Failed to copy:', err);
      }
    };

    const getInitials = (email) => {
      return email
        .split('@')[0]
        .split(/[-._]/)
        .map(part => part[0])
        .join('')
        .toUpperCase()
        .slice(0, 2);
    };

    const inviteTeamMemberHandler = async () => {
      errorMessage.value = "";
      emailError.value = "";
      teamMemberNameError.value = "";
      teamMemberRoleError.value = "";
      isInviting.value = true;

      try {
        // Validate inputs
        if (!teamMemberEmail.value) {
          emailError.value = "Email is required";
          return;
        }
        if (!teamMemberName.value) {
          teamMemberNameError.value = "Name is required";
          return;
        }
        if (!teamMemberRole.value) {
          teamMemberRoleError.value = "Role is required";
          return;
        }

        // Check if email already exists
        const existingUser = await checkExistingUser(teamMemberEmail.value);
        if (existingUser) {
          errorMessage.value = "Cannot invite this user. This email is already registered.";
          return;
        }

        // Invite user
        const redirectUrl = `${window.location.protocol}//${window.location.host}/login`;
        await inviteTeamMember(teamMemberEmail.value, redirectUrl, teamMemberName.value, authStore.tenantId, authStore.tenantName, parseInt(teamMemberRole.value));

        clearModal();
        showSuccessNotification.value = true;
        successNotificationMsg.value = "Team member invited successfully";
        await getTeamMembers();
        setTimeout(() => showSuccessNotification.value = false, 4000);
      } catch (error) {
        errorMessage.value = error.message;
      } finally {
        isInviting.value = false;
      }
    };

    const clearModal = () => {
      showPopup.value = false;
      teamMemberName.value = "";
      teamMemberEmail.value = "";
      teamMemberRole.value = "";
      emailError.value = "";
      teamMemberNameError.value = "";
      teamMemberRoleError.value = "";
      errorMessage.value = "";
    };

    const editMember = (user) => {
      selectedMember.value = user;
      editForm.value.role = user.role === 'tenant_admin' ? 'tenant_admin' : 'tenant_member';
      editForm.value.is_active = user.is_active;
      showEditModal.value = true;
    };

    const updateMember = async () => {
      try {
        await updateTeamMember(
          selectedMember.value.email,
          tenantId.value,
          {
            role_id: editForm.value.role === 'tenant_admin' ? 2 : 3,
            is_active: editForm.value.is_active,
          }
        );

        showEditModal.value = false;
        successNotificationMsg.value = "Team member updated successfully";
        showSuccessNotification.value = true;
        await getTeamMembers();
        setTimeout(() => showSuccessNotification.value = false, 4000);
      } catch (error) {
        errorMessage.value = "Failed to update team member. Please try again.";
      }
    };

    const checkSDKConnection = async () => {
      try {
        const data = await checkTenantSDKConnection(tenantId.value);
        sdkConnected.value = data.sdk_connected;

        if (sdkConnected.value) {
          clearInterval(pollingInterval.value);
        }
      } catch (error) {
        console.error("Error checking SDK connection:", error);
      }
    };

    const changeTab = (tabName) => {
      tabs.value.forEach((tab) => {
        tab.current = tab.name === tabName;
      });
    };

    const handleCopySuccess = () => {
      successNotificationMsg.value = "Copied to Clipboard";
      showSuccessNotification.value = true;
      setTimeout(() => showSuccessNotification.value = false, 4000);
    };

    onMounted(async () => {
      tenantId.value = authStore.tenantId;
      userRole.value = authStore.role;

      isLoading.value = true;
      try {
        if (tenantId.value) {
          await Promise.all([
            getCurrentUser(),
            getTenantDetails(),
            getTeamMembers(),
          ]);
        }
      } catch (error) {
        console.error("Error loading settings:", error);
      } finally {
        isLoading.value = false;
      }

      if (tabs.value.length > 0) {
        changeTab(tabs.value[0].name);
      }
    });

    onBeforeUnmount(() => {
      if (pollingInterval.value) {
        clearInterval(pollingInterval.value);
      }
    });

    return {
      // Return all refs
      tabs,
      currentUser,
      userRole,
      teamMembers,
      tenantApiKey,
      tenantName,
      sdkConnected,
      showSuccessNotification,
      successNotificationMsg,
      showPopup,
      errorMessage,
      isLoading,
      isInviting,
      tenantId,
      teamMemberName,
      teamMemberEmail,
      teamMemberRole,
      emailError,
      teamMemberNameError,
      teamMemberRoleError,
      showEditModal,
      selectedMember,
      editForm,
      canInviteMembers,
      // Return all methods
      changeTab,
      getCurrentUser,
      getTenantDetails,
      getTeamMembers,
      copyToClipboard,
      inviteTeamMember: inviteTeamMemberHandler,
      clearModal,
      editMember,
      updateMember,
      getInitials,
      handleCopySuccess,
    };
  },
});
</script>