<template>
    <div>
        <AISurveyChat :initial-messages="initialMessages" :chat-payload="chatPayload" @close="closeChat"
            @survey-generated="handleSurveyGenerated" :is-wrapper="true" />
    </div>
</template>

<script>
import { ref } from 'vue'
import AISurveyChat from './AISurveyChat.vue'

export default {
    name: 'AISurveyChatWrapper',

    components: {
        AISurveyChat
    },

    props: {
        initialMessages: {
            type: Array,
            default: () => []
        },
        chatPayload: {
            type: Array,
            default: () => []
        }
    },

    setup() {
        const isOpen = ref(false)

        const openChat = () => {
            isOpen.value = true
            // Prevent body scrolling when modal is open
            document.body.style.overflow = 'hidden'
        }

        const closeChat = () => {
            isOpen.value = false
            // Restore body scrolling when modal is closed
            document.body.style.overflow = 'auto'
        }
        return {
            isOpen,
            openChat,
            closeChat,
        }
    }
}
</script>