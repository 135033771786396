<template>
  <div class="chat-box bg-white rounded-2xl px-10 py-12 max-w-[48rem] pb-6 shadow min-w-full sm:min-w-[42rem] flex flex-col items-center mx-4 sm:mx-auto"
    :class="{ 'widget-shadow': isSDK }">
    <svg v-if="(isSDK && canDismiss) || (isSDK && currentIndex === contentData.length - 1)" @click="closeWidget" xmlns="http://www.w3.org/2000/svg" fill="none"
      viewBox="0 0 24 24" stroke-width="2"
      class="size-6 absolute top-2 right-2 cursor-pointer stroke-gray-400 hover:stroke-gray-700">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
    </svg>

    <transition name="fade-slide">
      <div class="chat-content w-full flex flex-col items-center mx-auto text-center" v-if="currentContent">
        <p class="progress-text text-gray-500 text-sm font-medium">{{ currentIndex + 1 }} of {{ contentData.length }}
        </p>
        <div class="w-full flex flex-col items-center" v-if="currentContent.type === 'message'">
          <p class="block-headline-sondar">{{ currentContent.question }}</p>
          <p v-if="currentContent.description" class="block-description-sondar" v-html="currentContent.description"></p>
        </div>

        <div class="w-full flex flex-col items-center" v-else-if="currentContent.type === 'explore'">
          <p v-if="currentContent.description" class="block-description-sondar" v-html="currentContent.description"></p>
          <FollowUp class="mt-4" @conversationStarted="handleConversationStarted" @chatEnded="handleChatEnd"
            :responseId="responseId" :blockId="currentContent.id" :openingMessage="currentContent.question"
            :key="`${currentContent.id}-${currentIndex}`" :fuQuestionsRemaining="currentContent.followup_questions" />
        </div>

        <div class="w-full flex flex-col items-center" v-else-if="currentContent.type === 'select'">
          <p class="block-headline-sondar">{{ currentContent.question }}</p>
          <p v-if="currentContent.description" class="block-description-sondar" v-html="currentContent.description"></p>
          <div class="options">
            <div v-for="(choice, index) in currentContent.choices" :key="index"
              :class="['option', { selected: selectedChoice === choice }]"
              @click="!showFollowUp && selectOption(choice)">
              <input type="radio" :name="currentContent.id" :value="choice" v-model="selectedChoice"
                :disabled="showFollowUp" />
              <label>{{ choice }}</label>
            </div>
          </div>
          <FollowUp v-if="showFollowUp" style="margin-top: 1rem;" :responseId="responseId" @chatEnded="handleChatEnd"
            :openingMessage="followUpQuestionFromResponse" :blockId="currentContent.id"
            :key="`${currentContent.id}-${currentIndex}`" :messageId="blochMessageId"
            :fuQuestionsRemaining="currentContent.followup_questions - 1" />
        </div>

        <div class="w-full flex flex-col items-center" v-else-if="currentContent.type === 'rate'">
          <p class="block-headline-sondar">{{ currentContent.question }}</p>
          <p v-if="currentContent.description" class="block-description-sondar" v-html="currentContent.description"></p>
          <div class="w-full ratings-sondar">
            <div class="inline-flex flex-col">
              <div class="flex flex-row flex-wrap justify-center gap-1.5 sm:gap-x-2 gap-y-4 pb-1">
                <label v-for="n in currentContent.max_value - currentContent.min_value + 1" :key="n + currentContent.min_value - 1" class="rating-label-sondar shrink-0">
                  <input type="radio" v-model="selectedChoice" :name="currentContent.id" :value="n + currentContent.min_value - 1" :disabled="showFollowUp"
                    class="rating-input-sondar" />
                  <span :class="{ 'rating-text-sondar': true, 'rating-selected-sondar': selectedChoice === n + currentContent.min_value - 1, 'text-sm sm:text-base': currentContent.max_value - currentContent.min_value > 7 }">{{ n + currentContent.min_value - 1 }}</span>
                </label>
              </div>
              <div class="flex justify-between text-gray-500 font-medium mt-3" :class="{ 'hidden': isWrapped }">
                <div>{{ currentContent.min_label }}</div>
                <div>{{ currentContent.max_label }}</div>
              </div>
              <div v-if="isWrapped" class="flex justify-between text-gray-500 font-medium mt-3">
                <div>{{ currentContent.min_value }}-{{ currentContent.min_label }}</div>
                <div>{{ currentContent.max_value }}-{{ currentContent.max_label }}</div>
              </div>
            </div>
          </div>
          <FollowUp v-if="showFollowUp" style="margin-top: 1rem;" :responseId="responseId" @chatEnded="handleChatEnd"
            :openingMessage="followUpQuestionFromResponse" :blockId="currentContent.id"
            :key="`${currentContent.id}-${currentIndex}`" :messageId="blochMessageId"
            :fuQuestionsRemaining="currentContent.followup_questions - 1" />
        </div>

        <div class="w-full flex flex-col items-center" v-else-if="currentContent.type === 'csat'">
          <p class="block-headline-sondar">{{ currentContent.question }}</p>
          <p v-if="currentContent.description" class="block-description-sondar" v-html="currentContent.description"></p>
          <div class="inline-block ratings-sondar">
            <div class="flex flex-row gap-x-1 sm:gap-x-3 pb-1">
              <label v-for="n in currentContent.max_value" :key="n" class="rating-label-sondar">
                <input type="radio" v-model="selectedChoice" :name="currentContent.id" :value="n" :disabled="showFollowUp"
                  class="rating-input-sondar" />
                <span v-if="currentContent.max_value === 5"
                  :class="{ 'rating-csat-sondar': true, 'rating-selected-sondar': selectedChoice === n }">
                  {{ ['😠', '😟', '😐', '🙂', '😍'][n - 1] }}
                </span>

                <span v-if="currentContent.max_value === 4"
                  :class="{ 'rating-csat-sondar': true, 'rating-selected-sondar': selectedChoice === n }">
                  {{ ['😠', '😟', '🙂', '😍'][n - 1] }}
                </span>

                <span v-if="currentContent.max_value === 3"
                  :class="{ 'rating-csat-sondar': true, 'rating-selected-sondar': selectedChoice === n }">
                  {{ ['😠', '😐', '😍'][n - 1] }}
                </span>

                <span v-if="currentContent.max_value === 2"
                  :class="{ 'rating-csat-sondar': true, 'rating-selected-sondar': selectedChoice === n }">
                  {{ ['👎', '👍'][n - 1] }}
                </span>

              </label>
            </div>
          </div>

          <FollowUp v-if="showFollowUp" style="margin-top: 1rem;" :responseId="responseId" @chatEnded="handleChatEnd"
            :key="`${currentContent.id}-${currentIndex}`" :messageId="blochMessageId"
            :openingMessage="followUpQuestionFromResponse" :blockId="currentContent.id"
            :fuQuestionsRemaining="currentContent.followup_questions - 1" />
        </div>

        <div class="w-full flex flex-col items-center" v-else-if="currentContent.type === 'nps'">
          <p class="block-headline-sondar">{{ currentContent.question }}</p>
          <p v-if="currentContent.description" class="block-description-sondar" v-html="currentContent.description"></p>
          <div class="w-full ratings-sondar">
            <div class="inline-flex flex-col">
              <div class="flex flex-row flex-wrap justify-center gap-1.5 sm:gap-x-2 gap-y-4 pb-1">
                <label v-for="n in currentContent.max_value - currentContent.min_value + 1" :key="n + currentContent.min_value - 1" class="rating-label-sondar shrink-0">
                  <input type="radio" v-model="selectedChoice" :name="currentContent.id" :value="n + currentContent.min_value - 1" :disabled="showFollowUp"
                    class="rating-input-sondar" />
                  <span :class="{ 'rating-text-sondar': true, 'rating-selected-sondar': selectedChoice === n + currentContent.min_value - 1, 'text-sm sm:text-base': currentContent.max_value - currentContent.min_value > 7 }">{{ n + currentContent.min_value - 1 }}</span>
                </label>
              </div>
              <div class="flex justify-between text-gray-500 font-medium mt-3" :class="{ 'hidden': isWrapped }">
                <div>{{ currentContent.min_label }}</div>
                <div>{{ currentContent.max_label }}</div>
              </div>
              <div v-if="isWrapped" class="flex justify-between text-gray-500 font-medium mt-3">
                <div>{{ currentContent.min_value }}-{{ currentContent.min_label }}</div>
                <div>{{ currentContent.max_value }}-{{ currentContent.max_label }}</div>
              </div>
            </div>
          </div>

          <FollowUp v-if="showFollowUp" style="margin-top: 1rem;" :responseId="responseId" @chatEnded="handleChatEnd"
            :key="`${currentContent.id}-${currentIndex}`" :messageId="blochMessageId"
            :openingMessage="followUpQuestionFromResponse" :blockId="currentContent.id"
            :fuQuestionsRemaining="currentContent.followup_questions - 1" />
        </div>

        <div v-else-if="currentContent.type === 'multi_choice'" class="w-full">
          <p class="block-headline-sondar">{{ currentContent.question }}</p>
          <p v-if="currentContent.description" class="block-description-sondar" v-html="currentContent.description"></p>
          <div class="options">
            <div v-for="(choice, index) in currentContent.choices" :key="index"
              :class="['option', { selected: selectedChoice && selectedChoice.includes(choice) }]"
              @click="!showFollowUp && toggleChoice(choice)">
              <input type="checkbox" :name="currentContent.id" :value="choice" v-model="selectedChoice"
                :disabled="showFollowUp" />
              <label>{{ choice }}</label>
            </div>
          </div>
          <FollowUp v-if="showFollowUp" style="margin-top: 1rem;" :responseId="responseId" @chatEnded="handleChatEnd"
            :openingMessage="followUpQuestionFromResponse" :blockId="currentContent.id"
            :key="`${currentContent.id}-${currentIndex}`" :messageId="blochMessageId"
            :fuQuestionsRemaining="currentContent.followup_questions - 1" />
        </div>
        <button v-if="showNextButton" :disabled="!isButtonEnabled"
          :class="['btn-primary-sondar mt-8', { 'btn-primary-disabled-sondar': !isButtonEnabled }]" class="btn-primary-sondar" @click="nextContent">{{
            currentContent.button_label }}</button>
      </div>
    </transition>

    <a href="https://sondar.ai" target="_blank" class="inline-block text-sm mt-4 underline text-gray-500">Powered by
      Sondar</a>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch, nextTick, onBeforeUnmount } from 'vue'
import axios from 'axios'
import FollowUp from './FollowUp.vue'
import { saveResponseToSupabase } from '@/services/supabaseService'
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'ChatBox',
  components: {
    FollowUp
  },
  props: {
    contentData: {
      type: Array,
      required: true
    },
    responseId: {
      type: String,
      required: true
    },
    surveyId: {
      type: String,
      required: false
    },
    isSDK: {
      type: Boolean,
      default: false
    },
    canDismiss: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    // Reactive state
    const currentIndex = ref(0)
    const selectedChoice = ref(null)
    const hasConversationStarted = ref(false)
    const hasChatEndedForCurrentQuestion = ref(false)
    const showFollowUp = ref(false)
    const followUpQuestionFromResponse = ref(null)
    const hasPostedForCurrentQuestion = ref(false)
    const blochMessageId = ref(null)
    const isWrapped = ref(false)
    const authStore = useAuthStore()
    const tenantId = computed(() => authStore.tenantId)

    // Methods to check if options are wrapped
    const checkIfWrapped = () => {
      if (typeof window !== 'undefined') {
        const container = document.querySelector('.ratings-sondar .flex')
        if (container) {
          const firstOption = container.firstElementChild
          const lastOption = container.lastElementChild
          if (firstOption && lastOption) {
            isWrapped.value = firstOption.offsetTop !== lastOption.offsetTop
          }
        }
      }
    }

    // Watch for changes that might affect wrapping
    watch(() => props.contentData[currentIndex.value], () => {
      nextTick(() => {
        checkIfWrapped()
        window.addEventListener('resize', checkIfWrapped)
      })
    })

    // Computed properties
    const showNextButton = computed(() => {
      if (isLastQuestion() && props.contentData[currentIndex.value].button_label == "") {
        return false
      }
      return true
    })

    const isButtonEnabled = computed(() => {
      let current = props.contentData[currentIndex.value]
      if (!current.required) return true
      if (current.type === "message") {
        return true
      } else if (current.type === "explore") {
        return hasConversationStarted.value
      } else if (current.type === "multi_choice") {
        return selectedChoice.value.length > 0
      } else {
        return selectedChoice.value !== null
      }
    })

    const currentContent = computed(() => {
      return props.contentData[currentIndex.value]
    })

    // Methods
    const isLastQuestion = () => {
      return currentIndex.value === props.contentData.length - 1
    }

    const closeWidget = () => {
      const sondarWidget = document.getElementById('sondar-widget')
      const backgroundOverlay = document.getElementById('background-overlay-sondar')
      if (sondarWidget) {
        sondarWidget.style.display = 'none'
      }
      if (backgroundOverlay) {
        backgroundOverlay.style.display = 'none'
      }
    }

    const postResponse = async (currentBlock) => {
      const apiUrl = `${process.env.VUE_APP_SUPABASE_EF_BASE_URL}/save-response-item`
      const data = {
        response_id: props.responseId,
        block_id: currentBlock.id,
        value: String(selectedChoice.value),
      }

      try {
        let calling_idx = currentIndex.value
        const response = await axios.post(apiUrl, data)
        if (currentBlock.followup_questions && calling_idx === currentIndex.value) {
          blochMessageId.value = response.data.message_id
          followUpQuestionFromResponse.value = response.data.message
        }
      } catch (error) {
        console.error('Error making API request:', error)
      }
    }

    // Watch for selectedChoice changes
    watch(selectedChoice, async (newValue) => {
      const current = props.contentData[currentIndex.value];
      if (!newValue || current.type === 'message' || current.type === 'explore') return;
      // For multi_choice, only save if there's at least one selection
      if (current.type === 'multi_choice' && (!Array.isArray(newValue) || newValue.length === 0)) return;

      // For other types (select, rate, csat, nps), save as soon as there's a value
      if (['select', 'rate', 'csat', 'nps'].includes(current.type) && newValue === null) return;

      try {
        await saveResponseToSupabase(current, props.responseId, Array.isArray(selectedChoice.value) ? selectedChoice.value.join(',') : selectedChoice.value, tenantId.value);
      } catch (error) {
        console.error('Error saving response:', error);
      }
    });

    const handleChatEnd = () => {
      hasChatEndedForCurrentQuestion.value = true
    }

    const handleConversationStarted = () => {
      hasConversationStarted.value = true
    }

    const hasSelectedChoice = () => {
      let current = props.contentData[currentIndex.value]
      if (current.type === 'multi_choice') {
        return selectedChoice.value.length > 0
      } else {
        return selectedChoice.value !== null
      }
    }

    const nextContent = () => {
      if (props.contentData[currentIndex.value].button_url) {
        let url = props.contentData[currentIndex.value].button_url
        if (!/^https?:\/\//i.test(url)) {
          url = 'https://' + url
        }
        window.open(url, '_blank')
      }
      if (isLastQuestion()) {
        closeWidget()
        return
      }
      const current = props.contentData[currentIndex.value]

      if (!hasPostedForCurrentQuestion.value && current.type != 'explore' && current.type != 'message' && hasSelectedChoice()) {
        postResponse(current)
        hasPostedForCurrentQuestion.value = true
        if (current.followup_questions) {
          showFollowUp.value = true
          return
        }
      }

      // Move to next question if no follow-up is required or chat ended
      currentIndex.value++
      hasChatEndedForCurrentQuestion.value = false
      showFollowUp.value = false
      blochMessageId.value = null
      hasPostedForCurrentQuestion.value = false
      selectedChoice.value = props.contentData[currentIndex.value].type === "multi_choice" ? [] : null
      followUpQuestionFromResponse.value = null
    }

    const selectOption = (choice) => {
      selectedChoice.value = choice
    }

    const toggleChoice = (choice) => {
      const index = selectedChoice.value.indexOf(choice);
      if (index > -1) {
        // Remove the choice if it exists
        selectedChoice.value = [
          ...selectedChoice.value.slice(0, index),
          ...selectedChoice.value.slice(index + 1)
        ];
      } else {
        // Add the choice if it doesn't exist
        selectedChoice.value = [...selectedChoice.value, choice];
      }
    }

    // Lifecycle hooks
    onMounted(() => {
      selectedChoice.value = props.contentData[currentIndex.value].type === "multi_choice" ? [] : null
      nextTick(() => {
        checkIfWrapped()
        window.addEventListener('resize', checkIfWrapped)
      })
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', checkIfWrapped)
    })

    return {
      // State
      currentIndex,
      selectedChoice,
      hasConversationStarted,
      hasChatEndedForCurrentQuestion,
      showFollowUp,
      followUpQuestionFromResponse,
      hasPostedForCurrentQuestion,
      blochMessageId,
      isWrapped,

      // Computed
      showNextButton,
      isButtonEnabled,
      currentContent,

      // Methods
      isLastQuestion,
      closeWidget,
      handleChatEnd,
      handleConversationStarted,
      nextContent,
      selectOption,
      toggleChoice
    }
  }
}
</script>

<style scoped>
.option input[type="radio"],
.option input[type="checkbox"] {
  display: none;
}

.option label {
  cursor: pointer;
}

textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 0.5rem;
  resize: none;
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>