import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import ShareableSurveyView from "@/components/surveyBuilder/ShareableSurveyView.vue";
import AuthSignIn from "@/components/auth/AuthSignIn.vue";
import AdminDashboard from "@/components/settings/AdminDashboard.vue";
import PreviewTemplate from "@/components/templates/PreviewTemplate.vue";
import AIAnalysisReport from "@/components/surveyBuilder/AIAnalysisReport.vue";
import AuthSignUp from "@/components/auth/AuthSignUp.vue";
import AuthSignUpSuccess from "@/components/auth/AuthSignUpSuccess.vue";
import NotFound from "@/components/commons/NotFound.vue";
import CheckoutSuccess from "@/components/stripe/CheckoutSuccess.vue"
import ValidateLink from "@/components/auth/ValidateLink.vue"
import SurveyBuilder from "@/components/surveyBuilder/SurveyBuilder.vue";
import AISurveyChatWrapper from "@/components/chat/AISurveyChatWrapper.vue";

const routes = [
  {
    path: "/survey/:survey_id",
    name: "ShareableSurveyView",
    component: ShareableSurveyView,
    props: (route) => ({
      surveyId: route.params.survey_id,
      isPreview: route.query.isPreview === "true",
    }),
  },
  {
    path: "/templates/:templateId",
    name: "previewTemplate",
    component: PreviewTemplate,
    props: true,
  },
  {
    path: "/view-report/:reportId",
    name: "AIAnalysisReport",
    component: AIAnalysisReport,
  },
  {
    path: "/login",
    name: "SignInView",
    component: AuthSignIn,
  },
  {
    path: "/",
    name: "AdminDashboard",
    component: AdminDashboard,
  },
  {
    path: "/events",
    name: "EventsList",
    component: AdminDashboard,
  },
  {
    path: "/attributes",
    name: "AttributesList",
    component: AdminDashboard,
  },
  {
    path: "/survey/edit/:survey_id",
    name: "SurveyBuilder",
    component: AdminDashboard,
    props: true,
  },
  {
    path: "/survey-builder",
    name: "GuestSurveyBuilder",
    component: SurveyBuilder,
    props: true,
  },
  {
    path: "/survey-builder/:survey_id",
    name: "GuestSurveyBuilderWithId",
    component: SurveyBuilder,
    props: true,
  },
  {
    path: "/frame/chat-survey-builder",
    name: "ChatSurveyBuilder",
    component: AISurveyChatWrapper,
  },
  {
    path: "/admin-settings",
    name: "AdminSettings",
    component: AdminDashboard,
  },
  {
    path: "/tenant-settings",
    name: "TenantSettings",
    component: AdminDashboard,
  },
  {
    path: "/signup",
    name: "SignUpView",
    component: AuthSignUp,
  },
  {
    path: "/signup-success",
    name: "SignUpSuccess",
    component: AuthSignUpSuccess,
  },
  {
    path: "/stripe/checkout_success",
    name: "checkout-success",
    component: CheckoutSuccess,
    meta: { requiresAuth: true }
  },
  {
    path: "/validate-link",
    name: "ValidateLink",
    component: ValidateLink,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  // Wait for auth to initialize on first navigation
  if (authStore.loading) {
    await authStore.initializeAuth();
  }

  if (
    authStore.isAuthenticated &&
    (to.path === "/login" || to.path === "/signup" || to.path === "/validate-link" || to.path === "/signup-success" || to.path === "/survey-builder")
  ) {
    return next({ 
      name: "AdminDashboard",
      query: { redirect: to.fullPath }
    });
  }

  const publicRoutes = ["SignInView", "SignUpView", "ShareableSurveyView", "AIAnalysisReport", "SignUpSuccess", "ValidateLink", "GuestSurveyBuilder", "GuestSurveyBuilderWithId", "ChatSurveyBuilder"];
  
  if (!authStore.isAuthenticated && !publicRoutes.includes(to.name)) {
    return next({ name: "SignInView" });
  }
  next();
});

export default router;
