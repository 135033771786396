<template>
  <div class="p-6 max-w-[75rem] w-[75rem] mx-auto w-full">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-bold">Events</h2>
      <button type="button" @click="showPopup = true"
        class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        New Event
      </button>
    </div>

    <div v-if="isLoading">
      <div class="mt-8 flow-root animate-pulse">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-0">
                    <div class="h-4 w-20 bg-gray-300 rounded"></div>
                  </th>
                  <th scope="col" class="px-3 py-3.5">
                    <div class="h-4 w-24 bg-gray-300 rounded"></div>
                  </th>
                  <th scope="col" class="px-3 py-3.5">
                    <div class="h-4 w-16 bg-gray-300 rounded"></div>
                  </th>
                  <th scope="col" class="px-3 py-3.5">
                    <div class="h-4 w-24 bg-gray-300 rounded"></div>
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span class="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr v-for="i in 4" :key="i">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-0">
                    <div class="h-4 w-32 bg-gray-300 rounded"></div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4">
                    <div class="h-4 w-48 bg-gray-300 rounded"></div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4">
                    <div class="h-4 w-24 bg-gray-300 rounded"></div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4">
                    <div class="h-6 w-24 bg-gray-300 rounded"></div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4">
                    <div class="h-5 w-5 bg-gray-300 rounded-full ml-auto"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="events.length === 0" class="text-center py-4 mt-10 bg-gray-100 rounded-lg shadow">
        <p class="text-gray-700 font-medium">
          There are no custom events. Create one now!
        </p>
      </div>

      <div v-else class="overflow-hidden">
        <div class="mt-8 flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Name
                    </th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Description</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
                    <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Occurrences</th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                  <tr v-for="(event, index) in paginatedEvents" :key="index" class="hover:bg-gray-50">
                    <td class="whitespace-nowrap text-left py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">
                      {{ event.name }}
                    </td>
                    <td class="whitespace-nowrap text-left px-3 py-4 text-sm text-gray-500">
                      {{ event.description || '-' }}
                    </td>
                    <td class="whitespace-nowrap text-left px-3 py-4 text-sm text-gray-500">
                      {{ formatCreatedAt(event.createdAt) }}
                    </td>
                    <td class="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">
                      {{ event.occurrenceCount }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isLoading && events.length > itemsPerPage" class="mt-4">
      <div class="flex flex-1 justify-between sm:hidden">
        <button @click="previousPage" :disabled="currentPage === 1"
          class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed">
          Previous
        </button>
        <button @click="nextPage" :disabled="currentPage === totalPages"
          class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed">
          Next
        </button>
      </div>
      <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p class="text-sm text-gray-700">
            Showing
            <span class="font-medium">{{ ((currentPage - 1) * itemsPerPage) + 1 }}</span>
            to
            <span class="font-medium">{{ Math.min(currentPage * itemsPerPage, events.length) }}</span>
            of
            <span class="font-medium">{{ events.length }}</span>
            results
          </p>
        </div>
        <div>
          <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button @click="previousPage" :disabled="currentPage === 1"
              class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed">
              <span class="sr-only">Previous</span>
              <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
            </button>

            <button v-for="page in totalPages" :key="page" @click="currentPage = page" :class="[
              page === currentPage
                ? 'relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                : 'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
            ]">
              {{ page }}
            </button>

            <button @click="nextPage" :disabled="currentPage === totalPages"
              class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed">
              <span class="sr-only">Next</span>
              <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>

    <!-- Create New Event Modal -->
    <div v-if="showPopup" class="relative z-10">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 overflow-y-auto text-left">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div>
              <div>
                <h3 class="text-base font-semibold leading-6 text-gray-900">
                  Create a New Event
                </h3>

                <div class="mt-6 mb-16">
                  <div class="mb-4">
                    <label for="event-name" class="block text-sm font-medium leading-6 text-gray-900">Event Name</label>
                    <div class="mt-2">
                      <input id="event-name" v-model="newEventName" type="text" placeholder="e.g. purchaseComplete"
                        class="input-sondar" />
                      <p v-if="errorMessage" class="mt-2 text-sm text-red-600">
                        {{ errorMessage }}
                      </p>
                    </div>
                  </div>

                  <div class="mb-4">
                    <label for="event-description"
                      class="block text-sm font-medium leading-6 text-gray-900">Description</label>
                    <div class="mt-2">
                      <input id="event-description" v-model="newEventDescription" type="text"
                        placeholder="e.g. User has completed a purchase" class="input-sondar" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button type="button" @click="clearModal()" class="btn-secondary-sondar">
                Cancel
              </button>
              <button type="button" @click="createEvent" class="btn-primary-sondar">
                Create
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { format } from "date-fns"
import { useAuthStore } from '@/stores/auth'
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline"
import { fetchEventDetails, createEvent as createEventService } from '@/services/supabaseService'

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon
  },
  setup() {
    // State variables using ref
    const currentUserId = ref(null)
    const userRole = ref(null)
    const tenantId = ref(null)
    const events = ref([])
    const currentPage = ref(1)
    const itemsPerPage = ref(10)
    const isLoading = ref(true)
    const showPopup = ref(false)
    const newEventName = ref("")
    const newEventDescription = ref("")
    const errorMessage = ref("")

    // Computed properties
    const totalPages = computed(() => {
      return Math.ceil(events.value.length / itemsPerPage.value)
    })

    const paginatedEvents = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value
      return events.value.slice(start, start + itemsPerPage.value)
    })

    // Methods
    const fetchEvents = async () => {
      isLoading.value = true
      try {
        events.value = await fetchEventDetails(tenantId.value)
      } catch (err) {
        console.error("Unexpected error:", err)
      } finally {
        isLoading.value = false
      }
    }

    const formatCreatedAt = (date) => {
      return format(new Date(date), "MMM do, yyyy")
    }

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++
      }
    }

    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--
      }
    }

    const createEvent = async () => {
      if (!newEventName.value.trim() || newEventName.value.includes(" ")) {
        errorMessage.value = "Event name is required. Must not contain any spaces."
        return
      }

      try {
        await createEventService(newEventName.value, newEventDescription.value, tenantId.value)
        newEventName.value = ""
        newEventDescription.value = ""
        errorMessage.value = ""
        showPopup.value = false
        fetchEvents()
      } catch (err) {
        console.error("Unexpected error:", err)
        errorMessage.value = "An unexpected error occurred."
      }
    }

    const clearModal = () => {
      newEventName.value = ''
      newEventDescription.value = ''
      errorMessage.value = ''
      showPopup.value = false
    }

    const deleteEvent = async (id) => {
      console.log(id)
    }

    // Lifecycle hook
    onMounted(async () => {
      const authStore = useAuthStore()
      currentUserId.value = authStore.user.id
      userRole.value = authStore.role
      tenantId.value = authStore.tenantId
      await fetchEvents()
    })

    // Return all refs, computed properties and methods that are used in the template
    return {
      currentUserId,
      userRole,
      tenantId,
      events,
      currentPage,
      itemsPerPage,
      isLoading,
      showPopup,
      newEventName,
      newEventDescription,
      errorMessage,
      totalPages,
      paginatedEvents,
      fetchEvents,
      formatCreatedAt,
      nextPage,
      previousPage,
      createEvent,
      clearModal,
      deleteEvent
    }
  }
}
</script>
