<template>
  <div class="min-h-screen bg-gradient-to-br from-indigo-50 via-white to-indigo-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <!-- Animated success checkmark -->
      <div class="mx-auto h-20 w-20 flex items-center justify-center rounded-full bg-green-100 animate-bounce">
        <div class="rounded-full bg-green-400 h-16 w-16 flex items-center justify-center">
          <svg class="h-10 w-10 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M5 13l4 4L19 7" class="animate-draw"></path>
          </svg>
        </div>
      </div>

      <h2 class="mt-8 text-center text-3xl font-extrabold text-gray-900 tracking-tight">
        Check your email
      </h2>
      <div class="mt-4 text-center">
        <p class="text-sm text-gray-600">
          We've sent an access link to
        </p>
        <p class="mt-2 text-md font-medium text-indigo-600 bg-indigo-50 py-2 px-4 rounded-lg inline-block">
          {{ email }}
        </p>
        <p class="mt-2 text-sm text-gray-500">
          Click the link in the email to access your account
        </p>
      </div>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow-xl sm:rounded-xl sm:px-10 border border-gray-100">
        <div class="space-y-6">
          <!-- Email not received section -->
          <div class="text-sm">
            <p class="font-semibold text-gray-900">Didn't receive the email?</p>
            <ul class="mt-4 space-y-4">
              <li class="flex items-center text-gray-600">
                <svg class="h-5 w-5 text-gray-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Check your spam folder
              </li>
              <li class="flex items-center text-gray-600">
                <svg class="h-5 w-5 text-gray-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Make sure the email address is correct
              </li>
              <li class="flex items-center">
                <svg class="h-5 w-5 text-gray-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span class="text-gray-600">Wait a few minutes or</span>
                <button 
                  @click="handleResendEmail" 
                  :disabled="isResending"
                  class="ml-1 text-indigo-600 hover:text-indigo-500 font-semibold focus:outline-none focus:underline transition-colors duration-150"
                >
                  {{ isResending ? 'Resending...' : 'resend the email' }}
                </button>
              </li>
            </ul>
          </div>

          <!-- Back to login button -->
          <div class="mt-8">
            <router-link 
              to="/login"
              class="w-full flex justify-center items-center px-4 py-3 text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-150 group"
            >
              <svg 
                class="w-4 h-4 mr-2 transform group-hover:-translate-x-1 transition-transform duration-150" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              Back to login
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'

const route = useRoute()
const email = ref(route.query.email || '')
const isResending = ref(false)

onMounted(async () => {
  // Report conversion without page refresh
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-16851746744/8O2yCJ22ypkaELj_xOM-'
    });
  }

  if (window.fbq) {
    window.fbq('track', 'CompleteRegistration');
  }
})

const handleResendEmail = async () => {
  if (isResending.value) return
  
  try {
    isResending.value = true
    const authStore = useAuthStore()
    await authStore.resendMagicLink(email.value)
    // Show success toast or message
  } catch (error) {
    console.error('Failed to resend email:', error)
    // Show error toast or message
  } finally {
    isResending.value = false
  }
}
</script>

<style scoped>
.animate-draw {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: draw 1s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style> 