<template>
  <div class="flex items-center justify-center h-screen bg-zinc-100">
    <div v-if="loading" class="loader"></div>
    <div v-else-if="!loading && contentData.length === 0"
      class="bg-white rounded-2xl px-4 py-12 sm:px-20 w-full shadow max-w-2xl">
      <h1 class="text-xl font-semibold text-gray-800 mb-4">{{ error }}</h1>
    </div>
    <ChatBox v-if="!loading && contentData && contentData.length > 0" :responseId="responseId" class="" :contentData="contentData" />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import ChatBox from '../surveys/ChatBox.vue'

export default {
  components: { ChatBox },
  props: {
    surveyId: {
      type: String,
      required: true
    },
    isPreview: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    // Reactive state
    const contentData = ref([])
    const responseId = ref(null)
    const loading = ref(false)
    const error = ref("Survey not found")

    // Methods
    const fetchSurveyData = async () => {
      try {
        loading.value = true
        const response = await axios.post(`${process.env.VUE_APP_SUPABASE_EF_BASE_URL}/initiate-response`, {
          survey_id: props.surveyId,
          channel: "shareable_link",
          is_preview: props.isPreview,
          participant: {
            language: (navigator.language || navigator.userLanguage).split('-')[0],
            device: /Mobi|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }
        })

        if (response.data && response.data.blocks) {
          contentData.value = response.data.blocks
          responseId.value = response.data.response_id
        }
      } catch (err) {
        error.value = err?.response?.data?.error
      } finally {
        loading.value = false
      }
    }

    // Lifecycle hooks
    onMounted(() => {
      fetchSurveyData()
    })

    return {
      // State
      contentData,
      responseId,
      loading,
      error,
    }
  }
}
</script>

<style scoped>
.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #5051BA;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>