<template>
  <div class="min-h-screen bg-gradient-to-br from-indigo-50 via-white to-indigo-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <!-- Loading State -->
      <div v-if="isLoading">
        <div class="mx-auto h-20 w-20 flex items-center justify-center rounded-full bg-indigo-100">
          <div class="rounded-full bg-indigo-400 h-16 w-16 flex items-center justify-center">
            <svg class="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </div>

        <h2 class="mt-8 text-center text-3xl font-extrabold text-gray-900 tracking-tight">
          Validating Access
        </h2>
        <p class="mt-4 text-center text-sm text-gray-600">
          Please wait while we verify your credentials
        </p>
      </div>

      <!-- Error State -->
      <div v-if="invalidAccessToken">
        <div class="mx-auto h-20 w-20 flex items-center justify-center rounded-full bg-red-100">
          <div class="rounded-full bg-red-400 h-16 w-16 flex items-center justify-center">
            <svg class="h-10 w-10 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>

        <h2 class="mt-8 text-center text-3xl font-extrabold text-gray-900 tracking-tight">
          Access Error
        </h2>
        <p class="mt-4 text-center text-sm text-gray-600">
          We couldn't validate your access token
        </p>
      </div>
    </div>

    <!-- Error Actions Card -->
    <div v-if="invalidAccessToken" class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow-xl sm:rounded-xl sm:px-10 border border-gray-100">
        <div class="space-y-6">
          <div class="text-sm">
            <p class="font-semibold text-gray-900">Unable to verify access?</p>
            <ul class="mt-4 space-y-4">
              <li class="flex items-center text-gray-600">
                <svg class="h-5 w-5 text-gray-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Check if you're using the correct link
              </li>
              <li class="flex items-center text-gray-600">
                <svg class="h-5 w-5 text-gray-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                The link might have expired
              </li>
            </ul>
          </div>

          <!-- Back to login button -->
          <div class="mt-8">
            <button 
              @click="goToSignIn"
              class="w-full flex justify-center items-center px-4 py-3 text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-150 group"
            >
              <svg 
                class="w-4 h-4 mr-2 transform group-hover:-translate-x-1 transition-transform duration-150" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              Back to login
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useRouter } from 'vue-router'

const invalidAccessToken = ref(false)
const isLoading = ref(false)
const authStore = useAuthStore()
const router = useRouter()

const goToSignIn = () => {
  router.push('/auth/signin')
}

onMounted(async () => {
  const urlParams = new URLSearchParams(window.location.hash.substring(1));
  const accessToken = urlParams.get('access_token');
  const email = decodeURIComponent(urlParams.get('email'));
  
  if (!accessToken) {
    invalidAccessToken.value = true
    return
  }

  try {
    isLoading.value = true
    await authStore.validateAccessLink({ accessToken, email })
    window.location.href = '/'
  } catch (err) {
    invalidAccessToken.value = true
  } finally {
    isLoading.value = false
  }
})
</script>

<style scoped>
.animate-draw {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: draw 1s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>